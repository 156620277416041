import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "../utils/api-client";

const useGetProjects = () => {
  const { data, isLoading } = useQuery({
    queryKey: "projects",
    queryFn: () => client("projects/").then((data) => data),
  });
  return {
    data: data ?? [],
    isLoading,
  };
};

const useGetProjectById = (projectId) => {
  const { data, isLoading } = useQuery({
    queryKey: ["project", projectId],
    queryFn: () => client(`projects/${projectId}`).then((data) => data),
    enabled: !!projectId,
  });
  return {
    data: data ?? {},
    isLoading,
  };
};

const useCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`projects/`, {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("projects"),
    }
  );
};

const useMutateProjectStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`projects/${data.id}/`, {
        method: "PUT",
        data: { status: data.status },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("projects"),
    }
  );
};

const useEditProject = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`projects/${projectId}/`, {
        method: "PATCH",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("projects"),
    }
  );
};

const useAddAttachmentToProject = () => {
  return useMutation((data) =>
    client(`projects/${data.id}/add_attachment/`, {
      method: "POST",
      data: data.file,
      headers: {
        "Content-Type": data.type,
        "Content-Disposition": `attachment; filename=${data.name}`,
        filename: data.name,
      },
    })
  );
};

const useDeleteAttachmentFromProject = (attachmentId) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`projects/del_attachment/${attachmentId}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("project"),
      onSettled: () => queryClient.invalidateQueries("project"),
    }
  );
};

const useDeleteObservationFromProject = (observationId) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`projects/del_observation/${observationId}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("project"),
      onSettled: () => queryClient.invalidateQueries("project"),
    }
  );
};

const useDownloadAttachmentById = (attachmentId) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["attachment", attachmentId],
    queryFn: () =>
      client(`projects/attachment/${attachmentId}/`).then((data) => data),
    manual: true,
    enabled: !!attachmentId,
  });
  return {
    data: data ?? {},
    isLoading,
    refetch,
  };
};

export {
  useAddAttachmentToProject,
  useCreateProject,
  useDeleteAttachmentFromProject,
  useDownloadAttachmentById,
  useEditProject,
  useGetProjectById,
  useGetProjects,
  useMutateProjectStatus,
  useDeleteObservationFromProject
};
