import { Error, Input, Select } from "UI/atoms";
import "rc-tooltip/assets/bootstrap_white.css";
import { ReadOnly } from "./ReadOnly";

const Information = ({
  formik,
  lookups,
  project,
  setTab,
  isEdit,
  isEditMode,
  isCreateMode,
  responseError,
  successResponse,
}) => {
  return (
    <div className="tablayer tab-informacion">
      {isEditMode || isCreateMode ? (
        <>
          {" "}
          <div className="field-row row" style={{ width: "100%" }}>
            <div style={{ marginRight: "25px", width: "70%" }}>
              <label htmlFor="name" className="form-label">
                Nombre proyecto *
              </label>

              <Input
                id="name"
                name="name"
                type="text"
                height="46px"
                width={"100%"}
                className="form-control"
                onChange={(name) => {
                  formik.setFieldValue("name", name.target.value);
                }}
                value={formik?.values?.name ?? ""}
              />
              {formik.errors.name && formik.touched.name ? (
                <Error>{formik.errors.name}</Error>
              ) : null}
            </div>
            <div style={{ width: "30%" }}>
              <label htmlFor="status" className="form-label">
                Etapa *
              </label>

              <Select
                name="status"
                id="status"
                width="100%"
                style={{ marginLeft: "2rem", width: "100%" }}
                defaultValue={
                  formik?.values?.status || formik?.values?.status?.id || ""
                }
                onChange={(status) => {
                  formik.setFieldValue("status", parseInt(status.target.value));
                }}
              >
                {!isEdit && (
                  <option value="" hidden>
                    Selecione una etapa
                  </option>
                )}
                {lookups.statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </Select>
              {formik.errors.status && formik.touched.status ? (
                <Error>{formik.errors.status}</Error>
              ) : null}
            </div>
          </div>
          <div className="field-row">
            <ul className="field-colums" style={{ width: "100%" }}>
              <li style={{ width: "20%" }}>
                <label htmlFor="organization_name" className="form-label">
                  Organización *
                </label>
                <Input
                  name="organization_name"
                  id="organization_name"
                  type="text"
                  height="46px"
                  width={"100%"}
                  className="form-control"
                  onChange={(organization_name) => {
                    formik.setFieldValue(
                      "organization_name",
                      organization_name.target.value
                    );
                  }}
                  value={formik?.values?.organization_name ?? ""}
                />
                {formik.errors.organization_name &&
                formik.touched.organization_name ? (
                  <Error>{formik.errors.organization_name}</Error>
                ) : null}
              </li>
              <li style={{ width: "40%" }}>
                <label htmlFor="topic" className="form-label">
                  Tipo de proyecto *
                </label>

                <Select
                  name="topic"
                  id="topic"
                  width="100%"
                  defaultValue={
                    formik?.values?.topic || formik?.values?.topic?.id || ""
                  }
                  onChange={(topic) => {
                    formik.setFieldValue("topic", parseInt(topic.target.value));
                  }}
                >
                  {!isEdit && (
                    <option value="" hidden>
                      Selecione un tipo de proyecto
                    </option>
                  )}
                  {lookups.topics.map((topic) => (
                    <option key={topic.id} value={topic.id}>
                      {topic.name}
                    </option>
                  ))}
                </Select>
                {formik.errors.topic && formik.touched.topic ? (
                  <Error>{formik.errors.topic}</Error>
                ) : null}
              </li>
              <li style={{ width: "20%" }}>
                <label htmlFor="productive_sector" className="form-label">
                  Sector productivo *
                </label>

                <Select
                  name="productive_sector"
                  id="productive_sector"
                  width="100%"
                  defaultValue={
                    formik?.values?.productive_sector ||
                    formik?.values?.productive_sector.id ||
                    ""
                  }
                  onChange={(productive_sector) => {
                    formik.setFieldValue(
                      "productive_sector",
                      parseInt(productive_sector.target.value)
                    );
                  }}
                >
                  {!isEdit && (
                    <option value="" hidden>
                      Selecione un sector productivo
                    </option>
                  )}
                  {lookups.productiveSectors.map((productive_sector) => (
                    <option
                      key={productive_sector.id}
                      value={productive_sector.id}
                    >
                      {productive_sector.name}
                    </option>
                  ))}
                </Select>
                {formik.errors.productive_sector &&
                formik.touched.productive_sector ? (
                  <Error>{formik.errors.productive_sector}</Error>
                ) : null}
              </li>
              <li style={{ width: "20%" }}>
                <label htmlFor="location" className="form-label">
                  Provincia *
                </label>

                <Select
                  name="location"
                  id="location"
                  width="100%"
                  defaultValue={
                    formik.values.location || formik.values.location.id || ""
                  }
                  onChange={(location) => {
                    formik.setFieldValue(
                      "location",
                      parseInt(location.target.value)
                    );
                  }}
                >
                  {!isEdit && (
                    <option value="" hidden>
                      Seleccionar
                    </option>
                  )}
                  {lookups.provinces.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </Select>
                {formik.errors.location && formik.touched.location ? (
                  <Error>{formik.errors.location}</Error>
                ) : null}
              </li>
            </ul>
          </div>
          <div className="field-row">
            <ul className="field-colums" style={{ width: "100%" }}>
              <li style={{ width: "20%" }}>
                <label htmlFor="location_details" className="form-label">
                  Localidad *
                </label>

                <Input
                  name="location_details"
                  id="location_details"
                  type="text"
                  height="46px"
                  width={"100%"}
                  className="form-control"
                  onChange={(location_details) => {
                    formik.setFieldValue(
                      "location_details",
                      location_details.target.value
                    );
                  }}
                  value={formik.values.location_details ?? ""}
                />
                {formik.errors.location_details &&
                formik.touched.location_details ? (
                  <Error>{formik.errors.location_details}</Error>
                ) : null}
              </li>
              <li style={{ width: "40%" }}>
                <label htmlFor="substatus" className="form-label">
                  Estado *
                </label>

                <Select
                  name="substatus"
                  id="substatus"
                  width="100%"
                  disabled={
                    formik.values.status === 2 ||
                    project?.status?.name === "En desarrollo"
                      ? false
                      : true
                  }
                  defaultValue={
                    formik?.values?.substatus ||
                    formik?.values?.substatus?.id ||
                    ""
                  }
                  onChange={(substatus) => {
                    formik.setFieldValue(
                      "substatus",
                      parseInt(substatus.target.value)
                    );
                  }}
                >
                  {lookups.substatuses.map((substatus) => (
                    <option key={substatus.id} value={substatus.id}>
                      {substatus.name}
                    </option>
                  ))}
                </Select>
              </li>
              <li style={{ width: "20%" }}>
                <label htmlFor="start_date" className="form-label">
                  Fecha de inicio *
                </label>

                <Input
                  id="start_date"
                  name="start_date"
                  type="date"
                  height="46px"
                  width={"100%"}
                  className="form-control"
                  onChange={(start_date) => {
                    formik.setFieldValue("start_date", start_date.target.value);
                  }}
                  value={
                    formik.values.start_date
                      ? formik.values.start_date
                      : project?.start_date || ""
                  }
                />
                {formik.errors.start_date && formik.touched.start_date ? (
                  <Error>{formik.errors.start_date}</Error>
                ) : null}
              </li>
              <li style={{ width: "20%" }}>
                <label htmlFor="end_date" className="form-label">
                  Fecha de finalización *
                </label>

                <Input
                  id="end_date"
                  name="end_date"
                  type="date"
                  height="46px"
                  width={"100%"}
                  className="form-control"
                  onChange={(end_date) => {
                    formik.setFieldValue("end_date", end_date.target.value);
                  }}
                  value={
                    formik.values.end_date
                      ? formik.values.end_date
                      : project?.end_date || ""
                  }
                />
                {formik.errors.end_date && formik.touched.end_date ? (
                  <Error>{formik.errors.end_date}</Error>
                ) : null}
              </li>
            </ul>
          </div>
          <div className="field-row" style={{ width: "100%" }}>
            <label htmlFor="description" className="form-label">
              Objetivo general
            </label>
            <textarea
              id="description"
              name="description"
              className="form-control textarea scrollbar-scroll scrollbar-track scrollbar-thumb"
              onChange={(description) => {
                formik.setFieldValue("description", description.target.value);
              }}
              value={formik.values.description ?? ""}
            ></textarea>
          </div>
          <div className="field-row" style={{ width: "100%" }}>
            <label htmlFor="specific_objectives" className="form-label">
              Objetivos específicos
            </label>

            <textarea
              id="specific_objectives"
              name="specific_objectives"
              className="form-control textarea scrollbar-scroll scrollbar-track scrollbar-thumb"
              onChange={(specific_objectives) => {
                formik.setFieldValue(
                  "specific_objectives",
                  specific_objectives.target.value
                );
              }}
              value={formik.values.specific_objectives ?? ""}
            ></textarea>
          </div>
          <div className="field-row " style={{ width: "100%" }}>
            <label htmlFor="justification" className="form-label">
              Justificación
            </label>

            <textarea
              id="justification"
              name="justification"
              className="form-control textarea scrollbar-scroll scrollbar-track scrollbar-thumb"
              onChange={(justification) => {
                formik.setFieldValue(
                  "justification",
                  justification.target.value
                );
              }}
              value={formik.values.justification ?? ""}
            ></textarea>
          </div>
        </>
      ) : (
        <ReadOnly formik={formik} />
      )}
    </div>
  );
};

export { Information };
