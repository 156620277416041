import styled from "styled-components";

export const BoxList = ({
  width,
  height,
  padding,
  margin,
  color,
  background,
  fontWeight,
  fontSize,
  border,
  borderRadius,
  children,
  flexDirection,
  justifyContent,
  onClick,
  style,
  key,
}) => {
  return (
    <StyledBox
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      color={color}
      background={background}
      fontWeight={fontWeight}
      fontSize={fontSize}
      border={border}
      borderRadius={borderRadius}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      onClick={onClick}
      style={style}
      key={key}
    >
      {children}
    </StyledBox>
  );
};
const StyledBox = styled.li`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  height: ${(props) => (props.height ? props.height : null)};

  padding: ${(props) => (props.padding ? props.padding : " 0 15px;")};
  margin: ${(props) => props.margin};

  color: ${(props) => props.color};
  background: ${(props) => props.background};

  font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;

  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};

  cursor: pointer;
`;
