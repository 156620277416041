import { Badge, Button, Error, Input, RegularText } from "UI/atoms";
import { useState } from "react";
import { Check } from "shared/assets/svgs";

import { useAddLookup, useMutateLookup } from "queries/lookups";
import { getTextColor } from "utils/getTextColor";

const AddEdit = ({
  setOpenAddOrEditModal,
  actionText,
  method,
  lookup,
  keyName,
  name,
  colors,
}) => {
  const [value, setValue] = useState();
  const [error, setError] = useState("");
  const [textColor, setTextColor] = useState({});
  const [bgColor, setBgColor] = useState({});

  const { mutate: edit } = useMutateLookup(keyName);
  const { mutate: add } = useAddLookup(keyName);

  const findColor = (type) => {
    if (method === "PUT" && type === "text") {
      return colors?.find((color) => color.id === lookup?.color.id).code;
    }
    if (method === "PUT" && type === "bg" && keyName === "project-status") {
      return colors?.find((color) => color.id === lookup?.bg_color.id).code;
    } else {
      return;
    }
  };

  const handleSideEffectAction = () => {
    if (keyName === "project-status") {
      if (method !== "PUT") {
        if (!value || !bgColor || !textColor) {
          setError("empty-value");
          return;
        }
      }

      if (method === "PUT") {
        edit({
          name: value ?? lookup?.name,
          color: textColor.id ?? lookup?.color.id,
          bg_color: bgColor.id ?? lookup?.bg_color.id,
          id: lookup.id,
        });
      }
      if (method === "POST") {
        add({
          name: value,
          color: textColor.id ?? lookup?.color.id,
          bg_color: bgColor.id ?? lookup?.bg_color.id,
        });
      }
    } else {
      if (method !== "PUT") {
        if (!value || !textColor) {
          setError("empty-value");
          return;
        }
      }

      if (method === "PUT") {
        edit({
          name: value ?? lookup?.name,
          color: bgColor.id,
          id: lookup.id,
        });
      }
      if (method === "POST") {
        add({
          name: value,
          color: bgColor.id,
        });
      }
    }
    setOpenAddOrEditModal("");
  };

  const handleInputOnChange = (value) => {
    setValue(value);
  };

  return (
    <>
      <div className="field-row">
        <h4>{actionText}</h4>
        <div className="field-with-icon" style={{ margin: "1rem 0" }}>
          <Input
            type="text"
            name={name}
            height="46px"
            className="form-control"
            placeholder={name}
            defaultValue={lookup?.name ? lookup.name : ""}
            onChange={(e) => handleInputOnChange(e.target.value)}
          />
        </div>
        {colors ? (
          <>
            <div style={{ margin: "1rem 0 0 0" }}>
              <RegularText margin="1rem 0 0 0">
                {textColor && "Colores seleccionados:"}
              </RegularText>
              <Badge
                margin="1rem 0"
                color={
                  keyName === "project-status"
                    ? textColor.code ?? findColor("text")
                    : "white"
                }
                background={
                  keyName === "project-status"
                    ? bgColor.code ?? findColor("bg")
                    : bgColor.code ?? findColor("text")
                }
              >
                {lookup?.name ? lookup?.name : "Etapa"}
              </Badge>
            </div>
            {keyName === "project-status" ? (
              <>
                <RegularText>Color de letra:</RegularText>
                <li
                  style={{
                    display: "flex",
                    margin: "1rem 0 1rem 0",
                    flexWrap: "wrap",
                  }}
                >
                  {colors?.map((color) => (
                    <Badge
                      key={color.name}
                      value={color.code}
                      background={getTextColor(color.code)}
                      height="fit-content"
                      margin="5px"
                      color={color.code}
                      onClick={() => setTextColor(color)}
                    >
                      {color.name}
                    </Badge>
                  ))}
                </li>
              </>
            ) : (
              ""
            )}

            <RegularText>Color de fondo:</RegularText>
            <li
              style={{
                display: "flex",
                margin: "1rem 0 0 0",
                flexWrap: "wrap",
              }}
            >
              {colors?.map((color) => (
                <Badge
                  key={color.name}
                  value={color.code}
                  background={color.code}
                  color={getTextColor(color.code)}
                  height="fit-content"
                  margin="5px"
                  onClick={() => setBgColor(color)}
                >
                  {color.name}
                </Badge>
              ))}
            </li>
          </>
        ) : (
          ""
        )}
      </div>
      {error ? <Error>Hay campos vacíos.</Error> : null}
      <div className="actions-row">
        <Button
          width="133px"
          background="#F0F4F7"
          color="#344854"
          className="btn-with-icon"
          onClick={() => setOpenAddOrEditModal("")}
        >
          Cancelar
        </Button>
        <Button
          width="133px"
          className="btn-with-icon white-icon"
          onClick={() => handleSideEffectAction()}
        >
          <Check /> <span>Confirmar</span>
        </Button>
      </div>
    </>
  );
};

export { AddEdit };
