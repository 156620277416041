import { useNavigate } from "react-router-dom";
import { LeftArrow } from "shared/assets/svgs/LeftArrow";
import styled from "styled-components";

const BackButton = (isLogin = false) => {
  const navigate = useNavigate();
  return (
    <StyledButton onClick={() => navigate(isLogin ? "/login" : -1)}>
      <LeftArrow /> <span>Volver</span>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  width: 72px;
  height: 27px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #21619f;
  display: flex;
  justify-content: space-between;
`;

export { BackButton };
