import styled from "styled-components";

const DashHead = styled.div`
  max-width: 100%;
  align-items: center;
  margin: 32px 24px;
  white-space: nowrap;
`;

const DashBar = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.span`
  font-size: ${(props) =>
    props.fontSizes ? props.fontSizes : props.theme.fontSizes.base};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : props.theme.fontFamily.base};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.semiBold};
  color: ${(props) => (props.color ? props.color : props.theme.colors.gray)};
`;

const Title = styled.h2`
  font-size: ${(props) =>
    props.fontSizes ? props.fontSizes : props.theme.fontSizes.extended};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.semiBold};

  color: ${(props) => (props.color ? props.color : props.theme.colors.gray)};
`;

export { DashBar, DashHead, Text, Title };
