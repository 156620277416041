import { Button, Input } from "UI/atoms";
import { Formik } from "formik";
import jwt_decode from "jwt-decode";
import { Check } from "shared/assets/svgs";
import { CloseModalIcon } from "shared/assets/svgs/CloseModal";
import { generateId } from "utils/getDate";

const TrackingNotesModal = ({
  setIsOpenModal,
  selectedObservation,
  setSelectedObservation,
  trackingNotes,
  setTrackingNotes,
}) => {
  const user = jwt_decode(localStorage.getItem("__auth_provider_token__"));

  const handleInitialValues = () => {
    if (selectedObservation) {
      return {
        name: selectedObservation.name ?? "",
        description: selectedObservation.description ?? "",
      };
    } else {
      return { name: "", description: "" };
    }
  };
  const closeModal = () => {
    setIsOpenModal(false);
    setSelectedObservation();
  };

  const onSubmit = (data) => {
    closeModal();
    data = {
      key: generateId(),
      user: user,
      name: data.name === "" ? "-----" : data.name,
      description: data.description === "" ? "-----" : data.description,
      finished_at: null,
    };
    if (selectedObservation?.key === data.key) {
      const filtered = trackingNotes.filter(
        (trackingNote) => trackingNote.key !== data.key
      );

      setTrackingNotes([...filtered, data]);
    } else {
      setTrackingNotes([...trackingNotes, data]);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "15px",
        padding: "1rem",
      }}
    >
      <div className="ficha-topbar">
        <h2>Nueva Observación</h2>

        <Button
          background="white"
          width="32px"
          height="32px"
          onClick={() => closeModal()}
        >
          <CloseModalIcon />
        </Button>
      </div>
      <Formik initialValues={handleInitialValues()}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="ficha-head">
            <div style={{ marginRight: "23px" }}>
              <label htmlFor="name" className="form-label">
                Título
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                height="46px"
                width={"524px"}
                className="form-control"
                onChange={(name) => {
                  formik.setFieldValue("name", name.target.value);
                }}
                value={formik.values.name ?? ""}
              />
            </div>
            <div style={{ margin: "23px 23px 0px 0px" }}>
              <label htmlFor="description" className="form-label">
                Descripción
              </label>

              <textarea
                id="description"
                name="description"
                className="form-control textarea scrollbar-scroll scrollbar-track scrollbar-thumb"
                onChange={(description) => {
                  formik.setFieldValue("description", description.target.value);
                }}
                value={formik.values.description ?? ""}
              ></textarea>
            </div>

            <div className="actions-row">
              <Button
                width="133px"
                background="#F0F4F7"
                color="#344854"
                className="btn-with-icon"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                width="133px"
                className="btn-with-icon white-icon"
                type={"button"}
                onClick={() => onSubmit(formik.values)}
              >
                <Check /> <span>Confirmar</span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { TrackingNotesModal };
