import SaveButton from "UI/organisms/SaveButton";
import { Formik } from "formik";
import useLookups from "hooks/useLookups";
import { useCreateProject, useEditProject, useGetProjectById } from "queries";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "shared/assets/svgs";
import { validateUrls } from "utils";
import ResponseModal from "./UI/atoms/ResponseModal";
import {
  Attachments,
  Budget,
  Indicators,
  Inform,
  Information,
  Milestones,
  TabsLayout,
  Team,
  Tracking,
} from "./UI/templates";
import {
  addFormSchemaValidator,
  addProjectEditValues,
  addProjectInitialValues,
  editFormSchemaValidator,
  useProjectState,
} from "./utils";
import { getRole } from "utils/getRole";
import jwtDecode from "jwt-decode";

const layoutsTabs = {
  information: <Information />,
  team: <Team />,
  milestones: <Milestones />,
  indicators: <Indicators />,
  budget: <Budget />,
  attachments: <Attachments />,
  tracking: <Tracking />,
  inform: <Inform />,
};

const tabs = [
  { id: "information", label: "Información", content: <Information /> },
  { id: "team", label: "Equipo", content: <Team /> },
  { id: "milestones", label: "Hitos", content: <Milestones /> },
  { id: "indicators", label: "Indicadores", content: <Indicators /> },
  { id: "budget", label: "Presupuesto", content: <Budget /> },
  { id: "attachments", label: "Adjuntos", content: <Attachments /> },
  { id: "tracking", label: "Seguimiento", content: <Tracking /> },
  { id: "inform", label: "Informe", content: <Inform /> },
];

function AddEditProject() {
  const { id } = useParams();
  const isEdit = id ? true : false;
  const location = useLocation();

  const pathname = location.pathname;
  const isEditMode = pathname.includes("/edit-project");
  const isCreateMode = pathname.includes("/new-project");

  const { data: project, isLoading: isProjectLoading } = useGetProjectById(id);

  const navigate = useNavigate("");
  const successText = isEdit ? "editado" : "creado";
  const isDisabled = !!project.status && project.status.id === 3 ? true : false;

  const {
    memberships,
    milestones,
    indicators,
    trackingNotes,
    attachments,
    reportAttachments,
    setMemberships,
    setMilestones,
    setIndicators,
    setTrackingNotes,
    setAttachments,
    setReportAttachments,
    urlAttachmentsError,
    setUrlAttachmentsError,
    urlInformError,
    setUrlInformError,
  } = useProjectState(project, isEditMode);

  const [successResponse, setSuccessResponse] = useState();
  const [responseError, setResponseError] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("information");

  const { mutate: createProject } = useCreateProject();
  const { mutate: editProject } = useEditProject(id);
  const {
    isLoading,
    provinces,
    topics,
    statuses,
    substatuses,
    users,
    membershipRoles,
    productiveSectors,
    milestoneStatuses,
  } = useLookups();

  const user = jwtDecode(localStorage.getItem("__auth_provider_token__"));

  useEffect(() => {
    if (project.memberships && !project.memberships.find((m) => m.user.id === user.user_id) && user.role !== "Consorcio") {
      navigate(-1);
    }
  }, [project])

  if (isLoading) {
    return <Spinner />;
  }

  const lookups = {
    provinces: [...provinces],
    topics: [...topics],
    statuses: [...statuses],
    substatuses: [...substatuses],
    users: [...users],
    membershipRoles: [...membershipRoles],
    productiveSectors: [...productiveSectors],
    milestoneStatuses: [...milestoneStatuses],
  };

  const props = {
    lookups,
    attachments,
    setAttachments,
    reportAttachments,
    setReportAttachments,
    memberships,
    setMemberships,
    milestones,
    setMilestones,
    indicators,
    setIndicators,
    trackingNotes,
    setTrackingNotes,
    project,
    setUrlAttachmentsError,
    urlAttachmentsError,
    setUrlInformError,
    urlInformError,
  };

  const handleLayoutsTabsWithExtendedProps = (formik) => {
    return React.cloneElement(layoutsTabs[selectedTab], {
      ...props,
      formik,
      responseError,
      successResponse,
      isEdit,
      setSelectedTab,
      isEditMode,
      isCreateMode,
    });
  };

  const handleInitialValues = () => {
    if (isEdit) {
      return addProjectEditValues(project);
    } else {
      return addProjectInitialValues;
    }
  };

  const onSubmit = (values, resetForm) => {
    const isFormValid = validateUrls(
      setUrlAttachmentsError,
      setUrlInformError,
      reportAttachments,
      attachments,
      isDisabled
    );

    if (!isFormValid) {
      return;
    }

    const tracking_notes = trackingNotes;
    const report_attachments = reportAttachments;
    const data = {
      ...values,
      memberships,
      attachments,
      report_attachments,
      milestones,
      indicators,
      tracking_notes,
    };

    const newData = {
      ...values,
      memberships: memberships.map((m) => ({
        ...m,
        user: m.user.id ?? m.user,
        role: m.role.id ?? m.role,
      })),
      attachments: attachments.map(({ key, ...rest }) => rest),
      report_attachments: reportAttachments.map(({ key, ...rest }) => rest),
      milestones: milestones.map((m) => ({
        ...m,
        status: m.status.id ?? m.status,
      })),
      indicators: indicators.map(({ key, ...rest }) => rest),
      tracking_notes: trackingNotes.map((tn) => ({
        ...tn,
        user: tn.user.id ?? tn.user.user_id ?? tn.user,
      })),
      location: data.location.id ?? data.location,
      status: data.status?.id ?? data.status,
      substatus: data.substatus?.id ?? data.substatus,
      topic: data.topic?.id ?? data.topic,
      productive_sector: data.productive_sector?.id ?? data.productive_sector,
    };

    if (isEdit) {
      editProject(newData, {
        onSuccess: () => {
          setSuccessResponse(true);
        },
        onError: (err) => setResponseError(err?.response?.status),
      });
    } else {
      createProject(newData, {
        onSuccess: () => {
          resetForm();
          setMemberships(null);
          setMilestones(null);
          setIndicators(null);
          setTrackingNotes(null);
          setSuccessResponse(true);
        },
        onError: (err) => setResponseError(err?.response?.status),
      });
    }
    if (setSuccessResponse) {
      setIsOpenModal(true);
    }
  };

  if (isEdit && isProjectLoading) {
    return <Spinner />;
  }

  return (
    <div className="dash-area">
      <Formik
        initialValues={handleInitialValues()}
        validationSchema={
          isEdit ? editFormSchemaValidator : addFormSchemaValidator
        }
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} style={{ width: "auto" }}>
            <div className="head-form">
              <div>
                <p className="breadcrumb">
                  <strong>Proyectos</strong> <span className="pipe">/</span>
                  {isEdit ? project.name : "Nuevo proyecto"}
                </p>
              </div>

              {isCreateMode || isEditMode ? (
                <div className="col-form">
                  {" "}
                  <SaveButton
                    disabled={isDisabled}
                    onClick={() => navigate(-1)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="tabs-card scrollbar-thumb scrollbar-track scrollbar-scroll">
              <ul className="tab-menu">
                {tabs.map((tab) => (
                  <li key={tab.id} onClick={() => setSelectedTab(tab.id)}>
                    <span
                      className={
                        selectedTab === tab.id
                          ? "tab-item rubik-bold menu  selected"
                          : "tab-item rubik-bold menu "
                      }
                    >
                      {tab.label}
                    </span>
                  </li>
                ))}
              </ul>

              <TabsLayout>
                {handleLayoutsTabsWithExtendedProps(
                  formik,
                  tabs.find((tab) => tab.id === selectedTab).content
                )}
              </TabsLayout>
            </div>
          </form>
        )}
      </Formik>
      <ResponseModal
        successResponse={successResponse}
        successText={successText}
        responseError={responseError}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </div>
  );
}

export { AddEditProject };
