const UrlIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="#21619F" />
      <path
        d="M5.87891 10.1208L10.1215 5.87811"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0606 11.1819L7.29283 12.9497C6.73019 13.5121 5.96718 13.8281 5.17162 13.828C4.37606 13.8279 3.6131 13.5119 3.05055 12.9493C2.48801 12.3868 2.17194 11.6238 2.17188 10.8282C2.17181 10.0327 2.48774 9.26968 3.05019 8.70703L4.81796 6.93927"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1821 9.06029L12.9499 7.29253C13.5123 6.72988 13.8282 5.96687 13.8282 5.17131C13.8281 4.37575 13.512 3.6128 12.9495 3.05025C12.387 2.4877 11.624 2.17164 10.8284 2.17157C10.0329 2.1715 9.26986 2.48744 8.70722 3.04989L6.93945 4.81765"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { UrlIcon };
