import { Badge } from "UI/atoms";

import "react-datepicker/dist/react-datepicker.css";

const ReadOnly = ({ indicators }) => {
  return (
    <>
      <div className="tablayer tab-parametros">
        <table className="dash-table dash-table-inverse">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Fecha</th>
              <th>Meta</th>
              <th>Resultados del indicador</th>
            </tr>
          </thead>
          <tbody>
            {indicators ? (
              indicators.map((indicator) => (
                <tr
                  key={indicator.key ? indicator.key : indicator.id}
                  style={{ textAlign: "center" }}
                >
                  <td>
                    <strong>{indicator.name}</strong>
                  </td>
                  <td>
                    <small className="ellipsis">{indicator.description}</small>
                  </td>
                  <td>
                    <small>{indicator.date}</small>
                  </td>
                  <td>
                    <small className="ellipsis">{indicator.goal}</small>
                  </td>
                  <td>
                    <small className="ellipsis">{indicator.scope}</small>
                  </td>
                </tr>
              ))
            ) : (
              <div className="barlow-medium center">Sin indicadores</div>
            )}
          </tbody>
        </table>
        <div className="pager">
          <Badge
            key={1}
            background="white"
            color="#0379F2"
            fontWeight="bold"
            onClick={() => "setPageNumber(lof)"}
            style={{
              cursor: "pointer",
              textAlign: "center",
              margin: "0 0.25rem",
            }}
          >
            1
          </Badge>
        </div>
      </div>
    </>
  );
};

export { ReadOnly };
