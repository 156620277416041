import { useCommonLookups } from "queries";
import { useMemo } from "react";

function useLookups() {
  const { data: provinces, isLoading: isProvincesLoading } =
    useCommonLookups("locations");
  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("topics");
  const { data: statuses, isLoading: isStatusesLoading } =
    useCommonLookups("project-status");
  const { data: substatuses, isLoading: isSubstatusesLoading } =
    useCommonLookups("project-substatus");
  const { data: productiveSectors, isLoading: isProductiveSectorsLoading } =
    useCommonLookups("project-productive-sectors");
  const { data: membershipRoles, isLoading: isMembershipRolesLoading } =
    useCommonLookups("project-membership-roles");
  const { data: milestoneStatuses, isLoading: isMilestoneStatusesLoading } =
    useCommonLookups("project-milestone-status");
  const { data: users, isLoading: isUserLoading } = useCommonLookups("users");

  const lookups = useMemo(
    () => ({
      provinces,
      topics,
      statuses,
      substatuses,
      users,
      membershipRoles,
      productiveSectors,
      milestoneStatuses,
    }),
    [
      provinces,
      topics,
      statuses,
      substatuses,
      users,
      membershipRoles,
      productiveSectors,
      milestoneStatuses,
    ]
  );

  const isLoading =
    isProvincesLoading ||
    isTopicsLoading ||
    isStatusesLoading ||
    isSubstatusesLoading ||
    isUserLoading ||
    isMembershipRolesLoading ||
    isProductiveSectorsLoading ||
    isMilestoneStatusesLoading;

  return { isLoading, ...lookups };
}

export default useLookups;
