const theme = {
  fontSizes: {
    xxs: "9px",
    xs: "12px",
    sm: "14px",
    base: "16px",
    customBase: "13px",
    regular: "20px",
    medium: "22px",
    extended: "24px",
    lg: "30px",
    xlg: "35px",
    xxl: "40px",
    "2xxl": "50px",
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  fontFamilies: {
    main: "Rubik",
  },
  colors: {
    primary: "#21619f",
    secondary: "#ff9841",
    tertiary: "#ffcb00",
    estadoverde: "#60b959",
    estadoamarillo: "#ed4e",
    estadorojo: "#e00000",
    body: "#545454",
    sec: "#6f83a0",
    third: "#d5dde9",
    bgmain: "#f0f4f7",
    bgazul: "#ebf2ff",
    bgverde: "#e4f6ee",
    bgamarillo: "#fdf5df",
    bgrojo: "#ffefea",
  },
  maxWidth: {
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    xxl: "42rem",
    x2xl: "48rem",
  },
};

export { theme };
