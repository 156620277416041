import {
  ADMIN_ROLES,
  CONSULTANT_ROLES,
  COORDINATOR_ROLES,
  UE_ROLES,
} from "./list-items-by-role";

const handleSidebarAccordingToUserRole = (role) => {
  switch (true) {
    case role === "Consorcio":
      return ADMIN_ROLES;
    case role === "Aliado":
      return COORDINATOR_ROLES;
    case role === "Comunidad":
      return CONSULTANT_ROLES;
    case role === "UE":
      return UE_ROLES;
    default:
      return;
  }
};

export { handleSidebarAccordingToUserRole };
