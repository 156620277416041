import { Badge } from "UI/atoms";

export const ReadOnly = ({ milestones, handleColors, handleName }) => {
  return (
    <>
      <div className="tablayer tab-parametros">
        <table className="dash-table dash-table-inverse">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Fecha de inicio</th>
              <th>Fecha fin</th>
              <th>Estado del hito</th>
            </tr>
          </thead>
          <tbody>
            {!!milestones ? (
              milestones.map((milestone) => (
                <tr
                  key={milestone.key ? milestone.key : milestone.id}
                  style={{ textAlign: "center" }}
                >
                  <td>
                    <strong>{milestone.name}</strong>
                  </td>
                  <td>
                    <small className="ellipsis">{milestone.description}</small>
                  </td>
                  <td>
                    <small>{milestone.start_date}</small>
                  </td>
                  <td>
                    <small>{milestone.end_date}</small>
                  </td>
                  <td className="nowrap">
                    <Badge
                      background={
                        milestone.status.color
                          ? milestone.status.color.code
                          : handleColors(milestone.status)
                      }
                      className="badge estado"
                    >
                      {milestone.status.name ?? handleName(milestone.status)}
                    </Badge>
                  </td>
                </tr>
              ))
            ) : (
              <div className="barlow-medium center">Sin hitos</div>
            )}
          </tbody>
        </table>
        <div className="pager">
          <Badge
            key={1}
            background="white"
            color="#0379F2"
            fontWeight="bold"
            onClick={() => "setPageNumber(lof)"}
            style={{
              cursor: "pointer",
              textAlign: "center",
              margin: "0 0.25rem",
            }}
          >
            1
          </Badge>
        </div>
      </div>
    </>
  );
};
