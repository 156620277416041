import React from 'react'

const CloudArrowDownIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.06024 10.8787L10.1484 13L12.2366 10.8787" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1484 8V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.70313 13H5.22657C4.73797 12.9995 4.25506 12.8935 3.8099 12.6889C3.36473 12.4843 2.96749 12.1859 2.64454 11.8134C2.32159 11.441 2.08031 11.003 1.93673 10.5285C1.79315 10.0541 1.75054 9.55407 1.81174 9.06162C1.87294 8.56918 2.03654 8.09559 2.29168 7.67229C2.54683 7.24899 2.88768 6.88567 3.29162 6.60642C3.69556 6.32718 4.15335 6.13841 4.6346 6.05265C5.11584 5.96688 5.60954 5.98608 6.08293 6.10897" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.71875 8C5.71883 7.20777 5.90421 6.4269 6.25963 5.72173C6.61505 5.01657 7.13032 4.40729 7.763 3.9441C8.39567 3.48092 9.12763 3.17708 9.89857 3.05764C10.6695 2.9382 11.4573 3.00657 12.1972 3.25711C12.937 3.50765 13.6076 3.9332 14.1538 4.49868C14.7 5.06416 15.1061 5.7534 15.3386 6.50959C15.5711 7.26578 15.6235 8.06728 15.4913 8.84804C15.3591 9.6288 15.0462 10.3665 14.5783 11.0003" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {CloudArrowDownIcon}