import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body { 
    margin: 0;
    font-family: 'Rubik', sans-serif;
  }
`;

export default GlobalStyle;
