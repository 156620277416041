import styled from "styled-components";

const Button = ({
  children,
  background = "#21619F",
  fontSize = "customBase",
  type,
  fontWeight = "700",
  style,
  color = "white",
  padding,
  margin,
  className,
  width,
  height = "44px",
  borderRadius = "10px",
  onClick,
  disabled,
}) => {
  const buttonClass = disabled ? "disabled" : "";

  return (
    <StyledButton
      onClick={onClick}
      background={background}
      fontSize={fontSize}
      style={style}
      color={color}
      fontWeight={fontWeight}
      type={type}
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
      className={`${className} ${buttonClass}`}
      height={height}
      width={width}
      // disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: ${(props) => props.width};

  color: ${(props) => props.color};
  background: ${(props) => props.background};

  font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};
  text-align: center;

  border: none;
  border-radius: ${(props) => props.borderRadius};

  cursor: pointer;

  &.disabled {
    // pointer-events: none;
    opacity: 0.5;
    cursor: pointer;
  }
`;

export { Button };
