const provincias = [
  { id: "1", name: "Misiones" },
  { id: "2", name: "San Luis" },
  { id: "3", name: "San Juan" },
  { id: "4", name: "Entre Ríos" },
  { id: "5", name: "Santa Cruz" },
  { id: "6", name: "Río Negro" },
  { id: "7", name: "Chubut" },
  { id: "8", name: "Córdoba" },
  { id: "9", name: "Mendoza" },
  { id: "10", name: "La Rioja" },
  { id: "11", name: "Catamarca" },
  { id: "12", name: "La Pampa" },
  { id: "13", name: "Santiago del Estero" },
  { id: "14", name: "Corrientes" },
  { id: "15", name: "Santa Fe" },
  { id: "16", name: "Tucumán" },
  { id: "17", name: "Neuquén" },
  { id: "18", name: "Salta" },
  { id: "19", name: "Chaco" },
  { id: "20", name: "Formosa" },
  { id: "21", name: "Jujuy" },
  { id: "22", name: "Ciudad Autónoma de Buenos Aires" },
  { id: "23", name: "Buenos Aires" },
  { id: "24", name: "Tierra del Fuego" },
];

export { provincias };
