import styled from "styled-components";

const StatusProgress = ({ ...props }) => {
  const { count, color, labelText } = props;

  return (
    <Container>
      <Count color={color}>{count}</Count>
      <Label>
        PROYECTOS <br></br>
        {labelText}
      </Label>
    </Container>
  );
};

const Container = styled.ul`
  max-width: 194px;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 58px;
`;

const Count = styled.p`
  width: 79px;
  height: 83px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 83px;
  color: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
`;
const Label = styled.p`
  max-height: 38px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-aling: center;
  color: #545454;
`;
export { StatusProgress };
