import styled from 'styled-components'
import {Link} from 'react-router-dom'

const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 100;
  right: ${props => props.position === 'left' ? '7.25rem' : undefined};
  top: ${props => props.position === 'right' ? '150px' : undefined};
  left: ${props => props.position === 'right' ? '90px' : undefined};
`;

const DropDownList = styled("ul")`
  padding: 1rem;
  margin: 0;

  background: white;
  color: ${props => props.theme.colors.dark};

  border: 1px solid transparent;
  border-radius: 10px;

  box-sizing: border-box;

  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 500;
`;

const ListItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
  text-decoration: none;

  margin-bottom: 1rem;
  &:hover {
    color: ${props => props.theme.colors.violet};
  }
`;

export {DropDownListContainer, DropDownList, ListItem}