import { Button, Input } from "UI/atoms";
import { Plus } from "shared/assets/svgs";

import "react-datepicker/dist/react-datepicker.css";

import { BoxList } from "UI/atoms/BoxList";
import { useEffect, useState } from "react";
import Modal from "styled-react-modal";
import { getTime, getTodayDate } from "utils/getDate";
import { TrackingNotesModal } from "./ui/TrackingNotesModal";
import DeleteModal from "../../atoms/DeleteModal";
import { useDeleteObservationFromProject } from "queries";
const Tracking = ({
  trackingNotes,
  setTrackingNotes,
  isEditMode,
  isCreateMode,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState();
  const [showDetail, setShowDetail] = useState();
  const today = new Date();
  const [isCheck, setIsCheck] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { mutate: deleteObservationFromProject } = useDeleteObservationFromProject(showDetail?.id || "12");

  const initialChecks = trackingNotes?.map((trackingNote) => ({
    id: trackingNote.key || trackingNote.id,
    finished: trackingNote.finished_at !== null,
  }));

  const [checks, setChecks] = useState(initialChecks);

  useEffect(() => {
    const detailId = showDetail?.key || showDetail?.id;
    const checked =
      checks.find((check) => check.id === detailId)?.finished || false;
    setIsCheck(checked);
  }, [showDetail, setIsCheck, checks]);

  useEffect(() => {
    if (deleteObservationFromProject.mutate)
    deleteObservationFromProject.mutate(showDetail?.id);
  }, [showDetail, deleteObservationFromProject]);


  const isChecked = () => {
    const tracking = checks.find((c) =>
      showDetail.key ? showDetail.key === c.id : showDetail.id === c.id
    );
    return tracking?.finished;
  };

  const handleCheck = (id, checked) => {
    const updatedChecks = checks.map((check) =>
      check.id === id ? { ...check, finished: checked } : check
    );
    setChecks(updatedChecks);
    setIsCheck(checked);

    let trackingNotesCopy = [...trackingNotes];
    const trackingNotesIdx = trackingNotesCopy.findIndex(
      (trackingNote) => trackingNote.key === id || trackingNote.id === id
    );

    if (checked === true) {
      trackingNotesCopy[trackingNotesIdx] = {
        ...trackingNotesCopy[trackingNotesIdx],
        finished_at: today.toJSON(),
      };
    } else {
      trackingNotesCopy[trackingNotesIdx] = {
        ...trackingNotesCopy[trackingNotesIdx],
        finished_at: null,
      };
    }
    setTrackingNotes(trackingNotesCopy);
  };

  const getDate = (date) => {
    const today = new Date(date);
    const todayDate =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();

    return todayDate;
  };

  const deleteObservation = () => {
    let trackingNotesCopy = [...trackingNotes];
    const trackingNotesIdx = trackingNotesCopy.findIndex(
      (trackingNote) => trackingNote.id === showDetail.id
    );
    trackingNotesCopy.splice(trackingNotesIdx, 1);
    setTrackingNotes(trackingNotesCopy);
    setIsOpenDeleteModal(false);
    setSelectedObservation(null);
    setShowDetail(null);
    deleteObservationFromProject(showDetail.id);
  };


  return (
    <>
      {" "}
      <div className="tablayer tab-parametros">
        <div className="list-row" style={{ marginRight: "60px" }}>
          <div>
            {isEditMode || isCreateMode ? (
              <Button
                type={"button"}
                height="24px"
                color="#21619F"
                background="transparent"
                className="btn-with-icon addfile"
                onClick={() => setIsOpenModal(true)}
              >
                <span className="blue-circle">
                  <Plus />
                </span>{" "}
                <span>AGREGAR OBSERVACIÓN</span>
              </Button>
            ) : (
              ""
            )}

            <div
              style={{
                margin: "15px 0px 15px 0px",
                width: "full",
                height: "24rem",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              className="scrollbar-thumb scrollbar-track scrollbar-scroll"
            >
              <ul>
                {trackingNotes != null && trackingNotes.length ? (
                  trackingNotes.map((observation) => (
                    <BoxList
                      key={observation.key}
                      background={
                        observation.finished_at ? "#E4F6EE" : "#FFEFEA"
                      }
                      borderRadius={"10px"}
                      width={"210px"}
                      height={"112px"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      padding={"10px"}
                      margin={"8px 0px 8px 0px"}
                      onClick={() => setShowDetail(observation)}
                      style={{ position: "relative" }}
                    >
                        {/* cross icon */}
                        {(isEditMode || isCreateMode) && (
                          <div
                            className="position-absolute"
                            style={{
                              top: "0px",
                              right: "0px",
                              margin: "10px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowDetail(observation);
                              setIsOpenDeleteModal(true);
                            }}
                          >
                            <span>&#10005;</span>
                          </div>
                        )}
                      <div style={{ margin: "2px 0px 5px 0px" }}>
                        <span
                          className="barlow-medium"
                          style={{ fontSize: "11px" }}
                        >
                          {/* Nombre y Apellido */}
                          {observation.user?.first_name}{" "}
                          {observation.user?.last_name}
                        </span>
                        <br />
                        <span
                          className="barlow-medium"
                          style={{ fontSize: "11px" }}
                        >
                          {getDate(observation.created_at ?? today)}
                        </span>
                        <br />
                        <span
                          className="barlow-medium"
                          style={{ fontSize: "11px" }}
                        >
                          {getTime(observation.created_at ?? today)}
                        </span>
                      </div>

                      <p style={{ fontSize: "13px" }}>
                        <b
                          className="barlow-bold ellipsis"
                          style={{ maxHeight: ".8rem" }}
                        >
                          {" "}
                          {observation.name}
                        </b>
                        <small
                          className="ellipsis"
                          style={{ marginTop: "2px" }}
                        >
                          {observation.description}
                        </small>
                      </p>
                    </BoxList>
                  ))
                ) : (
                  <div className="barlow-medium">Sin observaciones</div>
                )}
              </ul>
            </div>
          </div>
          {showDetail ? (
            <div
              className="column"
              style={{
                margin: "0px 10px 15px 49px",
                justifyContent: "space-between",
              }}
            >
              <h4 style={{ color: "#6F83A0" }}>
                {showDetail.user.first_name}
                {showDetail.user.last_name}
              </h4>
              <br />
              <h2>{showDetail.name}</h2>
              <br />
              <p className="barlow-medium body"> {showDetail.description}</p>
              <br />
              {isEditMode || isCreateMode ? (
                <div
                  style={{
                    margin: "23px 0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    id="budget"
                    name="budget"
                    type="checkbox"
                    className="form-control"
                    onChange={(e) => {
                      if (trackingNotes !== null) {
                        handleCheck(
                          showDetail.key ? showDetail.key : showDetail.id,
                          e.target.checked
                        );
                      }
                    }}
                    checked={isChecked(
                      showDetail.key ? showDetail.key : showDetail.id
                    )}
                  />
                  <label
                    htmlFor="budget"
                    className="form-label"
                    style={{ marginTop: "12.5px" }}
                  >
                    Finalizada el{" "}
                    {showDetail.finished_at
                      ? getDate(showDetail.finished_at)
                      : getTodayDate()}
                  </label>
                </div>
              ) : (
                <label
                  htmlFor="budget"
                  className="form-label"
                  style={{ marginTop: "12.5px" }}
                >
                  {showDetail.finished_at
                    ? `Finalizada el ${getDate(showDetail.finished_at)}`
                    : "No finalizada"}
                </label>
              )}
            </div>
          ) : (
            <div style={{ margin: "15px 48px" }}>
              <p className="barlow-medium ">
                No hay observaciones seleccionadas
              </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="610px"
        height={"90%"}
        padding="25px 32px"
        className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
      >
        <TrackingNotesModal
          setIsOpenModal={setIsOpenModal}
          trackingNotes={trackingNotes}
          setTrackingNotes={setTrackingNotes}
          selectedObservation={selectedObservation}
          setSelectedObservation={setSelectedObservation}
        />
      </Modal>
      <DeleteModal isOpenModal={isOpenDeleteModal} setIsOpenModal={setIsOpenDeleteModal} callback={deleteObservation}/>
    </>
  );
};

export { Tracking };
