import {
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Scatter as S,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

const COLORS = ["#FFCB00", "#6F83A0", "#FF9841", "#60B959"];
const SHAPE = ["star", "triangle", "square", "diamond", "polygon"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <ToolTip>
        <ToolTipTitle>{payload[0].payload.name}</ToolTipTitle>
        <ToolTipText>{`${payload[0].payload.topicName}`}</ToolTipText>
        <ToolTipText>{`${payload[0].payload.productiveSectorName}`}</ToolTipText>
        <ToolTipText>{`Iniciado: ${payload[0].payload.presented}`}</ToolTipText>
        <ToolTipText>{`Estado: ${payload[0].payload.substatusName}`}</ToolTipText>
      </ToolTip>
    );
  }

  return null;
};

const Scatter = ({ data, productiveSectors }) => (
  <div style={{ marginTop: 20, height: 225 }}>
    {Object.keys(data).length !== 0 && (
      <ResponsiveContainer width="100%" height={225}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid horizontal={false} strokeDasharray="2" />
          <XAxis
            type="category"
            allowDuplicatedCategory={false}
            dataKey="date"
            name="Tiempo de inicio"
            tickLine={false}
            interval={"preserveStartEnd"}
            label={{
              value: "Tiempo de inicio",
              position: "bottom",
              fontFamily: "Barlow",
              fontWeight: "500",
              fontSize: 13,
              fill: "#545454",
            }}
            tickMargin={10}
            fontSize={13}
            tick={{
              fill: "#687B8C",
              fontFamily: "Barlow",
              fontWeight: "500",
            }}
          />
          <YAxis
            type="number"
            dataKey="budget"
            name="Presupuesto"
            tickLine={false}
            interval={0}
            label={{
              value: "Presupuesto",
              angle: -90,
              position: "left",
              fontSize: 13,
              fontFamily: "Barlow",
              fontWeight: "500",
              fill: "#545454",
              style: { textAnchor: "middle" },
            }}
            tickFormatter={(value) =>
              (value === 900000 && "$900k") ||
              (value === 1800000 && "$1.8m") ||
              "$2.7m"
            }
            ticks={[900000, 1800000, 2700000]}
            domain={["dataMin", "dataMax"]}
            fontSize={13}
            tick={{
              fill: "#687B8C",
              fontFamily: "Barlow",
              fontWeight: "500",
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ strokeDasharray: "3 3" }}
          />
          <Legend
            verticalAlign="top"
            align="start"
            wrapperStyle={{
              top: 0,
              left: 90,
              fontSize: 13,
              width: 410,
              whiteSpace: "break-spaces",
              lineHeight: "20px",
              fontFamily: "Barlow",
              fontWeight: "500",
            }}
            iconSize={10}
            payload={[
              {
                value: "Aprobado",
                type: "circle",
                color: "#60B959",
              },
              {
                value: "Observación",
                type: "circle",
                color: "#FF9841",
              },
              {
                value: "Propuestas",
                type: "circle",
                color: "#FFCB00",
              },
              {
                value: "En desarrollo",
                type: "circle",
                color: "#6F83A0",
              },
            ].concat(
              Object.keys(productiveSectors).map((s) => ({
                value: productiveSectors[s][0].sectorName,
                type: SHAPE[productiveSectors[s][0].sectorId % SHAPE.length],
                payload: { strokeDasharray: 0 },
              }))
            )}
            fontSize={13}
          />
          {Object.keys(productiveSectors).map((s, index) => (
            <S
              key={`${index}-${data[productiveSectors[s][0].sectorId]}`}
              name={productiveSectors[s][0].sectorName}
              data={data[productiveSectors[s][0].sectorId]}
              shape={SHAPE[productiveSectors[s][0].sectorId % SHAPE.length]}
            >
              {data[productiveSectors[s][0].sectorId]?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[entry.statusId % COLORS.length]}
                />
              ))}
            </S>
          ))}
          <S name="Hidrocarburos" data={data[1]} shape="triangle">
            {data[1]?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.statusId % COLORS.length]}
              />
            ))}
          </S>
        </ScatterChart>
      </ResponsiveContainer>
    )}
  </div>
);

const ToolTip = styled.div`
  width: 200px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
`;

const ToolTipTitle = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const ToolTipText = styled.p`
  font-size: 12px;
`;

export default Scatter;
