import { groupBy } from "../utils/groupBy";

const getGroupedProjectsPerStatus = (projects = [], selectedYears = []) => {
  var months = [
    "",
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ];

  const groupedDataByStatus = groupBy(
    projects.map((p) => {
      return {
        name: p.name,
        topicName: p.topic?.name,
        topicId: p.topic?.id,
        date: `${months[new Date(p.start_date).getMonth() + 1]}-${new Date(
          p.end_date
        ).getFullYear()}`,
        statusId: p.status?.id,
        statusName: p.status?.name,
        substatusId: p.substatus?.id,
        substatusName: p.substatus?.name,
        productiveSectorId: p.productive_sector?.id,
        productiveSectorName: p.productive_sector?.name,
        presented: p.start_date ? p.start_date : "-",
        voted: p.end_date ? p.end_date : "-",
        budget: Number(p.budgeted_money),
      };
    }),
    "productiveSectorId"
  );

  Object.values(groupedDataByStatus).forEach((group) => {
    for (let year = selectedYears[1]; year >= selectedYears[0]; year--) {
      for (let month = 12; month >= 1; month--) {
        group.unshift({
          date: `${months[month]}-${year}`,
        });
      }
    }
  });

  const groupedProductiveSectors = groupBy(
    projects
      .filter((p) => p.status.id !== 6)
      .map((p) => {
        return {
          sectorName: p.productive_sector.name,
          sectorId: p.productive_sector.id,
        };
      }),
    "sectorId"
  );

  return {
    groupedDataByStatus,
    groupedProductiveSectors,
  };
};

export { getGroupedProjectsPerStatus };
