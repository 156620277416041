import { Map } from "UI/molecules/Map";
import { StatusProgress } from "UI/molecules/StatusProgress";
import { Progress } from "UI/molecules/index";
import { Card } from "UI/organisms";
import { DashArea } from "UI/organisms/DashArea";
import { useColors } from "queries/lookups";
import { Spinner } from "shared/assets/svgs";
import { getProjectsAmountPerStatus } from "utils/getProjectsAmountPerStatus";
import { getProjectsAmountPerTopic } from "../../utils/getProjectsAmountPerTopic";
import { TOPIC_COLOR_TYPES } from "./types/index";
import { getProjectsAmountPerCountry } from "./utils/getProjectsAmountPerCountry";

function Escritorio({ projects }) {
  const projectsAmountPerCountry = getProjectsAmountPerCountry(projects);

  const { total, results } = getProjectsAmountPerTopic(projects);
  const { data: colors, isLoading } = useColors();
  const { total: totalProjectsByAllStatus, results: projectsByStatus } =
    getProjectsAmountPerStatus(projects);

  const handleColors = (projectLookupColor) => {
    return colors?.find((color) => color.id === projectLookupColor.id).code;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div style={{ margin: 0 }}>
      <DashArea section={"Escritorio"}>
        <div className="escritorio">
          <Map mapData={projectsAmountPerCountry} />
          <div className="ranges ">
            <div className="box-ranges scrollbar-thumb scrollbar-track scrollbar-scroll">
              {projectsByStatus?.map((project, index) => (
                <StatusProgress
                  key={index}
                  count={project.count}
                  total={totalProjectsByAllStatus}
                  color={handleColors(project.color)}
                  bgColor="#EBF2FF"
                  labelText={project.status}
                  labelNumber={project.count}
                />
              ))}
            </div>
            <Card>
              {results.map((topic, index) => (
                <Progress
                  key={index}
                  count={topic.count}
                  total={total}
                  color={TOPIC_COLOR_TYPES.color.default}
                  bgColor={TOPIC_COLOR_TYPES.background.default}
                  labelText={topic.name}
                  labelNumber={topic.count}
                />
              ))}
            </Card>
          </div>
        </div>
      </DashArea>
    </div>
  );
}

export { Escritorio };
