import React from "react";
import styled from "styled-components";

const ErrorFallback = ({ error }) => {
  // const {logout} = useAuth()
  return (
    <StyledError>
      {error.response?.status === 403 ? (
        <div>
          Expiro tu sesion, por favor
          <button onClick={() => console.log("logout here")}>
            haz click aqui
          </button>
          para logearte nuevamente.
        </div>
      ) : (
        <div>
          Oops, algo salio mal. Por favor{" "}
          <button onClick={() => (window.location.href = "/desktop")}>
            haz click aqui
          </button>{" "}
          e intenta nuevamente.
        </div>
      )}
    </StyledError>
  );
};

const StyledError = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.colors.violet};
  color: white;
`;

export { ErrorFallback };
