import styled from "styled-components";

export const DashArea = ({ children, section }) => {
  return (
    <StyledDashArea>
      <Breadcrumb>{section}</Breadcrumb>
      {children}
    </StyledDashArea>
  );
};

export const Breadcrumb = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #21619f;
`;

const StyledDashArea = styled.div`
  width: 100%;
  margin: 18px 0px 0px 48px;
`;
