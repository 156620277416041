import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  client,
  recoverPassword,
  sendRecoverPasswordEmail,
} from "../utils/api-client";

const useCreateUser = (onError) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`users/`, {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
      onSettled: () => queryClient.invalidateQueries("users"),
      onError: (err) => onError(err),
    }
  );
};

const useEditUser = (userId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`users/${userId}/`, {
        method: "PATCH",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
    }
  );
};

const useDeleteUser = (userId) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`users/${userId}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
    }
  );
};

const useEditPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client("me/change_password/", {
        method: "PUT",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
    }
  );
};

const useChangePasswordInFirstLogin = () => {
  return useMutation((data) =>
    client(`me/change_password/`, {
      method: "PUT",
      data,
    })
  );
};

const useForgotPassword = () => {
  return useMutation((mail) => {
    sendRecoverPasswordEmail(mail);
  });
};

const usePasswordRecover = () => {
  return useMutation((payload) => {
    recoverPassword(payload);
  });
};

export {
  useChangePasswordInFirstLogin,
  useCreateUser,
  useEditPassword,
  useEditUser,
  useDeleteUser,
  useForgotPassword,
  usePasswordRecover,
};
