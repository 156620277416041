import { Button } from "UI/atoms";
import Tooltip from "rc-tooltip";
import { CheckCloud } from "shared/assets/svgs/CheckCloud";

const SaveButton = ({ onClick, disabled }) => {
  return (
    <>
      <Button
        width="124px"
        background="#D5DDE9"
        color="#6F83A0"
        className="btn-with-icon"
        onClick={onClick}
        type={"button"}
      >
        CANCELAR
      </Button>

      <Button
        background={"#60B959"}
        width="124px"
        className="btn-with-icon white-icon"
        type="submit"
        disabled={disabled}
      >
        <CheckCloud />{" "}
        <Tooltip
          placement="right"
          showArrow={true}
          overlay={
            disabled
              ? "Los proyectos finalizados no pueden ser editados"
              : "Guardar"
          }
          overlayStyle={{ background: "transparent" }}
          overlayInnerStyle={{
            borderRadius: "10px",
            font: '1em "DM Sans", Arial, Helvetica, sans-serif',
          }}
        >
          <span>GUARDAR</span>
        </Tooltip>
      </Button>
    </>
  );
};

export default SaveButton;
