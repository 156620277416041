import { Button, Input } from "UI/atoms";
import { Trash } from "shared/assets/svgs";
import { LinkIcon } from "shared/assets/svgs/LinkIcon";

export const AttachmentInput = ({
  attachment,
  setAttachments,
  attachments,
  onClick,
}) => {
  const handleChange = (attachmentId, value, type, index) => {
    let attachmentsCopy = [...attachments];
    const attachmentIdx = attachmentsCopy.findIndex(
      (attachment) =>
        attachment.key === attachmentId || attachment.id === attachmentId
    );
    if (type === "name") {
      attachmentsCopy[attachmentIdx] = {
        ...attachmentsCopy[attachmentIdx],
        name: value,
      };
    }
    if (type === "url") {
      attachmentsCopy[attachmentIdx] = {
        ...attachmentsCopy[attachmentIdx],
        url: value,
      };
    }
    setAttachments(attachmentsCopy);
  };

  return (
    <div className="item">
      <div className="row" style={{ width: "100%" }}>
        <Input
          id="name"
          name="name"
          type="text"
          width={"40%"}
          height="44px"
          className="form-control"
          placeholder="Nombre del archivo"
          onChange={(e) => {
            handleChange(
              attachment.key ?? attachment.id,
              e.target.value,
              "name"
            );
          }}
          value={attachment.name ?? ""}
        />

        <Input
          id="url"
          name="url"
          type="text"
          width={"60%"}
          height="44px"
          className="form-control"
          placeholder="Link del archivo"
          onChange={(e) => {
            const newUrl = e.target.value;
            handleChange(attachment.key ?? attachment.id, newUrl, "url");
          }}
          value={attachment.url ?? ""}
        />
      </div>

      <Button
        type="button"
        height="30px"
        width="30px"
        background="#21619F"
        className="circle-btn"
        onClick={() => navigator.clipboard.writeText(attachment.url)}
      >
        <LinkIcon />
      </Button>
      <Button
        type="button"
        height="30px"
        width="30px"
        background="#E00000"
        className="circle-btn"
        onClick={onClick}
      >
        <Trash />
      </Button>
    </div>
  );
};
