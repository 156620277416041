import styled from "styled-components";

const Badge = ({
  children,
  background = "transparent",
  fontSize = "xs",
  fontWeight = "700",
  color = "#fff",
  margin = "0",
  padding = "9px 15px",
  border = "none",
  borderRadius = "20px",
  style,
  className,
  onClick,
  height,
  width
}) => {
  return (
    <StyledBadge
      background={background}
      color={color}
      padding={padding}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      fontSize={fontSize}
      fontWeight={fontWeight}
      style={style}
      className={className}
      onClick={onClick}
      height={height}
      width={width}
    >
      {children}
    </StyledBadge>
  );
};

const StyledBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${props => props.width ? props.width : 'fit-content'};
  height: ${props => props.height ? props.height : null};

  padding: ${(props) => (props.padding ? props.padding : " 0 15px;")};
  margin: ${(props) => props.margin};

  color: ${(props) => props.color};
  background: ${(props) => props.background};

  font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;

  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};

  cursor: pointer;
`;

export { Badge };
