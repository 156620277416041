import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

const apiURL = process.env.REACT_APP_API_URL;
const cleanApiURL = process.env.REACT_APP_API_CLEAN_URL;
const localStorageToken = "__auth_provider_token__";
const localStorageRefresh = "__auth_provider_refresh__";

async function client(
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) {
  const token = localStorage.getItem(localStorageToken);
  const refresh = localStorage.getItem(localStorageRefresh);
  const config = {
    method: data ? "POST" : "GET",
    data: data ? data : undefined,
    ...customConfig,
  };

  const axiosInstance = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${token}`,
      ...customHeaders,
    },
    ...customConfig,
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const decodedToken = jwt_decode(token);
    const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;
    const response = await axios.post(`${apiURL}/token/refresh/`, {
      refresh,
    });

    const data = response.data;
    if (response.status === 200) {
      localStorage.setItem(localStorageToken, data.access);
      localStorage.setItem(localStorageRefresh, data.refresh);
    }
    req.headers.Authorization = `Bearer ${data.access}`;
    return req;
  });

  const req = axiosInstance(`${apiURL}/${endpoint}`, config).then(
    async (response) => {
      const data = response.data;

      if (response.status === 200 || response.status === 201) {
        return data;
      } else {
        return Promise.reject(data);
      }
    }
  );
  // .catch(async (data) => {
  //   if (data.response.status === 401) {
  //     login({
  //       data: { refresh: localStorage.getItem(localStorageRefresh) },
  //       isRefresh: true,
  //     });
  //     return Promise.reject(data);
  //   }
  // });

  return req;
}

async function login({ data, isRefresh } = {}) {
  const config = {
    method: "POST",
    data: data,
  };

  const req = axios(
    `${apiURL}/token/${isRefresh ? "refresh/" : ""}`,
    config
  ).then(async (response) => {
    const data = response.data;
    if (response.status === 200) {
      localStorage.setItem(localStorageToken, data.access);
      localStorage.setItem(localStorageRefresh, data.refresh);
      return data.access;
    } else {
      return Promise.reject(data);
    }
  });

  return req;
}

async function sendRecoverPasswordEmail(email) {
  const config = {
    method: "POST",
    data: { email },
  };

  const req = axios(
    `${cleanApiURL}/v1/api/common/auth/reset-password/`,
    config
  ).then(async (response) => {
    const data = response.data;
    if (response.status === 200) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });

  return req;
}

async function recoverPassword(data) {
  const config = {
    method: "POST",
    data,
  };

  const req = axios(
    `${cleanApiURL}/v1/api/common/auth/reset-password/confirm/`,
    config
  ).then(async (response) => {
    const data = response.data;
    if (response.status === 200) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });

  return req;
}

async function validateToken(data) {
  const config = {
    method: "POST",
    data,
  };

  const response = await axios(
    `${cleanApiURL}/v1/api/common/auth/reset-password/validate-token/`,
    config
  );

  return response.data;
}

export {
  client,
  login,
  recoverPassword,
  sendRecoverPasswordEmail,
  validateToken,
};
