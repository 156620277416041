const GearIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 6.14062H13.25C12.9169 6.14062 12.6406 5.86437 12.6406 5.53125C12.6406 5.19813 12.9169 4.92188 13.25 4.92188H18.125C18.4581 4.92188 18.7344 5.19813 18.7344 5.53125C18.7344 5.86437 18.4581 6.14062 18.125 6.14062Z"
        fill="#545454"
      />
      <path
        d="M5.125 6.14062H1.875C1.54188 6.14062 1.26562 5.86437 1.26562 5.53125C1.26562 5.19813 1.54188 4.92188 1.875 4.92188H5.125C5.45813 4.92188 5.73438 5.19813 5.73438 5.53125C5.73438 5.86437 5.45813 6.14062 5.125 6.14062Z"
        fill="#545454"
      />
      <path
        d="M8.375 8.98438C6.47375 8.98438 4.92188 7.4325 4.92188 5.53125C4.92188 3.63 6.47375 2.07812 8.375 2.07812C10.2762 2.07812 11.8281 3.63 11.8281 5.53125C11.8281 7.4325 10.2762 8.98438 8.375 8.98438ZM8.375 3.29688C7.14 3.29688 6.14062 4.29625 6.14062 5.53125C6.14062 6.76625 7.14 7.76562 8.375 7.76562C9.61 7.76562 10.6094 6.76625 10.6094 5.53125C10.6094 4.29625 9.61 3.29688 8.375 3.29688Z"
        fill="#545454"
      />
      <path
        d="M18.125 15.0781H14.875C14.5419 15.0781 14.2656 14.8019 14.2656 14.4688C14.2656 14.1356 14.5419 13.8594 14.875 13.8594H18.125C18.4581 13.8594 18.7344 14.1356 18.7344 14.4688C18.7344 14.8019 18.4581 15.0781 18.125 15.0781Z"
        fill="#545454"
      />
      <path
        d="M6.75 15.0781H1.875C1.54188 15.0781 1.26562 14.8019 1.26562 14.4688C1.26562 14.1356 1.54188 13.8594 1.875 13.8594H6.75C7.08313 13.8594 7.35938 14.1356 7.35938 14.4688C7.35938 14.8019 7.08313 15.0781 6.75 15.0781Z"
        fill="#545454"
      />
      <path
        d="M11.625 17.9219C9.72375 17.9219 8.17188 16.37 8.17188 14.4688C8.17188 12.5675 9.72375 11.0156 11.625 11.0156C13.5262 11.0156 15.0781 12.5675 15.0781 14.4688C15.0781 16.37 13.5262 17.9219 11.625 17.9219ZM11.625 12.2344C10.39 12.2344 9.39062 13.2338 9.39062 14.4688C9.39062 15.7038 10.39 16.7031 11.625 16.7031C12.86 16.7031 13.8594 15.7038 13.8594 14.4688C13.8594 13.2338 12.86 12.2344 11.625 12.2344Z"
        fill="#545454"
      />
    </svg>
  );
};

export { GearIcon };
