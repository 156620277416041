import { useMutation } from "@tanstack/react-query";
import { login } from "../utils/api-client";

const useLogin = () => {
  return useMutation((data) =>
    login({
      data,
    })
  );
};

export { useLogin };
