import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

export const useProjectState = (project, isEdit) => {
  const [milestones, setMilestones] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [trackingNotes, setTrackingNotes] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [reportAttachments, setReportAttachments] = useState([]);
  const [urlAttachmentsError, setUrlAttachmentsError] = useState(false);
  const [urlInformError, setUrlInformError] = useState(false);

  const user = jwt_decode(
    localStorage.getItem("__auth_provider_token__") || ""
  );
  const isCommunityUser = user.role === "Comunidad";
  const initialMembershipSelected = isCommunityUser
    ? [
        {
          role: {
            id: 1,
          },
          user: {
            first_name: user.first_name,
            id: user.user_id,
            last_name: user.last_name,
          },
        },
      ]
    : [];
  const [memberships, setMemberships] = useState(initialMembershipSelected);

  useEffect(() => {
    if (project?.attachments?.length > 0) {
      setAttachments(project.attachments);
    }
  }, [project.attachments, isEdit]);

  useEffect(() => {
    if (project?.memberships?.length > 0) {
      setMemberships(project.memberships);
    }
  }, [project.memberships, isEdit]);

  useEffect(() => {
    if (project?.milestones?.length > 0) {
      setMilestones(project.milestones ? project.milestones : []);
    }
  }, [project.milestones, isEdit]);

  useEffect(() => {
    if (project?.indicators?.length > 0) {
      setIndicators(project.indicators);
    }
  }, [project.indicators, isEdit]);

  useEffect(() => {
    if (project?.tracking_notes?.length > 0) {
      setTrackingNotes(project.tracking_notes);
    }
  }, [project.tracking_notes, isEdit]);

  useEffect(() => {
    if (project?.report_attachments?.length > 0) {
      setReportAttachments(project.report_attachments);
    }
  }, [project.report_attachments, isEdit]);

  return {
    memberships,
    milestones,
    indicators,
    trackingNotes,
    attachments,
    reportAttachments,
    setMemberships,
    setMilestones,
    setIndicators,
    setTrackingNotes,
    setAttachments,
    setReportAttachments,
    urlAttachmentsError,
    setUrlAttachmentsError,
    urlInformError,
    setUrlInformError,
  };
};
