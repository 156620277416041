import React from 'react'

const OrderByIcon = () => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 7.96973L4 11L1 7.96973" stroke="#687B8C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 4.03027L4 0.99997L7 4.03027" stroke="#687B8C" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {OrderByIcon}