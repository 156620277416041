import { useMutation } from "@tanstack/react-query";
import { Button, Error, Input, RegularText } from "UI/atoms/index";
import PublicMain from "UI/organisms/PublicMain";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Eye } from "shared/assets/svgs/LoginIcons";
import { recoverPassword } from "utils/api-client";
import * as Yup from "yup";

const values = {
  password: "",
  repeat_password: "",
};

const schema = Yup.object()
  .shape({
    password: Yup.string()
      .required("Nueva contraseña es una campo requerido")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
      ),
    repeat_password: Yup.string()
      .required("Confirmar nueva contraseña es  requerido")
      .oneOf([Yup.ref("password")], "Las contraseñas deben coincidir"),
  })
  .required();

function PasswordRecover({ token }) {
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(3);
  const [hidePassword, setHidePassword] = useState({
    password: true,
    repeat_password: true,
  });

  const { mutate, isError, isSuccess } = useMutation(recoverPassword);

  useEffect(() => {
    if (isError) {
      setError("Prueba una contraseña diferente");
    }
    if (isSuccess && !isError) {
      let intervalId = null;

      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(intervalId);
        localStorage.removeItem("__auth_provider_token__");
        localStorage.removeItem("__auth_provider_refresh__");
        window.location.href = "/login";
      }, 3000);
    } else if (isSuccess) {
      let intervalId = null;

      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(intervalId);
        window.location.href = "/login";
      }, 3000);
    }
  }, [isSuccess, isError]);

  const onSubmit = (values) => {
    const payload = {
      password: values.password,
      token,
    };
    mutate(payload);
  };

  const handleHidePassword = (fieldName) => {
    setHidePassword({
      ...hidePassword,
      [fieldName]: !hidePassword[fieldName],
    });
  };

  return (
    <PublicMain>
      {" "}
      <Formik
        initialValues={values}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="col">
            <div className="pass-recover login-card login-form">
              <h3>Recupera tu contraseña</h3>

              <div className="login-row">
                <label htmlFor="password">Nueva contraseña</label>
                <Input
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  type={hidePassword.password ? "password" : "text"}
                  name="password"
                  id="password"
                  className="password"
                  onChange={(password) => {
                    formik.setFieldValue("password", password.target.value);
                  }}
                  value={formik.values.password || ""}
                />
                <span
                  className="tooglepass"
                  onClick={() => handleHidePassword("password")}
                >
                  <Eye />
                </span>
                {formik.errors.password && formik.touched.password ? (
                  <Error>{formik.errors.password}</Error>
                ) : null}
              </div>
              <div className="login-row">
                <label htmlFor="repeat_password">
                  Confirmar nueva contraseña
                </label>
                <Input
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  type={hidePassword.repeat_password ? "password" : "text"}
                  name="repeat_password"
                  id="repeat_password"
                  className="password"
                  onChange={(repeatPassword) => {
                    formik.setFieldValue(
                      "repeat_password",
                      repeatPassword.target.value
                    );
                  }}
                  value={formik.values.repeat_password || ""}
                />
                <span
                  className="tooglepass"
                  onClick={() => handleHidePassword("repeat_password")}
                >
                  <Eye />
                </span>
                {formik.errors.repeat_password &&
                formik.touched.repeat_password ? (
                  <Error>{formik.errors.repeat_password}</Error>
                ) : null}
              </div>
              <div style={{ margin: ".5rem 0" }}>
                {" "}
                {isSuccess && (
                  <RegularText style={{ color: "#2EC07F" }}>
                    La contraseña se cambió con éxito. Será redireccionado al
                    login en {timer}s.
                  </RegularText>
                )}
                {isError && <Error>{error}</Error>}
              </div>

              <Button
                type="submit"
                height="54px"
                width="100%"
                background="#21619F"
              >
                Guardar
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </PublicMain>
  );
}

export { PasswordRecover };
