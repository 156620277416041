import { useCommonLookups } from "queries/lookups";
import { useState } from "react";

const Roles = () => {
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [role, setRole] = useState();

  const { data: roles, isLoading: isRolesLoading } = useCommonLookups("roles");

  const handleDeleteLookup = (role) => {
    setIsDeleteModalOpen(true);
    setRole(role);
  };

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <ul className="ajustes">
          {roles?.map((role) => (
            <li key={role.id} style={{ padding: "1rem" }}>
              <p>{role.name}</p>
              <div className="actions"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Roles };
