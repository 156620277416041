import { Box } from "UI/atoms/Box";

export const ReadOnly = ({ formik }) => {
  return (
    <>
      {" "}
      <div className="field-row row" style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <label htmlFor="name" className="form-label">
            Nombre proyecto
          </label>
          <Box
            fontSizes="sm"
            margin="0px 0px 30px 15px"
            fontWeight={500}
            color="body"
            fontFamily="Barlow"
          >
            {formik.values.name}
          </Box>
        </div>
        <div style={{ width: "50%" }}>
          <label htmlFor="status" className="form-label">
            Etapa
          </label>
          <Box
            fontSizes="sm"
            margin="0px 0px 30px 15px"
            fontWeight={500}
            color="body"
            fontFamily="Barlow"
          >
            {formik.values.status.name}
          </Box>
        </div>
      </div>
      <div className="field-row">
        <ul className="field-colums" style={{ width: "100%" }}>
          <li style={{ width: "30%" }}>
            <label htmlFor="organization_name" className="form-label">
              Organización
            </label>
            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.organization_name}
            </Box>
          </li>
          <li style={{ width: "30%" }}>
            <label htmlFor="topic" className="form-label">
              Tipo de proyecto
            </label>
            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.topic.name}
            </Box>
          </li>
          <li style={{ width: "20%" }}>
            <label htmlFor="productive_sector" className="form-label">
              Sector productivo
            </label>

            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.productive_sector.name}
            </Box>
          </li>
          <li style={{ width: "20%" }}>
            <label htmlFor="location" className="form-label">
              Provincia
            </label>

            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.location.name}
            </Box>
          </li>
        </ul>
      </div>
      <div className="field-row">
        <ul className="field-colums" style={{ width: "100%" }}>
          <li style={{ width: "30%" }}>
            <label htmlFor="location_details" className="form-label">
              Localidad
            </label>
            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.location_details}
            </Box>
          </li>
          <li style={{ width: "30%" }}>
            <label htmlFor="substatus" className="form-label">
              Estado
            </label>
            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.substatus.name}
            </Box>
          </li>
          <li style={{ width: "20%" }}>
            <label htmlFor="start_date" className="form-label">
              Fecha de inicio
            </label>
            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.start_date}
            </Box>
          </li>
          <li style={{ width: "20%" }}>
            <label htmlFor="end_date" className="form-label">
              Fecha de finalización
            </label>
            <Box
              fontSizes="sm"
              margin="0px 0px 30px 15px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {formik.values.end_date}
            </Box>
          </li>
        </ul>
      </div>
      <div style={{ width: "100%" }}>
        <label htmlFor="description" className="form-label">
          Objetivo general
        </label>
        <Box
          fontSizes="sm"
          margin="0px 0px 30px 15px"
          fontWeight={500}
          color="body"
          fontFamily="Barlow"
        >
          {formik.values.description}
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <label htmlFor="specific_objectives" className="form-label">
          Objetivos específicos
        </label>
        <Box
          fontSizes="sm"
          margin="0px 0px 30px 15px"
          fontWeight={500}
          color="body"
          fontFamily="Barlow"
        >
          {formik.values.specific_objectives}
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <label htmlFor="justification" className="form-label">
          Justificación
        </label>
        <Box
          fontSizes="sm"
          margin="0px 0px 30px 15px"
          fontWeight={500}
          color="body"
          fontFamily="Barlow"
        >
          {formik.values.justification}
        </Box>
      </div>
    </>
  );
};
