import { Modal } from "UI/atoms";
import { ResponseText } from "UI/molecules/ResponseText";
import { Link } from "react-router-dom";

const ResponseModal = ({
  successResponse,
  successText,
  responseError,
  isOpenModal,
  setIsOpenModal,
}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggleModal={setIsOpenModal}
      width="610px"
      padding="25px 32px"
      className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
    >
      <div className="ficha-topbar justify-center center">
        {successResponse && (
          <ResponseText successText={successText} route={"projects"} />
        )}
        {!successResponse && responseError && (
          <div className="barlow-bold" style={{ textAlign: "left" }}>
            <p>Algo salió mal. (Error {responseError})</p>
            <br />
            <Link
              to={"/projects"}
              style={{ fontSize: "12px", color: "#0379F2" }}
            >
              Volver a proyectos
            </Link>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ResponseModal;
