import styled from "styled-components";

const RegularText = ({
  children,
  margin = "0",
  color,
  size,
  style,
  className,
}) => {
  return (
    <StyledText
      margin={margin}
      size={size}
      color={color}
      style={style}
      className={className}
    >
      {children}
    </StyledText>
  );
};

const StyledText = styled.span`
  font-size: ${(props) => (props.size ? props.size : props.theme.fontSizes.sm)};
  color: ${(props) => (props.color ? props.color : props.theme.colors.dark)};
  margin: ${(props) => props.margin};
`;

export { RegularText };
