import React from 'react'

const CloseModalIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#687B8C" strokeMiterlimit="10"/>
        <path d="M20 12L12 20" stroke="#687B8C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 20L12 12" stroke="#687B8C" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {CloseModalIcon}