import styled from "styled-components";

const Search = ({
  name,
  value,
  defaultValue,
  type,
  hasBorder = false,
  padding,
  width,
  onChange,
  placeholder,
  bg = "#FFFFFF",
  list,
  className,
  onKeyPress,
}) => {
  return (
    <>
      <StyledInput
        name={name}
        defaultValue={defaultValue}
        value={value}
        type={type}
        width={width}
        onChange={onChange}
        onKeyPress={onKeyPress}
        bg={bg}
        hasBorder={hasBorder}
        placeholder={placeholder}
        padding={padding}
        className={className}
        list={"list"}
      ></StyledInput>

      <datalist id={"list"}>
        {list &&
          list.map((item, index) => (
            <option key={index} value={item.name}></option>
          ))}
      </datalist>
    </>
  );
};

const StyledInput = styled.input`
  display: block;

  width: ${(props) => props.width};
  height: 44px;
  border: none;
  border-radius: 10px;
  border: 1px solid rgba(104, 123, 140, 0.5);
  padding: ${(props) => props.padding};

  background-color: ${(props) => props.bg};

  &:focus-visible {
    outline: none;
  }
`;

export { Search };
