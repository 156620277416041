import {
  AddEditProject,
  Ajustes,
  Escritorio,
  Proyectos,
  Reportes,
  Usuarios,
} from "pages/index";
import { useCommonLookups } from "queries/lookups";
import { Navigate, Route, Routes } from "react-router-dom";
import { Spinner } from "shared/assets/svgs";

const AdminRoutes = ({ projects }) => {
  const { data: statuses, isLoading: isStatusesLoading } =
    useCommonLookups("project-status");

  return (
    <>
      {isStatusesLoading ? (
        <Spinner />
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/desktop" />} />
          <Route
            exact
            path="/desktop"
            element={<Escritorio projects={projects} />}
          ></Route>
          <Route
            exact
            path="/projects"
            element={<Proyectos projects={projects} />}
          ></Route>
          <Route exact path="/new-project" element={<AddEditProject />}></Route>
          <Route
            exact
            path="/edit-project/:id"
            element={<AddEditProject />}
          ></Route>
          <Route
            exact
            path="/reports"
            element={<Reportes projects={projects} />}
          ></Route>
          <Route exact path="/users" element={<Usuarios />}></Route>
          <Route exact path="/settings" element={<Ajustes />}></Route>
        </Routes>
      )}
    </>
  );
};

const AdminApp = ({ projects }) => {
  return (
    <>
      <AdminRoutes projects={projects} />
    </>
  );
};

export { AdminApp };
