import { Button, Error, Input } from "UI/atoms";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";

import { Progress } from "UI/molecules";
import { useEffect, useState } from "react";
import { UrlIcon } from "shared/assets/svgs/Url";
import ReadOnly from "./ReadOnly";

const Budget = ({ formik, project, isEdit, isEditMode, isCreateMode }) => {
  const [budget, setBudget] = useState();
  const [executed, setExecuted] = useState();
  const budgetAvailable = budget - executed;

  useEffect(() => {
    if (isEdit) {
      setBudget(project.budgeted_money);
      setExecuted(project.executed_money);
    }
  }, []);

  return (
    <div className="tablayer tab-parametros">
      <ToastContainer/>
      {isEditMode || isCreateMode ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div style={{ width: "437px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "13px",
                }}
              >
                <p>
                  <small style={{ color: "#6F83A0" }}>Presupuestado</small>
                </p>
                <p>
                  <small style={{ color: "#6F83A0" }}>Ejecutado</small>
                </p>
                <p>
                  <small style={{ color: "#6F83A0" }}>Disponible</small>
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  background: "#F0F4F7",
                  borderRadius: "14px",
                  padding: "7px",
                }}
              >
                <Input
                  id="budgeted_money"
                  name="budgeted_money"
                  type="number"
                  width={"117px"}
                  height="46px"
                  className="form-control"
                  onChange={(budgeted_money) => {
                    setBudget(budgeted_money.target.value);
                    formik.setFieldValue(
                      "budgeted_money",
                      budgeted_money.target.value
                    );
                  }}
                  value={
                    formik.values.budgeted_money
                      ? formik.values.budgeted_money
                      : project?.budgeted_money || ""
                  }
                />
                <Input
                  id="executed_money"
                  name="executed_money"
                  type="number"
                  width={"117px"}
                  height="46px"
                  className="form-control"
                  onChange={(executed_money) => {
                    setExecuted(executed_money.target.value);
                    formik.setFieldValue(
                      "executed_money",
                      executed_money.target.value
                    );
                  }}
                  value={
                    formik.values.executed_money
                      ? formik.values.executed_money
                      : project?.executed_money || ""
                  }
                />
                <div
                  style={{
                    width: "117px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 style={{ color: "#21619F" }}>
                    {budgetAvailable ? `$${budgetAvailable}` : "$0"}
                  </h4>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "240px",
                margin: "30px 0px 0px 34px",
              }}
            >
              <Progress
                count={executed}
                total={budget}
                color="#21619F"
                bgColor="#F0F4F7"
                labelText="Ejecutado"
                labelNumber=""
              />
            </div>
          </div>
          <div style={{ marginTop: "36px" }}>
            <h6>Planilla de gastos</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0 20px 0",
              }}
            >
              <Input
                id="linkDrive"
                name="linkDrive"
                type="text"
                width={"700px"}
                height="44px"
                className="form-control"
                placeholder="Link del archivo"
                onChange={(expense_sheet_file_url) => {
                  formik.setFieldValue(
                    "expense_sheet_file_url",
                    expense_sheet_file_url.target.value
                  );
                }}
                value={formik.values.expense_sheet_file_url ?? ""}
              />

              <Button
                margin={"0px 10px 0px 12px"}
                height="30px"
                width="30px"
                className="circle-btn"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(project.expense_sheet_file_url).then(() => {
                    toast.success("Texto copiado al portapapeles!");
                  }).catch((error) => {
                    toast.error("Error copiando el texto al portapapeles. (Nos diste permiso en tu navegador?)");
                  });
                }}
              >
                <UrlIcon />
              </Button>
            </div>
            {formik.errors.expense_sheet_file_url ? (
              <Error>{formik.errors.expense_sheet_file_url}</Error>
            ) : null}
          </div>
        </>
      ) : (
        <ReadOnly
          project={project}
          budgetAvailable={budgetAvailable}
          executed={executed}
          budget={budget}
        />
      )}
    </div>
  );
};

export { Budget };
