const Lock = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.835 19.9862H13.7213C9.83626 19.9862 7.96376 18.455 7.64001 15.025C7.60501 14.6662 7.86751 14.3425 8.23501 14.3075C8.58501 14.2725 8.91751 14.5437 8.95251 14.9025C9.20626 17.65 10.5013 18.6737 13.73 18.6737H13.8438C17.405 18.6737 18.665 17.4137 18.665 13.8525V8.14748C18.665 4.58623 17.405 3.32623 13.8438 3.32623H13.73C10.4838 3.32623 9.18876 4.36748 8.95251 7.16748C8.90876 7.52623 8.60251 7.79748 8.23501 7.76248C7.86751 7.73623 7.60501 7.41248 7.63126 7.05373C7.92876 3.57123 9.81001 2.01373 13.7213 2.01373H13.835C18.1313 2.01373 19.9688 3.85123 19.9688 8.14748V13.8525C19.9688 18.1487 18.1313 19.9862 13.835 19.9862Z"
        fill="#21619F"
      />
      <path
        d="M13.625 11.6562H3.66748C3.30873 11.6562 3.01123 11.3587 3.01123 11C3.01123 10.6413 3.30873 10.3438 3.66748 10.3438H13.625C13.9837 10.3438 14.2812 10.6413 14.2812 11C14.2812 11.3587 13.9837 11.6562 13.625 11.6562Z"
        fill="#21619F"
      />
      <path
        d="M5.61876 14.5875C5.45251 14.5875 5.28626 14.5262 5.15501 14.395L2.22376 11.4637C1.97001 11.21 1.97001 10.79 2.22376 10.5362L5.15501 7.60499C5.40876 7.35124 5.82876 7.35124 6.08251 7.60499C6.33626 7.85874 6.33626 8.27874 6.08251 8.53249L3.61501 11L6.08251 13.4675C6.33626 13.7212 6.33626 14.1412 6.08251 14.395C5.96001 14.5262 5.78501 14.5875 5.61876 14.5875Z"
        fill="#21619F"
      />
    </svg>
  );
};

export { Lock };
