import styled from "styled-components";

const Select = ({
  name,
  id,
  defaultValue,
  onChange,
  children,
  labelText,
  bg = "#FFFFFF",
  padding,
  border,
  width,
  margin,
  className,
  labelClassName,
  labelHtmlFor,
  disabled,
  value,
}) => {
  return (
    <div className={className}>
      <label className={labelClassName} htmlFor={labelHtmlFor}>
        {labelText}
      </label>
      <StyledSelect
        disabled={disabled}
        name={name}
        id={id}
        padding={padding}
        defaultValue={defaultValue}
        onChange={onChange}
        bg={bg}
        border={border}
        width={width}
        margin={margin}
        value={value}
        className="select"
      >
        {children}
      </StyledSelect>
    </div>
  );
};

const StyledSelect = styled.select`
  display: block;
  margin-right: 0px;

  width: ${(props) => props.width || "100%"};
  height: 46px;
  padding: ${(props) => props.padding || "0 18px"};
  margin: ${(props) => props.margin};

  // background-color: ${(props) => props.bg};

  border: 1px solid rgb(111, 131, 160, 0.5);
  border-radius: 10px;

  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  color: #545454;
  cursor: pointer;
`;

export { Select };
