const validateUrls = (
  setUrlAttachmentsError,
  setUrlInformError,
  reportAttachments,
  attachments,
  isDisabled
) => {
  const validateUrl = (url) => {
    const urlRegex =
      /^(http[s]?:\/\/|(www\.)|(http[s]?:\/\/www\.))[a-zA-Z0-9]+\.[^\s]{2,}/;
    return urlRegex.test(url);
  };

  const validateUrls = (attachments) => {
    return attachments.some((att) => !validateUrl(att.url));
  };

  const hasAttachmentsError = validateUrls(attachments);
  setUrlAttachmentsError(hasAttachmentsError);

  const hasReportAttachmentsError = validateUrls(reportAttachments);
  setUrlInformError(hasReportAttachmentsError);

  if (hasAttachmentsError || hasReportAttachmentsError || isDisabled) {
    return false;
  }
  return true;
};

export { validateUrls };
