import { Button, Error } from "UI/atoms";
import { Plus } from "shared/assets/svgs";

import { AttachmentInput } from "UI/molecules/AttachmentInput";
import { AttachmentReadOnly } from "UI/molecules/AttachmentReadOnly";
import { generateId } from "utils/getDate";

const Attachments = ({
  attachments,
  setAttachments,
  isEditMode,
  isCreateMode,
  formik,
  urlAttachmentsError,
}) => {
  const addAttachment = () => {
    setAttachments([...attachments, { name: "", url: "", key: generateId() }]);
  };

  const deletedAttachment = (id) => {
    const filtered = attachments.filter((attachment) => {
      if (attachment.key) {
        return attachment.key !== id;
      } else {
        return attachment.id !== id;
      }
    });
    setAttachments(filtered);
  };

  return (
    <div className="tablayer tab-adjuntos">
      {isEditMode || isCreateMode ? (
        <>
          <ul>
            <Button
              type={"button"}
              height="24px"
              color="#21619F"
              background="transparent"
              className="btn-with-icon addfile"
              onClick={() => addAttachment()}
            >
              <span className="blue-circle">
                <Plus />
              </span>{" "}
              <span>AGREGAR ADJUNTO</span>
            </Button>
            {urlAttachmentsError ? (
              <div style={{ margin: "20px 0" }}>
                <Error>Una de las URL ingresadas no es válida</Error>
              </div>
            ) : null}
            <div className="adjuntos" style={{ maxWidth: "100%" }}>
              {attachments.map((attachment) => (
                <AttachmentInput
                  key={attachment.key ?? attachment.id}
                  setAttachments={setAttachments}
                  attachment={attachment}
                  attachments={attachments}
                  placeholder="Nombre del archivo"
                  onClick={() =>
                    deletedAttachment(
                      attachment.key ? attachment.key : attachment.id
                    )
                  }
                />
              ))}
            </div>
          </ul>
        </>
      ) : (
        <>
          <ul>
            <div className="adjuntos" style={{ maxWidth: "100%" }}>
              {attachments.map((attachment) => (
                <AttachmentReadOnly
                  key={attachment.key ?? attachment.id}
                  attachment={attachment}
                  attachments={attachments}
                />
              ))}
            </div>
          </ul>
        </>
      )}
    </div>
  );
};

export { Attachments };
