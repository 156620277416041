import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
  height: 5px;
`;

const Container = styled.ul`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 15px;
  }
`;

const EmptyBar = styled.div`
  width: 100%;
  height: 1vh;
  background: ${(props) =>
    props.color ? props.color : props.theme.colors.violet};

  border-radius: 30px;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.width};
  height: 5px;
  position: absolute;
  top: 0;
  background: ${(props) =>
    props.color ? props.color : props.theme.colors.white};

  border-radius: 40px;
`;

export { Wrapper, EmptyBar, ProgressBar, Container };
