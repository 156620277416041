import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorFallback } from "UI/organisms/ErrorFallback";
import { AuthenticatedApp } from "authenticated-app";
import { Menubar } from "pages/index";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import { UnauthenticatedApp } from "unauthenticated-app";
import GlobalStyle from "utils/globalStyles";
import { theme } from "utils/theme";

import jwt_decode from "jwt-decode";

const errors = [401, 403, 404, 500];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
    },
  },
});

function App() {
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("__auth_provider_token__") ? true : false
  );

  const role = isLogin
    ? jwt_decode(localStorage.getItem("__auth_provider_token__") || "")?.role ||
      "Comunidad"
    : "Comunidad";

  return (
    <div className="dashboard">
      <ModalProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {isLogin ? (
              <nav>
                <Menubar setIsLogin={setIsLogin} role={role} />
              </nav>
            ) : null}
            <main>
              {!isLogin ? (
                <UnauthenticatedApp setIsLogin={setIsLogin} />
              ) : (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <AuthenticatedApp role={role} setIsLogin={setIsLogin} />
                </ErrorBoundary>
              )}
            </main>
          </ThemeProvider>
        </QueryClientProvider>
      </ModalProvider>
    </div>
  );
}

export default App;
