import { Button, Error } from "UI/atoms";
import { Plus } from "shared/assets/svgs";

import "react-datepicker/dist/react-datepicker.css";

import { AttachmentInput } from "UI/molecules/AttachmentInput";
import { AttachmentReadOnly } from "UI/molecules/AttachmentReadOnly";
import { generateId } from "utils/getDate";

const Inform = ({
  reportAttachments,
  setReportAttachments,
  isEditMode,
  isCreateMode,
  urlInformError,
}) => {
  const addAttachment = () => {
    setReportAttachments([
      ...reportAttachments,
      { name: "", url: "", key: generateId() },
    ]);
  };

  const deletedAttachment = (id) => {
    const filtered = reportAttachments.filter((attachment) => {
      if (attachment.key) {
        return attachment.key !== id;
      } else {
        return attachment.id !== id;
      }
    });
    setReportAttachments(filtered);
  };

  return (
    <div className="tablayer tab-parametros">
      {isEditMode || isCreateMode ? (
        <>
          {" "}
          <Button
            type={"button"}
            height="24px"
            color="#21619F"
            background="transparent"
            className="btn-with-icon addfile"
            onClick={() => addAttachment()}
          >
            <span className="blue-circle">
              <Plus />
            </span>{" "}
            <span>AGREGAR ADJUNTO</span>
          </Button>
          {urlInformError ? (
            <div style={{ marginTop: "33px" }}>
              <Error>Una de las URL ingresadas no es válida</Error>
            </div>
          ) : null}
          <div style={{ margin: "1.25rem 0" }} className="adjuntos">
            {reportAttachments.map((reportAttachment) => (
              <AttachmentInput
                key={reportAttachment.key ?? reportAttachment.id}
                setAttachments={setReportAttachments}
                attachment={reportAttachment}
                attachments={reportAttachments}
                onClick={() =>
                  deletedAttachment(
                    reportAttachment.key
                      ? reportAttachment.key
                      : reportAttachment.id
                  )
                }
              />
            ))}
          </div>
        </>
      ) : (
        <div style={{ margin: "2rem 0" }} className="adjuntos">
          {reportAttachments.map((reportAttachment) => (
            <AttachmentReadOnly
              key={reportAttachment.key ?? reportAttachment.id}
              attachment={reportAttachment}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { Inform };
