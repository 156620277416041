const UsersIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.605 11.8388C10.5875 11.8388 10.5612 11.8388 10.5437 11.8388C10.5175 11.8388 10.4825 11.8388 10.4562 11.8388C8.46998 11.7775 6.98248 10.2288 6.98248 8.32126C6.98248 6.37876 8.56623 4.79501 10.5087 4.79501C12.4512 4.79501 14.035 6.37876 14.035 8.32126C14.0262 10.2375 12.53 11.7775 10.6312 11.8388C10.6137 11.8388 10.6137 11.8388 10.605 11.8388ZM10.5 6.09876C9.27498 6.09876 8.28623 7.09626 8.28623 8.31251C8.28623 9.51126 9.22248 10.4825 10.4125 10.5263C10.4387 10.5175 10.5262 10.5175 10.6137 10.5263C11.7862 10.465 12.705 9.50251 12.7137 8.31251C12.7137 7.09626 11.725 6.09876 10.5 6.09876Z"
        fill="#545454"
      />
      <path
        d="M10.5 19.9062C8.14623 19.9062 5.89748 19.0312 4.15623 17.4387C3.99873 17.2987 3.92873 17.0887 3.94623 16.8875C4.05998 15.8462 4.70748 14.875 5.78373 14.1575C8.39123 12.425 12.6175 12.425 15.2162 14.1575C16.2925 14.8837 16.94 15.8462 17.0537 16.8875C17.08 17.0975 17.0012 17.2987 16.8437 17.4387C15.1025 19.0312 12.8537 19.9062 10.5 19.9062ZM5.31998 16.7125C6.77248 17.9287 8.60123 18.5937 10.5 18.5937C12.3987 18.5937 14.2275 17.9287 15.68 16.7125C15.5225 16.1787 15.1025 15.6625 14.4812 15.2425C12.3287 13.8075 8.67998 13.8075 6.50998 15.2425C5.88873 15.6625 5.47748 16.1787 5.31998 16.7125Z"
        fill="#545454"
      />
      <path
        d="M10.5 19.9062C5.31125 19.9062 1.09375 15.6888 1.09375 10.5C1.09375 5.31125 5.31125 1.09375 10.5 1.09375C15.6888 1.09375 19.9062 5.31125 19.9062 10.5C19.9062 15.6888 15.6888 19.9062 10.5 19.9062ZM10.5 2.40625C6.0375 2.40625 2.40625 6.0375 2.40625 10.5C2.40625 14.9625 6.0375 18.5938 10.5 18.5938C14.9625 18.5938 18.5938 14.9625 18.5938 10.5C18.5938 6.0375 14.9625 2.40625 10.5 2.40625Z"
        fill="#545454"
      />
    </svg>
  );
};

export { UsersIcon };
