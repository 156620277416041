import { Badge, Button, Modal } from "UI/atoms";
import { Pencil, Plus } from "shared/assets/svgs";

import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { IndicatorsModal } from "./ui/IndicatorsModal";
import { ReadOnly } from "./ui/ReadOnly";

const Indicators = ({
  indicators,
  setIndicators,
  isEditMode,
  isCreateMode,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState();

  const openDetail = (indicator) => {
    setSelectedIndicator(indicator);
    setIsOpenModal(true);
  };

  return (
    <>
      {isEditMode || isCreateMode ? (
        <>
          <div className="tablayer tab-parametros">
            <Button
              height="24px"
              color="#21619F"
              background="transparent"
              className="btn-with-icon addfile"
              type={"button"}
              onClick={() => setIsOpenModal(true)}
            >
              <span className="blue-circle">
                <Plus />
              </span>{" "}
              <span>AGREGAR INDICADOR</span>
            </Button>
            <table className="dash-table dash-table-inverse">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha</th>
                  <th>Meta</th>
                  <th>Resultados del indicador</th>
                </tr>
              </thead>
              <tbody>
                {indicators ? (
                  indicators.map((indicator) => (
                    <tr
                      key={indicator.key ? indicator.key : indicator.id}
                      style={{ textAlign: "center" }}
                    >
                      <td>
                        <strong>{indicator.name}</strong>
                      </td>
                      <td>
                        <small className="ellipsis">
                          {indicator.description}
                        </small>
                      </td>
                      <td>
                        <small>{indicator.date}</small>
                      </td>
                      <td>
                        <small className="ellipsis">{indicator.goal}</small>
                      </td>
                      <td>
                        <small className="ellipsis">{indicator.scope}</small>
                      </td>
                      <td>
                        <Button
                          type="button"
                          height="30px"
                          width="30px"
                          className="circle-btn"
                          onClick={() => openDetail(indicator)}
                        >
                          <Pencil />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="barlow-medium center">Sin indicadores</div>
                )}
              </tbody>
            </table>
            <div className="pager">
              <Badge
                key={1}
                background="white"
                color="#0379F2"
                fontWeight="bold"
                onClick={() => "setPageNumber(lof)"}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                1
              </Badge>
            </div>
          </div>
          <Modal
            isOpen={isOpenModal}
            toggleModal={setIsOpenModal}
            width="610px"
            height={"90%"}
            padding="25px 32px"
            className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
          >
            <IndicatorsModal
              setIsOpenModal={setIsOpenModal}
              indicators={indicators}
              setIndicators={setIndicators}
              setSelectedIndicator={setSelectedIndicator}
              selectedIndicator={selectedIndicator}
            />
          </Modal>
        </>
      ) : (
        <ReadOnly indicators={indicators} />
      )}
    </>
  );
};

export { Indicators };
