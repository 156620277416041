import styled from "styled-components";

export const Card = ({ padding, maxWidth, bgColor, children }) => {
  return (
    <div>
      <CardContainer width={maxWidth} padding={padding} bgColor={bgColor}>
        {children}
      </CardContainer>
    </div>
  );
};

const CardContainer = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fefefe")};
  border-radius: 40px;
  padding: ${(props) => (props.padding ? props.padding : "44px 70px")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "429px")};
  margin-bottom: 38px;
  @media screen and (max-width: 1350px) {
    padding: 30px;
  }
`;
