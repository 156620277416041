import { Button } from "UI/atoms";
import { useCommonLookups } from "queries/lookups";
import { useState } from "react";
import { Pencil, Plus, Trash } from "shared/assets/svgs";
import { AddEditModal } from "../../organisms/AddEditModal";
import { DeleteModal } from "../../organisms/DeleteModal";

const Sectors = () => {
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [sector, setSector] = useState();

  const { data: productiveSectors, isLoading: isProductiveSectorsLoading } =
    useCommonLookups("project-productive-sectors");

  const handleDeleteLookup = (sector) => {
    setIsDeleteModalOpen(true);
    setSector(sector);
  };

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <Button
          height="24px"
          color="#2681FF"
          background="transparent"
          className="btn-with-icon addfile"
          onClick={() => setOpenAddOrEditModal("add")}
        >
          <span className="blue-circle">
            <Plus />
          </span>{" "}
          <span>Agregar Sector Productivo</span>
        </Button>
        <ul className="ajustes">
          {productiveSectors?.map((productiveSector) => (
            <li key={productiveSector.id}>
              <p>{productiveSector.name}</p>
              <div className="actions">
                <Button
                  width="30px"
                  height="30px"
                  background="#0379F2"
                  className="circle-btn white-icon"
                  onClick={() => (
                    setOpenAddOrEditModal("edit"), setSector(productiveSector)
                  )}
                >
                  <Pencil />
                </Button>
                <Button
                  width="30px"
                  height="30px"
                  background="#FF562F"
                  className="circle-btn"
                  onClick={() => handleDeleteLookup(productiveSector)}
                >
                  <Trash />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {openAddOrEditModal === "add" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "add"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="POST"
          keyName="project-productive-sectors"
          name="Sector Productivo"
        />
      ) : null}
      {openAddOrEditModal === "edit" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "edit"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="PUT"
          lookup={sector}
          keyName="project-productive-sectors"
          name="Sector Productivo"
        />
      ) : null}

      {isDeleteModalOpen ? (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsOpenDeleteModal={setIsDeleteModalOpen}
          lookup={sector}
          name="project-productive-sectors"
        />
      ) : null}
    </div>
  );
};

export { Sectors };
