const addProjectEditValues = (project) => {
  return {
    name: project.name ?? "",
    status: project.status ?? 0,
    organization_name: project.organization_name ?? "",
    topic: project.topic ?? 0,
    productive_sector: project.productive_sector ?? 0,
    location: project.location ?? 0,
    location_details: project.location_details ?? "",
    substatus: project.substatus ?? 0,
    start_date: project.start_date ?? "",
    end_date: project.end_date ?? "",
    description: project.description ?? "",
    specific_objectives: project.specific_objectives ?? "",
    justification: project.justification ?? "",
    budgeted_money: project.budgeted_money ?? "",
    executed_money: project.executed_money ?? "",
    expense_sheet_file_url: project.expense_sheet_file_url ?? "",
  };
};

const addProjectInitialValues = {
  name: "",
  status: 0,
  organization_name: "",
  topic: 0,
  productive_sector: 0,
  location: 0,
  location_details: "",
  substatus: 1,
  start_date: "",
  end_date: "",
  description: "",
  specific_objectives: "",
  justification: "",
  budgeted_money: "",
  executed_money: "",
  expense_sheet_file_url: "",
};

export { addProjectEditValues, addProjectInitialValues };
