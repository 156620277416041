const filterProjects = (
  projects = [],
  selects = {},
  yearsRange = [],
  searchTerm = ""
) => {
  const mappedProjects = projects.map((project) => {
    return {
      ...project,
      topic: {
        name: project.topic.name,
        id: project.topic.id,
      },
    };
  });

  return mappedProjects.filter((project) => {
    for (const key in selects) {
      const normalizedProjectStr = (obj, key) => {
        return obj[key]["name"].toLowerCase();
      };
      if (project[key] === undefined) {
        return false;
      } else {
        if (
          project[key] === undefined ||
          normalizedProjectStr(project, key) !==
            normalizedProjectStr(selects, key)
        ) {
          return false;
        }
      }
    }

    // Filter by search term
    if (searchTerm && searchTerm !== "") {
      const searchTermLower = searchTerm.toLowerCase();
      const projectValues = Object.values(project).map((value) => {
        return typeof value === "string" ? value.toLowerCase() : "";
      });
      if (!projectValues.some((value) => value.includes(searchTermLower))) {
        return false;
      }
    }

    // Filter by year range
    if (yearsRange && yearsRange.length > 0) {
      const startDateYear = new Date(project.start_date).getFullYear();
      if (
        startDateYear < yearsRange[0] ||
        startDateYear > yearsRange[yearsRange.length - 1]
      ) {
        return false;
      }
    }

    return true;
  });
};

export { filterProjects };
