import { Button, Search, Select } from "UI/atoms";
import { Trash } from "shared/assets/svgs";
import { CaretRightIcon } from "shared/assets/svgs/CaretRight";

import "react-datepicker/dist/react-datepicker.css";

import { BoxList } from "UI/atoms/BoxList";
import { useEffect, useState } from "react";
import { ReadOnly } from "./ReadOnly";

const Team = ({
  lookups,
  memberships,
  setMemberships,
  isEditMode,
  isCreateMode,
}) => {
  const activeUsers = lookups.users.filter((user) => !!user.is_active);
  const listUsers = activeUsers.map((user) => {
    return {
      role: {
        id: 1,
      },
      user: {
        first_name: user.first_name,
        id: user.id,
        last_name: user.last_name,
      },
    };
  });
  
  const [searchBar, setSearchBar] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    memberships !== null ? memberships : []
  );

  const allUsers = listUsers.filter(
    (filtered) =>
      !memberships?.some((member) => filtered.user.id === member.user.id)
  );

  useEffect(() => {
    // check if any user is already selected
    if (memberships !== null && memberships.length) {
      setFilteredUsers(allUsers);
    }
  }, []);

  useEffect(() => {
    if (searchBar !== "") {
      const results = filteredUsers.filter((filtered) => {
        const userName = filtered.user.first_name.toLowerCase();
        const filter = searchBar.toLowerCase();
        return userName.startsWith(filter);
      });
      setFilteredUsers(results);
    } else {
      setFilteredUsers(allUsers);
    }
  }, [searchBar])


  const selectUser = (selected) => {
    setSelectedUsers([...selectedUsers, selected]);

    // initial role id, correct format
    selected = {
      role: {
        id: 1,
      },
      user: {
        first_name: selected.user.first_name,
        id: selected.user.id,
        last_name: selected.user.last_name,
      },
    };

    setMemberships([...selectedUsers, selected]);

    // discard selected users in filteredUsers
    const filtered = filteredUsers.filter(
      (filtered) => filtered.user.id !== selected.user.id
    );
    setFilteredUsers(filtered);
  };

  const deleteUser = (userDeleted) => {
    const filtered = selectedUsers.filter(
      (userSelected) => userSelected.user.id !== userDeleted.user.id
    );
    const filteredMemberships = memberships.filter((membership) => {
      const user = userDeleted.user.id ? userDeleted.user.id : userDeleted.user;
      const memebershipUser = membership.user.id
        ? membership.user.id
        : membership.user;
      return user !== memebershipUser;
    });

    setSelectedUsers(filtered);
    setMemberships(filteredMemberships);
    setFilteredUsers([...filteredUsers, userDeleted]);
    
  };

  return (
    <div className="tablayer tab-parametros">
      {isEditMode || isCreateMode ? (
        <>
          <div className="search-area list-row">
            <Search
              placeholder="Buscar usuario/a por nombre o comunidad..."
              isSearch={true}
              id="search"
              name="search"
              className="form-search"
              width="348px"
              type={"search"}
              list={filteredUsers}
              onChange={(e) => setSearchBar(e.target.value)}
            />
            <p style={{ margin: "28px 0px 0px 120px" }}>Equipo asignado</p>
          </div>
          <div className="list-row">
            <div className="list-columns" style={{ marginRight: "60px" }}>
              <ul>
                <div>
                  {filteredUsers.map((filtered) => (
                    <BoxList
                      key={filtered.user.id}
                      background={" #F0F4F7"}
                      borderRadius={"10px"}
                      width={"22rem"}
                      height={"60px"}
                      margin={"0px 0px 10px 0px"}
                    >
                      <span
                        className="barlow-medium body"
                        style={{ width: "30%" }}
                      >
                        {filtered.user.first_name} {filtered.user.last_name}
                      </span>

                      {activeUsers?.map((user) => {
                        if (user.id === filtered.user.id) {
                          return (
                            <span key={user.id} className="barlow-medium body">
                              {user.role}
                            </span>
                          );
                        }
                      })}
                      <Button
                        type={"button"}
                        height="30px"
                        width="30px"
                        className="circle-btn"
                        onClick={() => selectUser(filtered)}
                      >
                        <CaretRightIcon />
                      </Button>
                    </BoxList>
                  ))}
                </div>
              </ul>
            </div>
            <div className="list-columns">
              <ul>
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  {selectedUsers.length ? (
                    selectedUsers.map((selected, index) => (
                      <BoxList
                        key={selected.user.id}
                        background={" #F0F4F7"}
                        borderRadius={"15px"}
                        width={"24rem"}
                        height={"60px"}
                        margin={"0px 0px 10px 0px"}
                      >
                        <span
                          className="barlow-medium body"
                          style={{ width: "30%" }}
                        >
                          {selected.user.first_name} {selected.user.last_name}
                        </span>
                        <Select
                          width={"170px"}
                          defaultValue={selected?.role?.id || 1}
                          onChange={(membership) => {
                            if (memberships !== null) {
                              memberships[index].role.id = parseInt(
                                membership.target.value
                              );
                            }
                          }}
                        >
                          {lookups.membershipRoles.map((membershipRole) => (
                            <option
                              key={membershipRole.id}
                              value={membershipRole.id}
                            >
                              {membershipRole.name}
                            </option>
                          ))}
                        </Select>
                        <Button
                          type={"button"}
                          height="30px"
                          width="30px"
                          className="circle-btn"
                          background="#FF562F"
                          onClick={() => deleteUser(selected)}
                        >
                          <Trash />
                        </Button>
                      </BoxList>
                    ))
                  ) : (
                    <div className="barlow-medium body center">
                      No se ha asignado
                    </div>
                  )}
                </div>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <ReadOnly
          filteredUsers={filteredUsers}
          selectedUsers={selectedUsers}
          activeUsers={activeUsers}
        />
      )}
    </div>
  );
};

export { Team };
