import { AddEditProject, Escritorio, Proyectos, Usuarios } from "pages/index";
import { Navigate, Route, Routes } from "react-router-dom";

const CoordinatorRoutes = ({ projects }) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/desktop" />} />
      <Route
        exact
        path="/desktop"
        element={<Escritorio projects={projects} />}
      ></Route>
      <Route
        exact
        path="/projects"
        element={<Proyectos projects={projects} />}
      ></Route>
      <Route exact path="/new-project" element={<AddEditProject />}></Route>
      <Route
        exact
        path="/edit-project/:id"
        element={<AddEditProject />}
      ></Route>
      <Route exact path="/project/:id" element={<AddEditProject />}></Route>
      <Route exact path="/users" element={<Usuarios />}></Route>
    </Routes>
  );
};

const CoordinatorApp = ({ projects }) => {
  return (
    <>
      <CoordinatorRoutes projects={projects} />
    </>
  );
};

export { CoordinatorApp };
