import { Button, Input } from "UI/atoms";
import { Header } from "UI/molecules";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { useGetProjects } from "queries/projects";
import { Spinner } from "shared/assets/svgs";

import { Error, Modal } from "UI/atoms";
import { Formik } from "formik";
import jwt_decode from "jwt-decode";
import { AppFlowByRole } from "pages/AppFlowByRole";
import { useLogin } from "queries/login";
import { useChangePasswordInFirstLogin } from "queries/users";

const AuthenticatedApp = ({ role, setIsLogin }) => {
  const [error, setError] = useState();
  const [isOpenModal, setIsOpenModal] = useState(
    () =>
      jwt_decode(localStorage.getItem("__auth_provider_token__") || "")
        ?.must_change_pass
  );

  const email = jwt_decode(
    localStorage.getItem("__auth_provider_token__")
  )?.email;

  const { data: projects, isLoading: isProjectsLoading } = useGetProjects();
  const { mutate: refresh } = useLogin();

  const {
    mutate: changePassword,
    isLoading: isChangePasswordLoading,
    isSuccess,
    isError,
  } = useChangePasswordInFirstLogin(email);

  const editPasswordSchema = Yup.object()
    .shape({
      old_password: Yup.string().required("Contraseña es un campo requerido"),
      password: Yup.string()
        .required("Nueva contraseña es un campo requerido")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
        ),
      repeat_password: Yup.string()
        .required("Confirmar nueva contraseña es requerido")
        .oneOf([Yup.ref("password")], "Las contraseñas deben coincidir"),
    })
    .required();

  const initialValues = {
    old_password: "",
    password: "",
    repeat_password: "",
  };

  const onSubmit = (values) => {
    changePassword(values, {
      onSuccess: () => {
        refresh({
          email,
          password: values.password,
        });
      },
      onError: (err) => setError(err.response.data.old_password.old_password),
    });

    if (isError) {
      return;
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsOpenModal(false);
    }
  }, [isSuccess]);

  const user = jwt_decode(localStorage.getItem("__auth_provider_token__"));

  return (
    <>
      {isProjectsLoading ? (
        <Spinner />
      ) : (
        <>
          {isOpenModal ? (
            <Modal
              isOpen={isOpenModal}
              toggleModal={setIsOpenModal}
              isActionDisabled
              width="520px"
              height={"90%"}
              padding="25px 39px"
              className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
            >
              <div>
                {" "}
                <div className="ficha-topbar">
                  <h2>Acción requerida: cambie su contraseña para continuar</h2>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={editPasswordSchema}
                  onSubmit={(values) => onSubmit(values)}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit} className="ficha-head">
                      <div className="field-row">
                        <label htmlFor="nombre" className="form-label">
                          Contraseña
                        </label>
                        <Input
                          type="text"
                          name="old_password"
                          id="old_password"
                          height="46px"
                          className="form-control"
                          onChange={(oldPassword) => {
                            formik.setFieldValue(
                              "old_password",
                              oldPassword.target.value
                            );
                          }}
                          value={formik.values.old_password || ""}
                        />
                        {formik.errors.old_password &&
                        formik.touched.old_password ? (
                          <Error>{formik.errors.old_password}</Error>
                        ) : null}
                      </div>
                      <div className="field-row">
                        <label htmlFor="nombre" className="form-label">
                          Nueva contraseña
                        </label>
                        <Input
                          type="text"
                          name="password"
                          id="password"
                          height="46px"
                          className="form-control"
                          onChange={(password) => {
                            formik.setFieldValue(
                              "password",
                              password.target.value
                            );
                          }}
                          value={formik.values.password || ""}
                        />
                        {formik.errors.password && formik.touched.password ? (
                          <Error>{formik.errors.password}</Error>
                        ) : null}
                      </div>
                      <div className="field-row">
                        <label htmlFor="nombre" className="form-label">
                          Confirmar nueva contraseña
                        </label>
                        <Input
                          type="text"
                          name="repeat_password"
                          id="repeat_password"
                          height="46px"
                          className="form-control"
                          onChange={(repeatPassword) => {
                            formik.setFieldValue(
                              "repeat_password",
                              repeatPassword.target.value
                            );
                          }}
                          value={formik.values.repeat_password || ""}
                        />
                        {formik.errors.repeat_password &&
                        formik.touched.repeat_password ? (
                          <Error>{formik.errors.repeat_password}</Error>
                        ) : null}
                      </div>
                      <div className="actions-row actions-row-left">
                        <Button
                          width="124px"
                          background="#F0F4F7"
                          color="#344854"
                          className="btn-with-icon"
                          onClick={() => {
                            setIsLogin(false);
                            localStorage.removeItem("__auth_provider_token__");
                            localStorage.removeItem(
                              "__auth_provider_refresh__"
                            );
                          }}
                        >
                          Cerrar sesión
                        </Button>
                        <Button
                          width="175px"
                          className="btn-with-icon white-icon"
                          type="submit"
                        >
                          {isChangePasswordLoading ? (
                            <Spinner />
                          ) : (
                            <span>Guardar nueva contraseña</span>
                          )}
                        </Button>
                      </div>
                      {isError && <Error>{error}</Error>}
                    </form>
                  )}
                </Formik>
              </div>
            </Modal>
          ) : (
            <>
              <Header
                user={user}
                search={"Buscar proyecto..."}
                buttonText={"NUEVO PROYECTO"}
                projects={projects}
              />
              <AppFlowByRole role={role} projects={projects} />
            </>
          )}
        </>
      )}
    </>
  );
};

export { AuthenticatedApp };
