import * as Yup from "yup";

const addFormSchemaValidator = Yup.object().shape({
  name: Yup.string().required("Nombre de proyecto es un campo requerido"),
  status: Yup.number()
    .positive("Etapa es un campo requerido")
    .required("Etapa es un campo requerido"),
  organization_name: Yup.string().required(
    "Organización es un campo requerido"
  ),
  topic: Yup.number()
    .positive("Tipo de proyecto es un campo requerido")
    .required("Tipo de proyecto es un campo requerido"),
  productive_sector: Yup.number()
    .positive("Sector productivo es un campo requerido")
    .required("Sector productivo es un campo requerido"),
  location: Yup.number()
    .positive("Provincia es un campo requerido")
    .required("Provincia es un campo requerido"),
  location_details: Yup.string().required("Localidad es un campo requerido"),
  start_date: Yup.date().required("Fecha de inicio es un campo requerido"),
  end_date: Yup.date().required("Fecha de finalización es un campo requerido"),
  expense_sheet_file_url: Yup.string().matches(
    /^(http[s]?:\/\/|(www\.)|(http[s]?:\/\/www\.))[a-zA-Z0-9]+\.[^\s]{2,}/,
    "La URL ingresada no es válida: debe empezar con https:// o www"
  ),
});

const editFormSchemaValidator = Yup.object().shape({
  expense_sheet_file_url: Yup.string().matches(
    /^(http[s]?:\/\/|(www\.)|(http[s]?:\/\/www\.))[a-zA-Z0-9]+\.[^\s]{2,}/,
    "La URL ingresada no es válida"
  ),
});

const milestoneFormSchemaValidator = Yup.object().shape({
  name: Yup.string().required("Título es un campo requerido"),
});

const indicatorsFormSchemaValidator = Yup.object().shape({
  name: Yup.string().required("Título es un campo requerido"),
});

export {
  addFormSchemaValidator,
  editFormSchemaValidator,
  indicatorsFormSchemaValidator,
  milestoneFormSchemaValidator,
};
