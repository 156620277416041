const getProjectsAmountPerCountry = (projects = []) => {
  const results = Object.values(
    projects.reduce((acc, project) => {
      acc[project.location.name] = acc[project.location.name]
        ? { ...project, count: 1 + acc[project.location.name].count }
        : { ...project, count: 1 };
      return acc;
    }, {})
  );

  const formattedResults = results.map((project) => {
    // Remover la coma antes de separar con guión
    const locationName = project.location.name.replace(",", "");

    // normalizar, reemplazar espacios con guión y convertir a minúsculas
    const normalizedStrs = locationName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[\s]/g, "-")
      .toLowerCase();

    return {
      className: `c-${normalizedStrs}`,
      count: project.count,
    };
  });

  return formattedResults;
};

export { getProjectsAmountPerCountry };
