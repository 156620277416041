const getTextColor = (hexColor) => {
  // Convertir el color hexadecimal a RGB
  const r = parseInt(hexColor?.slice(1, 3), 16);
  const g = parseInt(hexColor?.slice(3, 5), 16);
  const b = parseInt(hexColor?.slice(5, 7), 16);

  // Calcular el valor de luminosidad según la fórmula de W3C
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Devolver "black" o "white" según el valor de luminosidad
  return luminance > 0.5 ? "#3B3B3B" : "#FEFEFE";
};

export { getTextColor };
