import { BoxList } from "UI/atoms/BoxList";

export const ReadOnly = ({
  filteredUsers,
  selectedUsers,
  activeUsers,
  isRolesLoading,
}) => {
  return (
    <>
      <div className="search-area">
        <p className="barlow-medium" style={{ margin: "28px 0px" }}>
          Equipo asignado
        </p>
      </div>
      <div className="list-row">
        <div className="list-colums">
          <ul>
            <div
              style={{
                height: "100%",
              }}
            >
              {selectedUsers.length ? (
                selectedUsers.map((selected, index) => (
                  <BoxList
                    key={selected.user.id}
                    background={" #F0F4F7"}
                    borderRadius={"15px"}
                    width={"24rem"}
                    height={"60px"}
                    margin={"0px 0px 10px 0px"}
                  >
                    <span
                      className="barlow-medium body"
                      style={{ width: "30%" }}
                    >
                      {selected.user.first_name} {selected.user.last_name}
                    </span>
                    <span className="barlow-medium body">
                      {selected.role.name}
                    </span>
                  </BoxList>
                ))
              ) : (
                <div className="barlow-medium body center">
                  No se ha asignado
                </div>
              )}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};
