import { Badge, Select } from "UI/atoms";
import { SubtemaBar } from "UI/atoms/SubtemaBars/SubtemaBar.js";
import BudgetCard from "UI/molecules/BudgetCard/index.js";
import Card from "UI/molecules/Card/index.js";
import { DashArea } from "UI/organisms/DashArea/index.js";
import { useCommonLookups } from "queries/lookups";
import { useState } from "react";
import { Spinner } from "shared/assets/svgs";
import { TYPES } from "types/select.js";
import Circle from "../../UI/molecules/ProgressWheel/Circle.js";
import Scatter from "../../UI/molecules/Scatter";
import TreeMap from "../../UI/molecules/TreeMap";
import { filterProjects } from "../../utils/filterProjects";
import { getGroupedProjectsPerStatus } from "../../utils/getGroupedProjectsPerStatus";
import { getTopics } from "../../utils/getTopics.js";
import { getYearsRange } from "../../utils/getYearsRange";

function Reportes({ projects }) {
  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");
  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("topics");
  const { data: productiveSectors, isLoading: isProductiveSectorsLoading } =
    useCommonLookups("project-productive-sectors");
  const { data: substatuses, isLoading: isSubstatusesLoading } =
    useCommonLookups("project-substatus");
  const [selects, setSelects] = useState({});

  const yearsRange = getYearsRange(projects);
  const [selectedYears, setSelectedYears] = useState([
    yearsRange[0],
    yearsRange[yearsRange.length - 1],
  ]);

  const filteredProjects = filterProjects(projects, selects, selectedYears);

  const resetFilter = () => {
    var options = document.getElementsByClassName("select");
    for (var i = 0, l = options.length; i < l; i++) {
      options[i].value = options[i].options[0].value;
    }
    setSelects({});
  };

  const { resultsTopic: topicsPerFilteredProjects, tree } =
    getTopics(filteredProjects);

  const { groupedDataByStatus, groupedProductiveSectors } =
    getGroupedProjectsPerStatus(filteredProjects, selectedYears);

  const countProjects = filteredProjects.reduce(
    (acc, cur) => (cur ? ++acc : acc),
    0
  );
  const countPending = filteredProjects.reduce(
    (acc, cur) => (cur.status.name === "Propuesta" ? ++acc : acc),
    0
  );

  const countInProgress = filteredProjects.reduce(
    (acc, cur) => (cur.status.name === "En desarrollo" ? ++acc : acc),
    0
  );

  const countApproved = filteredProjects.reduce(
    (acc, cur) => (cur.status.name === "Aprobado" ? ++acc : acc),
    0
  );

  const countRejected = filteredProjects.reduce(
    (acc, cur) => (cur.status.name === "No aprobado" ? ++acc : acc),
    0
  );

  const countComplete = filteredProjects.reduce(
    (acc, cur) => (cur.status.name === "Finalizado" ? ++acc : acc),
    0
  );

  const countObservation = filteredProjects.reduce(
    (acc, cur) => (cur.status.name === "Observación" ? ++acc : acc),
    0
  );

  const countBudgetedMoney = filteredProjects.reduce(function (sum, record) {
    if (!!record.budgeted_money) {
      return Math.trunc(Number(sum) + Number(record.budgeted_money));
    } else {
      return Number(sum);
    }
  }, 0);

  const countExecutedMoney = filteredProjects.reduce(function (sum, record) {
    if (!!record.executed_money) {
      return Math.trunc(Number(sum) + Number(record.executed_money));
    } else {
      return Number(sum);
    }
  }, 0);
  const countTotalMoney = countBudgetedMoney - countExecutedMoney;

  const handleTypes = (lookupField) => {
    const hasTypeDefined = !!TYPES[lookupField];
    return {
      background: hasTypeDefined ? TYPES[lookupField].background : "#0379F2",
      name: hasTypeDefined ? TYPES[lookupField].name : lookupField,
      color: hasTypeDefined ? TYPES[lookupField].color : "#FFFFFF",
    };
  };
  return (
    <div className="reportes-dashboard">
      <DashArea section={"Reportes"}>
        <div className="reportes">
          <div className="reportes-topbar">
            <ul className="filters">
              <li>
                {isCountriesLoading ? (
                  <Spinner />
                ) : (
                  <Select
                    className="select-holder"
                    name="pais"
                    labelText={"Provincia"}
                    defaultValue=""
                    onChange={(e) =>
                      setSelects((prevState) => ({
                        ...prevState,
                        location: { name: e.target.value },
                      }))
                    }
                  >
                    <option value="" disabled></option>

                    {countries?.map((country) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                )}
              </li>
              <li>
                {isTopicsLoading ? (
                  <Spinner />
                ) : (
                  <Select
                    className="select-holder"
                    name="tipo"
                    labelText={"Tipo"}
                    defaultValue=""
                    onChange={(e) =>
                      setSelects((prevState) => ({
                        ...prevState,
                        topic: { name: e.target.value },
                      }))
                    }
                  >
                    <option value="" disabled></option>

                    {topics?.map((topic) => (
                      <option key={topic.id} value={topic.name}>
                        {topic.name}
                      </option>
                    ))}
                  </Select>
                )}
              </li>
              <li>
                {isProductiveSectorsLoading ? (
                  <Spinner />
                ) : (
                  <Select
                    className="select-holder"
                    name="tema"
                    labelText={"Sector"}
                    defaultValue=""
                    onChange={(e) =>
                      setSelects((prevState) => ({
                        ...prevState,
                        productive_sector: {
                          name: e.target.value,
                        },
                      }))
                    }
                  >
                    <option value="" disabled></option>

                    {productiveSectors?.map((sector) => (
                      <option key={sector.id} value={sector.name}>
                        {sector.name}
                      </option>
                    ))}
                  </Select>
                )}
              </li>
              <li>
                {isSubstatusesLoading ? (
                  <Spinner />
                ) : (
                  <Select
                    className="select-holder"
                    name="substatus"
                    labelText={"Estado"}
                    defaultValue=""
                    onChange={(e) =>
                      setSelects((prevState) => ({
                        ...prevState,
                        substatus: { name: e.target.value },
                      }))
                    }
                  >
                    <option value="" disabled></option>

                    {substatuses?.map((substatus) => (
                      <option key={substatus.id} value={substatus.name}>
                        {substatus.name}
                      </option>
                    ))}
                  </Select>
                )}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Badge
                    color="#687b8c"
                    background="rgba(104, 123, 140, 0.1)"
                    className="badge"
                    padding="9px 15px"
                    margin="18px 0 0 0.5rem"
                    onClick={resetFilter}
                  >
                    Limpiar Filtros
                  </Badge>
                </div>
              </li>
            </ul>
          </div>
          <div className="reportes-cols">
            <div className="estadisticas">
              <div className="row-card">
                <div className="card halfcard">
                  <Card
                    capText="Grado de avance de proyecto"
                    embedComp={
                      <div>
                        <div style={{ margin: "10px 0", fontSize: "13px" }}>
                          <small className="barlow-medium">
                            Total de proyectos:{" "}
                            <span
                              className="barlow-bold"
                              style={{ color: "#6F83A0" }}
                            >
                              {countProjects}
                            </span>
                          </small>
                        </div>
                        <ul className="circle-widgets">
                          <li>
                            <Circle
                              percentage={(
                                (countPending / filteredProjects.length || 0) *
                                100
                              ).toFixed(0)}
                              color="#FFCB00"
                              bgCircle="#FDF5DF"
                              title="Propuestas"
                            />
                          </li>
                          <li className="liO">
                            <Circle
                              percentage={(
                                (countInProgress / filteredProjects.length ||
                                  0) * 100
                              ).toFixed(0)}
                              color="#6F83A0"
                              bgCircle="#F0F4F7"
                              title="En desarrollo"
                            />
                          </li>
                          <li className="liT">
                            <Circle
                              percentage={(
                                (countComplete / filteredProjects.length || 0) *
                                100
                              ).toFixed(0)}
                              color="#21619F"
                              bgCircle="#EBF2FF"
                              title="Finalizados"
                            />
                          </li>
                          <li className="liTr">
                            <Circle
                              percentage={(
                                (countObservation / filteredProjects.length ||
                                  0) * 100
                              ).toFixed(0)}
                              color="#FF9841"
                              bgCircle="#FFEFEA"
                              title="Observaciones"
                            />
                          </li>
                          <li className="liTr">
                            <Circle
                              percentage={(
                                (countApproved / filteredProjects.length || 0) *
                                100
                              ).toFixed(0)}
                              color="#60B959"
                              bgCircle={"#E4F6EE"}
                              title="Aprobados"
                            />
                          </li>
                          <li className="liTr">
                            <Circle
                              percentage={(
                                (countRejected / filteredProjects.length || 0) *
                                100
                              ).toFixed(0)}
                              color="#E00000"
                              bgCircle={"#FFEFEA"}
                              title="No aprobados"
                            />
                          </li>
                        </ul>
                      </div>
                    }
                  />
                </div>
                <div className="card halfcard" style={{ marginLeft: "10px" }}>
                  <Card
                    capText="Ranking temas"
                    embedComp={
                      <div className="ranking">
                        <div className="head">
                          <h4
                            className="tag barlow-bold"
                            style={{ color: "#6F83A0" }}
                          >
                            Cantidad
                          </h4>
                        </div>
                        <ul className="scrollbar-thumb scrollbar-track scrollbar-scroll">
                          {topicsPerFilteredProjects
                            .sort((a, b) => b.count - a.count)
                            .map((p, index) => {
                              return (
                                <li key={index}>
                                  <SubtemaBar
                                    subtipo={`${p.name}`}
                                    cantidad={p.count}
                                  />
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="row-card">
                <div className="card">
                  <Card
                    capText="Tiempo en que se aprobó, sector y tipo"
                    embedComp={
                      <Scatter
                        data={groupedDataByStatus}
                        productiveSectors={groupedProductiveSectors}
                      />
                    }
                  />
                </div>
              </div>
              <div className="row-card">
                <div className="card">
                  <Card
                    capText="Distribución por tipo y sector"
                    embedComp={<TreeMap tree={tree} />}
                  />
                </div>
              </div>
              <div className="budget rubik-bold">
                <div className="card">
                  <BudgetCard
                    bgCircle="#EBF2FF"
                    color="#21619F"
                    title={"Presupuestado"}
                    current={countBudgetedMoney}
                    total={countBudgetedMoney}
                  />
                  <BudgetCard
                    bgCircle="#FFEFEA"
                    color="#E00000"
                    title={"Ejecutado"}
                    current={countExecutedMoney}
                    total={countBudgetedMoney}
                  />
                  <BudgetCard
                    bgCircle="#E4F6EE"
                    color="#60B959"
                    title={"Disponible"}
                    current={countTotalMoney}
                    total={countBudgetedMoney}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashArea>
    </div>
  );
}

export { Reportes };
