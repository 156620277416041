import { BackButton } from "UI/atoms/BackButton";
import PublicMain from "UI/organisms/PublicMain";

const InvalidToken = () => {
  return (
    <PublicMain>
      <div className="col">
        <div className="pass-recover login-card">
          <h3>Su token es invalido</h3>
          <BackButton isLogin={true} />
        </div>
      </div>
    </PublicMain>
  );
};

export default InvalidToken;
