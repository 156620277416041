const PresentationChartIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.75 21.125H3.25V4.875" />
      <path d="M22.7502 9.75L16.2502 15.4375L9.75018 10.5625L3.25018 16.25" />
    </svg>
  );
};

export { PresentationChartIcon };
