import styled from "styled-components";

const Error = ({ children, color, style }) => {
  return (
    <StyledError style={style} color={color}>
      {children}
    </StyledError>
  );
};

const StyledError = styled.span`
  line-height: 12px;
  display: flex;
  margin: 0.5rem 0 0 0.5rem;
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.secondary};
`;

export { Error };
