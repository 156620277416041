const getTopics = (projects = []) => {
  const weeksBetween = (d1, d2) => {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  };

  const topics = projects.map((project) => {
    return {
      name: project.topic.name,
      productive_sector: project.productive_sector.name,
      presented: project.start_date,
      voted: project.end_date,
      status: project.status.name,
      average_weeks: weeksBetween(
        new Date(project.start_date),
        new Date(project.end_date)
      ),
    };
  });

  const mergedTopics = [].concat.apply([], topics);

  const resultsTopicsProductiveSector = Object.values(
    mergedTopics.reduce((acc, topic) => {
      const key = `${topic.name}_${topic.productive_sector}`;
      if (!acc[key]) {
        acc[key] = {
          name: topic.name,
          productive_sector: topic.productive_sector,
          count: 1,
          presented: topic.presented,
          voted: topic.voted,
          status: topic.status,
          average_weeks: topic.average_weeks,
        };
      } else {
        acc[key].count++;
      }
      return acc;
    }, {})
  );
  const resultsTopic = Object.values(
    mergedTopics.reduce((acc, topic) => {
      const name = topic.name;
      if (!acc[name]) {
        acc[name] = {
          name: name,
          count: 1,
        };
      } else {
        acc[name].count++;
      }
      return acc;
    }, {})
  );
  const resultsByRejectedOrApprovedStatus =
    resultsTopicsProductiveSector.filter(
      (project) =>
        project.status === "No aprobado" || project.status === "Aprobado"
    );

  const tree = Object.entries(
    resultsTopicsProductiveSector.reduce(
      (acc, { name, productive_sector, count }) => {
        const key = `${name}_${productive_sector}`;
        if (!acc[key]) {
          acc[key] = {
            name,
            productive_sector,
            children: [],
          };
        }
        acc[key].children.push({ name, productive_sector, count });
        return acc;
      },
      {}
    )
  ).map(([key, { name, productive_sector, children }]) => ({
    name,
    resultsTopicsProductiveSector,
    productive_sector,
    children,
  }));

  return {
    resultsTopicsProductiveSector,
    resultsTopic,
    resultsByRejectedOrApprovedStatus,
    tree,
  };
};

export { getTopics };
