import { useMutation } from "@tanstack/react-query";
import { ForgotPassword } from "pages/ForgotPassword";
import InvalidToken from "pages/PasswordRecover/InvalidToken";
import { Login, PasswordRecover } from "pages/index";
import { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { validateToken } from "utils/api-client";

const UnauthenticatedRoutes = ({ setIsLogin }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const { mutate, data } = useMutation(validateToken);

  useEffect(() => {
    if (location.pathname === "/password-recover" && token) {
      const payload = {
        token,
      };
      mutate(payload);
    }
  }, [location, token]);

  const tokenIsValid = useMemo(() => {
    if (data && data.status) {
      return true;
    } else {
      return false;
    }
  }, [data]);

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route
        exact
        path="/login"
        element={<Login setIsLogin={setIsLogin} />}
      ></Route>
      <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>

      <Route
        exact
        path="/password-recover"
        element={
          token && tokenIsValid ? (
            <PasswordRecover token={token} />
          ) : (
            <InvalidToken />
          )
        }
      ></Route>
    </Routes>
  );
};

const UnauthenticatedApp = ({ setIsLogin }) => {
  return <UnauthenticatedRoutes setIsLogin={setIsLogin} />;
};

export { UnauthenticatedApp };
