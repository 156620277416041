import { AddEditProject, Escritorio, Proyectos } from "pages/index";
import { Navigate, Route, Routes } from "react-router-dom";

const UERoutes = ({ projects }) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/projects" />} />
      <Route
        exact
        path="/desktop"
        element={<Escritorio projects={projects} />}
      ></Route>
      <Route
        exact
        path="/projects"
        element={<Proyectos projects={projects} />}
      ></Route>
      <Route exact path="/project/:id" element={<AddEditProject />}></Route>
    </Routes>
  );
};

const UEApp = ({ projects }) => {
  return (
    <>
      <UERoutes projects={projects} />
    </>
  );
};

export { UEApp };
