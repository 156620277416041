import * as Yup from "yup";

const userSchemaValidator = (isEdit) => {
  return Yup.object()
    .shape({
      first_name: Yup.string().required("Nombre es un campo requerido"),
      last_name: Yup.string().required("Apellido es un campo requerido"),
      email: Yup.string().required("Email es un campo requerido"),
      password: Yup.string()
        .concat(
          !isEdit && Yup.string().required("Contraseña es un campo requerido")
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
        ),
      organization_name: Yup.string().required(
        "Nombre de organización es un campo requerido"
      ),
      location: Yup.number()
        .positive("Provincia es un campo requerido")
        .required("Provincia es un campo requerido"),
      role: Yup.string().required("Rol es un campo requerido"),
      is_active: Yup.bool().concat(
        !isEdit && Yup.bool().required("Estado es un campo requerido")
      ),
    })
    .required();
};

export { userSchemaValidator };
