import { Button } from "UI/atoms";
import { Box } from "UI/atoms/Box";
import { Progress } from "UI/molecules";
import { UrlIcon } from "shared/assets/svgs/Url";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const ReadOnly = ({ project, budgetAvailable, executed, budget }) => {
  return (
    <>
      <ToastContainer />
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div style={{ width: "437px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "13px",
            }}
          >
            <p>
              <small style={{ color: "#6F83A0" }}>Presupuestado</small>
            </p>
            <p>
              <small style={{ color: "#6F83A0" }}>Ejecutado</small>
            </p>
            <p>
              <small style={{ color: "#6F83A0" }}>Disponible</small>
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              background: "#F0F4F7",
              borderRadius: "14px",
              padding: "7px",
            }}
          >
            <Box
              fontSizes="sm"
              width={"117px"}
              height="46px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {project?.budgeted_money}
            </Box>
            <Box
              fontSizes="sm"
              width={"117px"}
              height="46px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {project?.executed_money}
            </Box>
            <div
              style={{
                width: "117px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4 style={{ color: "#21619F" }}>
                {budgetAvailable ? `$${budgetAvailable}` : "$0"}
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "240px",
            margin: "30px 0px 0px 34px",
          }}
        >
          <Progress
            count={executed}
            total={budget}
            color="#21619F"
            bgColor="#F0F4F7"
            labelText="Ejecutado"
            labelNumber=""
          />
        </div>
      </div>

      {project?.expense_sheet_file_url ? (
        <div style={{ marginTop: "36px" }}>
          <h6>Planilla de gastos</h6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0 20px 0",
            }}
          >
            <Box
              fontSizes="sm"
              width={"700px"}
              height="44px"
              fontWeight={500}
              color="body"
              fontFamily="Barlow"
            >
              {project.expense_sheet_file_url}
            </Box>

            <Button
              margin={"0px 10px 0px 12px"}
              height="30px"
              width="30px"
              className="circle-btn"
              type="button"
              onClick={() => {
                navigator.clipboard
                  .writeText(project.expense_sheet_file_url)
                  .then(() => {
                    toast.success("Texto copiado al portapapeles!");
                  })
                  .catch((error) => {
                    toast.error(
                      "Error copiando el texto al portapapeles. (Nos diste permiso en tu navegador?)"
                    );
                  });
              }}
            >
              <UrlIcon />
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {/* Puede ponerse un mensaje cuando no haya planilla de gastos */}
        </div>
      )}
    </>
  );
};

export default ReadOnly;
