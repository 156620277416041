import { Badge, Button, Error, Modal } from "UI/atoms/index";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Pencil, Spinner, Trash } from "shared/assets/svgs";
import { CalendarIcon as Calendar } from "shared/assets/svgs/Calendar";
import { CheckIcon as Check } from "shared/assets/svgs/Check";
import { CloseModalIcon as CloseModal } from "shared/assets/svgs/CloseModal";

import { useEditProject, useGetProjectById } from "queries/projects";

import { useDeleteLookup } from "queries/lookups";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getRole } from "utils/getRole";

const ProjectDetail = ({ projectId, setIsOpenModal, colors }) => {
  const { data: project, isLoading: isProjectLoading } =
    useGetProjectById(projectId);

  const [isOpenApprovalDateModal, setIsOpenApprovalDateModal] = useState(false);
  const [votedDate, setVotedDate] = useState(() => project?.voted || "");
  const [error, setError] = useState("");

  const { mutate: deleteLookup } = useDeleteLookup(project, "projects");

  const handleDelete = () => {
    deleteLookup();
    setIsOpenModal(false);
  };

  const {
    mutate: editProject,
    isLoading: isEditVotedDateLoading,
    isSuccess: isEditVotedSuccess,
  } = useEditProject(project.id);

  const handleColors = (projectLookupColor) => {
    return colors.find((color) => color.id === projectLookupColor.id).code;
  };

  const handleEditVotedDate = () => {
    if (!votedDate) {
      setError("no-date");
      return;
    }
    editProject({ voted: votedDate });
  };

  useEffect(() => {
    if (Object.keys(project).length > 0) {
      setVotedDate(project.voted);
    } else {
      setVotedDate("");
    }

    if (isEditVotedSuccess) {
      setIsOpenApprovalDateModal(false);
    }
  }, [project, isEditVotedSuccess]);

  if (isProjectLoading) {
    return <Spinner />;
  }
  const role = getRole();

  const user = jwt_decode(
    localStorage.getItem("__auth_provider_token__") || ""
  );

  const member = project.memberships.find(
    (member) => member.user.id === user.user_id
  );

  const miembros = project.memberships.filter(
    (member) => member?.role?.id === 1
  );

  const nombres = miembros.map(
    (miembro) => `${miembro.user.first_name} ${miembro.user.last_name}`
  );

  //   1.- Si es lider, puede editar.
  // 2.- Si es Consorcio, puede editar.
  // 3.- Si es aliado y su provincia es la misma que la del proyecto puede editar
  const isCommunityCoordinator =
    user.role === "Comunidad" && member?.role?.id === 1;
  const isConsortium = role === "Consorcio";
  const isAllyAllowedToEditProject =
    (user.role === "Aliado" && member?.role?.id === 1) ||
    (user.role === "Aliado" && project?.location?.id === user?.location_id);

  const isAllyColaborator = user.role === "Aliado" && member?.role?.id === 2;
  const isCommunityColaborator =
    user.role === "Comunidad" && member?.role?.id === 2;

  return (
    <div>
      <div className="ficha-topbar">
        <ul>
          {isCommunityCoordinator ||
          isConsortium ||
          isAllyAllowedToEditProject ? (
            <>
              <li>
                <Link to={`/edit-project/${project.id}`}>
                  <Button
                    width="110px"
                    className="btn-with-icon"
                    style={{ margin: "0" }}
                  >
                    <Pencil /> <span>EDITAR</span>
                  </Button>
                </Link>
              </li>
              {isConsortium ? (
                <li>
                  <Button
                    type="button"
                    width="110px"
                    className="btn-with-icon"
                    background="#FF562F"
                    style={{ margin: "0" }}
                    disabled={
                      role !== "Comunidad" && role !== "UE" ? false : true
                    }
                    onClick={() => handleDelete()}
                  >
                    <Trash /> <span>Eliminar</span>
                  </Button>
                </li>
              ) : null}
            </>
          ) : (
            ""
          )}
          {role === "UE" || isCommunityColaborator || isAllyColaborator ? (
            <li>
              {" "}
              <Link to={`/project/${project.id}`}>
                <Button width="90px" className="btn">
                  <span>Ver</span>
                </Button>
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>

        <Button
          background="white"
          width="32px"
          height="32px"
          onClick={() => setIsOpenModal(false)}
        >
          <CloseModal />
        </Button>
      </div>
      <div className="ficha-head">
        <h2>{project.name}</h2>
        {miembros.length > 0 ? (
          <p className="barlow-medium">
            Coordinador/a de proyecto: {nombres.join(", ")}
          </p>
        ) : (
          ""
        )}
      </div>
      <ul className="ficha-info barlow-medium">
        <li style={{ width: "25%" }}>
          <Badge
            background={handleColors(project.status.bg_color)}
            color={handleColors(project.status.color)}
            className="badge estado"
          >
            {project.status.name}
          </Badge>
        </li>
        <li style={{ width: "25%" }}>{project.topic.name}</li>
        <li style={{ width: "25%" }}>{project.productive_sector.name}</li>
        <li style={{ width: "25%" }}>{project.location.name}</li>
      </ul>
      <ul className="ficha-data barlow-medium">
        <li>
          <label>PRESENTADO EL</label>
          <span className="text">{project.start_date}</span>
        </li>
        <li>
          <label>ÚLTIMA MODIFICACIÓN</label>
          <span className="text">
            {project.end_date ? project.end_date : "N/A"}
          </span>
        </li>
        <li>
          <label>ESTADO</label>
          <Badge
            background={handleColors(project.substatus.color)}
            className="badge"
          >
            {project.substatus.name}
          </Badge>
        </li>
      </ul>
      <div className="ficha-body">
        <h4>Objetivo general</h4>
        <p className="barlow-medium">{project.description}</p>
      </div>
      <div className="ficha-body">
        <h4>Objetivo específico</h4>
        <p className="barlow-medium">{project.specific_objectives}</p>
      </div>
      <div className="ficha-body">
        <h4>Justificación</h4>
        <p className="barlow-medium">{project.justification}</p>
      </div>
      <Modal
        isOpen={isOpenApprovalDateModal}
        toggleModal={setIsOpenApprovalDateModal}
        width="342px"
        padding="40px 32px"
        className="fecha-aprobado"
      >
        <h4>Date of Approval</h4>
        <div className="field-row">
          <div className="field-with-icon">
            <DatePicker
              type="text"
              name="voted"
              height="46px"
              className="react-datepicker__input-field"
              placeholder="DD/MM/AAAA"
              value={votedDate}
              onChange={(val) => setVotedDate(val.toISOString().slice(0, 10))}
            />
            <span className="calendar-icon">
              <Calendar />
            </span>
          </div>
        </div>
        {error && <Error>Voted date is required</Error>}
        <div className="actions-row">
          <Button
            width="133px"
            background="#F0F4F7"
            color="#344854"
            className="btn-with-icon"
            onClick={() => setIsOpenApprovalDateModal(false)}
          >
            Cancel
          </Button>
          <Button
            width="133px"
            className="btn-with-icon white-icon"
            onClick={() => handleEditVotedDate()}
          >
            {isEditVotedDateLoading ? (
              <Spinner />
            ) : (
              <>
                <Check /> <span>Confirm</span>
              </>
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export { ProjectDetail };
