import styled from "styled-components";

const ListItem = ({
  children,
  color,
  fontSize,
  fontWeight,
  padding,
  margin,
  style,
  justifyContent,
  className,
}) => {
  return (
    <StyledListItem
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      margin={margin}
      padding={padding}
      style={style}
      className={className}
      justifyContent={justifyContent}
    >
      {children}
    </StyledListItem>
  );
};

const StyledListItem = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: center;

  font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props) => props.fontWeight};
  background: transparent;

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  text-decoration: none;

  cursor: pointer;
  color: ${(props) => props.color};
`;

export { ListItem };
