import { useState } from "react";
import { DashBar } from "UI/molecules/Header/styles";
import { SearchIcon } from "shared/assets/svgs/Search";
import { getRole } from "utils/getRole";
import { Plus } from "shared/assets/svgs/DesktopPageIcons";
import { Search } from "UI/atoms/index";
import { CaretRightIcon as CaretRight } from "shared/assets/svgs/CaretRight";
import { OrderBy } from "shared/assets/svgs/DesktopPageIcons";

import { useColors, useCommonLookups } from "queries/lookups";
import { Spinner } from "shared/assets/svgs";
import { Badge, Button, Select } from "UI/atoms/index";
import { Modal } from "UI/molecules/index";
import { TYPES } from "../../types/select";
import { ProjectDetail } from "./UI/templates/ProjectDetail";

import { usePaginator } from "hooks/usePaginator";
import { DashArea } from "UI/organisms/DashArea";
import { filterProjects } from "utils/filterProjects";
import { useNavigate } from "react-router-dom";

function Proyectos({ projects }) {
  const navigate = useNavigate();
  const role = getRole();
  const [searchFilter, setSearchFilter] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selects, setSelects] = useState({});
  const [project, setProject] = useState({});

  const { data: colors, isLoading: isColorsLoading } = useColors();
  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");
  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("topics");
  const { data: statuses, isLoading: isStatusesLoading } =
    useCommonLookups("project-status");
  const { data: substatuses, isLoading: isSubstatusesLoading } =
    useCommonLookups("project-substatus");

  const filteredProjects = filterProjects(projects, selects, [], searchFilter);
  const { items, pages, pagesList, setPageNumber } = usePaginator(
    filteredProjects,
    10
  );

  const handleColors = (projectLookupColor) => {
    return colors?.find((color) => color.id === projectLookupColor?.id)?.code;
  };

  const resetFilter = () => {
    var options = document.getElementsByClassName("select");
    for (var i = 0, l = options.length; i < l; i++) {
      options[i].value = options[i].options[0].value;
    }
    setSelects({});
    setSearchFilter("");
  };

  const handleProject = (project) => {
    setIsOpenModal(true);
    setProject(project);
  };

  const handleTypes = (lookupField) => {
    const hasTypeDefined = !!TYPES[lookupField];
    return {
      background: hasTypeDefined ? TYPES[lookupField].background : "#0379F2",
      name: hasTypeDefined ? TYPES[lookupField].name : lookupField,
      color: hasTypeDefined ? TYPES[lookupField].color : "#FFFFFF",
    };
  };

  if (isColorsLoading) {
    return <Spinner />;
  }

  return (
    <section>
      <div className="proyectos-dashboard">
        <DashArea section={"Proyectos"}>
          <ul className="dash-table-actions">
            <li>
              {isCountriesLoading ? (
                <Spinner />
              ) : (
                <Select
                  className="select-holder"
                  name="pais"
                  labelText={"Provincias"}
                  defaultValue=""
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      location: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled></option>
                  {countries?.map((country) => (
                    <option key={country.id}>{country.name}</option>
                  ))}
                </Select>
              )}
            </li>
            <li>
              {isTopicsLoading ? (
                <>
                  <Spinner />
                </>
              ) : (
                <Select
                  className="select-holder"
                  name="tipo"
                  labelText={"Tipo"}
                  defaultValue=""
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      topic: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled></option>
                  {topics?.map((topic) => (
                    <option key={topic.id} value={topic.name}>
                      {topic.name}
                    </option>
                  ))}
                </Select>
              )}
            </li>
            <li>
              {isStatusesLoading ? (
                <>
                  <Spinner />
                </>
              ) : (
                <Select
                  className="select-holder"
                  name="estado"
                  labelText={"Etapa"}
                  defaultValue=""
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      status: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled></option>
                  {statuses?.map((status) => (
                    <option key={status.id} value={status?.name}>
                      {handleTypes(status?.name).name}
                    </option>
                  ))}
                </Select>
              )}
            </li>
            <li>
              {isSubstatusesLoading ? (
                <>
                  <Spinner />
                </>
              ) : (
                <Select
                  className="select-holder"
                  name="substatus"
                  labelText={"Estado"}
                  defaultValue=""
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      substatus: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled></option>
                  {substatuses?.map((substatus) => (
                    <option key={substatus.id} value={substatus.name}>
                      {substatus.name}
                    </option>
                  ))}
                </Select>
              )}
            </li>
          </ul>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "118px",
            }}
          >
            <Badge
              color="#687b8c"
              background="rgba(104, 123, 140, 0.1)"
              className="badge"
              padding="9px 15px"
              margin="0 0 0 0.5rem"
              onClick={resetFilter}
            >
              Limpiar filtros
            </Badge>
          </div>

          <table className="dash-table">
            <thead>
              <tr>
                <th className="nowrap">
                  <span className="label">
                    MODIFICADO{" "}
                    <span className="orderby">
                      <OrderBy />
                    </span>
                  </span>
                </th>
                <th>NOMBRE DEL PROYECTO</th>
                <th>TIPO</th>
                <th>SECTOR</th>
                <th>PROVINCIA</th>
                <th className="nowrap">
                  <span className="label">
                    ETAPA{" "}
                    <span className="orderby">
                      <OrderBy />
                    </span>
                  </span>
                </th>
                <th className="nowrap">
                  <span className="label">
                    ESTADO{" "}
                    <span className="orderby">
                      <OrderBy />
                    </span>
                  </span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items?.map((project) => (
                <tr key={project.id}>
                  <td>{project.start_date}</td>
                  <td>
                    <strong>{project.name}</strong>
                  </td>
                  <td>{project.topic.name}</td>
                  <td>{project.productive_sector.name}</td>
                  <td>{project.location.name}</td>
                  <td className="nowrap">
                    <Badge
                      background={handleColors(project.status.bg_color)}
                      color={handleColors(project.status.color)}
                      className="badge estado"
                    >
                      {project.status.name}
                    </Badge>
                  </td>
                  <td className="nowrap center">
                    <Badge
                      background={handleColors(project.substatus.color)}
                      className="badge"
                    >
                      {project.substatus.name}
                    </Badge>
                  </td>
                  <td>
                    <Button
                      height="30px"
                      width="30px"
                      className="circle-btn"
                      onClick={() => handleProject(project)}
                    >
                      <CaretRight />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pager">
            {pagesList.map((lof) => (
              <Badge
                key={lof}
                background="white"
                color="#0379F2"
                fontWeight="bold"
                onClick={() => setPageNumber(lof)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                {lof}
              </Badge>
            ))}
          </div>
        </DashArea>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="610px"
        height={"90%"}
        padding="25px 32px"
        className="Proyectos-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
      >
        <ProjectDetail
          setIsOpenModal={setIsOpenModal}
          projectId={project.id}
          colors={colors}
        />
      </Modal>
    </section>
  );
}

export { Proyectos };
