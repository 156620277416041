import { Button } from "UI/atoms";
import { Box } from "UI/atoms/Box";
import { LinkIcon } from "shared/assets/svgs/LinkIcon";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export const AttachmentReadOnly = ({ attachment }) => {
  return (
    <form className="ficha-head">
    <ToastContainer/>
      <div className="item">
        <div className="row" style={{ width: "100%" }}>
          <Box
            fontSizes="sm"
            width={"20%"}
            height="44px"
            fontWeight={500}
            color="#545454"
            fontFamily="Barlow"
          >
            {attachment.name}
          </Box>
          <Box
            fontSizes="sm"
            width={"80%"}
            height="44px"
            fontWeight={500}
            color="#545454"
            fontFamily="Barlow"
          >
            {attachment.url}
          </Box>
        </div>

        <Button
          type="button"
          height="30px"
          width="30px"
          background="#21619F"
          className="circle-btn"
          onClick={() => {
                  navigator.clipboard.writeText(attachment.url).then(() => {
                    toast.success("Texto copiado al portapapeles!");
                  }).catch((error) => {
                    toast.error("Error copiando el texto al portapapeles. (Nos diste permiso en tu navegador?)");
                  });
                }}
        >
          <LinkIcon />
        </Button>
      </div>
    </form>
  );
};
