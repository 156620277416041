import React from 'react'

const PencilIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V10.2071C2.5 10.1414 2.51293 10.0764 2.53806 10.0158C2.56319 9.9551 2.60002 9.89998 2.64645 9.85355L10.1464 2.35355C10.2402 2.25979 10.3674 2.20711 10.5 2.20711C10.6326 2.20711 10.7598 2.25979 10.8536 2.35355L13.6464 5.14645C13.7402 5.24022 13.7929 5.36739 13.7929 5.5C13.7929 5.63261 13.7402 5.75979 13.6464 5.85355L6 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.5 4L12 7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {PencilIcon}