import { Button, Error, Input, Select } from "UI/atoms";
import { Formik } from "formik";
import { milestoneFormSchemaValidator } from "pages/AddEditProject/utils/schemaValidator";
import { useState } from "react";
import { Check } from "shared/assets/svgs";
import { CloseModalIcon } from "shared/assets/svgs/CloseModal";
import { generateId } from "utils/getDate";
import { getSelect } from "utils/getSelect";

const MilestonesModal = ({
  closeModal,
  milestones,
  setMilestones,
  selectedMilestone,
  lookups,
}) => {
  const [checked, setChecked] = useState(false);

  const handleInitialValues = () => {
    if (selectedMilestone) {
      return {
        name: selectedMilestone.name ?? "",
        description: selectedMilestone.description ?? "",
        start_date: selectedMilestone.start_date ?? "",
        end_date: selectedMilestone.end_date ?? "",
        status: selectedMilestone.status ?? 0,
        has_budget: selectedMilestone.has_budget ?? false,
      };
    } else {
      return {
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        status: 0,
        has_budget: false,
      };
    }
  };

  const getDate = () => {
    const today = new Date();
    const todayDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return todayDate;
  };

  const onSubmit = (data) => {
    closeModal();
    data = {
      key: generateId(),
      name: data.name === "" ? "----" : data.name,
      description: data.description === "" ? "----" : data.description,
      start_date: data.start_date === "" ? getDate() : data.start_date,
      end_date: data.end_date === "" ? getDate() : data.end_date,
      status: data.status === 0 ? 1 : data.status,
      has_budget: data.has_budget,
    };
    if (selectedMilestone) {
      const filtered = milestones.filter(
        (milestone) =>
          (milestone.key ? milestone.key : milestone.id) !==
          (selectedMilestone.key ? selectedMilestone.key : selectedMilestone.id)
      );
      setMilestones([...filtered, data]);
    } else {
      setMilestones([...milestones, data]);
    }
  };

  return (
    <div>
      <div className="ficha-topbar">
        <h2>Nuevo Hito</h2>

        <Button
          background="white"
          width="32px"
          height="32px"
          onClick={() => closeModal()}
        >
          <CloseModalIcon />
        </Button>
      </div>
      <Formik
        initialValues={handleInitialValues()}
        validationSchema={milestoneFormSchemaValidator}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="ficha-head">
            <div style={{ marginRight: "23px" }}>
              <label htmlFor="name" className="form-label">
                Título
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                height="46px"
                width={"524px"}
                className="form-control"
                onChange={(name) => {
                  formik.setFieldValue("name", name.target.value);
                }}
                value={formik.values.name ?? selectedMilestone?.name ?? ""}
              />
              {formik.errors.name && formik.touched.name ? (
                <Error>{formik.errors.name}</Error>
              ) : null}
            </div>
            <div style={{ margin: "23px 23px 0px 0px" }}>
              <label htmlFor="description" className="form-label">
                Descripción
              </label>

              <textarea
                id="description"
                name="description"
                onChange={(description) => {
                  formik.setFieldValue("description", description.target.value);
                }}
                value={
                  formik.values.description ||
                  selectedMilestone?.description ||
                  ""
                }
                className="form-control textarea scrollbar-scroll scrollbar-track scrollbar-thumb"
              ></textarea>
              {formik.errors.description && formik.touched.description ? (
                <Error>{formik.errors.description}</Error>
              ) : null}
            </div>
            <div
              style={{
                margin: "23px 23px 23px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label htmlFor="start_date" className="form-label">
                  Fecha de inicio
                </label>

                <Input
                  id="start_date"
                  name="start_date"
                  type="date"
                  height="46px"
                  width={"253px"}
                  className="form-control"
                  onChange={(start_date) => {
                    formik.setFieldValue("start_date", start_date.target.value);
                  }}
                  value={
                    formik?.values?.start_date ||
                    selectedMilestone?.start_date ||
                    ""
                  }
                />
                {formik.errors.start_date && formik.touched.start_date ? (
                  <Error>{formik.errors.start_date}</Error>
                ) : null}
              </div>
              <div>
                <label htmlFor="end_date" className="form-label">
                  Fecha de finalización
                </label>

                <Input
                  id="end_date"
                  name="end_date"
                  type="date"
                  height="46px"
                  width={"253px"}
                  className="form-control"
                  onChange={(end_date) => {
                    formik.setFieldValue("end_date", end_date.target.value);
                  }}
                  value={
                    formik.values.end_date || selectedMilestone?.end_date || ""
                  }
                />
                {formik.errors.end_date && formik.touched.end_date ? (
                  <Error>{formik.errors.end_date}</Error>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: "23px" }}>
              <label htmlFor="status" className="form-label">
                Estado
              </label>
              <Select
                name="status"
                id="status"
                width="313px"
                defaultValue={
                  selectedMilestone?.status?.id || formik.values.status.id
                }
                onChange={(status) => {
                  formik.setFieldValue("status", parseInt(status.target.value));
                }}
              >
                {!selectedMilestone && (
                  <option value="" hidden>
                    Seleccionar
                  </option>
                )}
                {lookups.milestoneStatuses.map((milestone_status) => (
                  <option
                    key={milestone_status.id}
                    value={milestone_status.id}
                    // selected={getSelect(
                    //   formik.values.status.id,
                    //   selectedMilestone?.status?.id,
                    //   milestone_status.id
                    // )}
                  >
                    {milestone_status.name}
                  </option>
                ))}
              </Select>
              {formik.errors.status && formik.touched.status ? (
                <Error>{formik.errors.status}</Error>
              ) : null}
            </div>
            <div
              style={{
                margin: "23px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                id="has_budget"
                name="has_budget"
                type="checkbox"
                className="form-control"
                onChange={() => {
                  formik.setFieldValue("has_budget", !checked);
                }}
                checked={getSelect(
                  formik.values.has_budget,
                  selectedMilestone?.has_budget,
                  true
                )}
                value={
                  formik.values.has_budget
                    ? formik.values.has_budget
                    : selectedMilestone?.has_budget
                }
              />
              <label
                htmlFor="has_budget"
                className="form-label"
                style={{ marginTop: "10px" }}
              >
                Cuenta con presupuesto asociado?
              </label>
            </div>

            <div className="actions-row">
              <Button
                width="133px"
                background="#F0F4F7"
                color="#344854"
                className="btn-with-icon"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                width="133px"
                className="btn-with-icon white-icon"
                type={"button"}
                onClick={() => onSubmit(formik.values)}
              >
                <Check /> <span>Confirmar</span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { MilestonesModal };
