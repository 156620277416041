const StackIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82939 9.9025C9.39064 9.9025 8.94376 9.82125 8.59439 9.66687L3.80064 7.53813C2.58189 6.99375 2.40314 6.2625 2.40314 5.86438C2.40314 5.46625 2.58189 4.735 3.80064 4.19062L8.59439 2.06187C9.30126 1.745 10.3656 1.745 11.0725 2.06187L15.8744 4.19062C17.085 4.72687 17.2719 5.46625 17.2719 5.86438C17.2719 6.2625 17.0931 6.99375 15.8744 7.53813L11.0725 9.66687C10.715 9.82938 10.2763 9.9025 9.82939 9.9025ZM9.82939 3.045C9.55314 3.045 9.28501 3.08562 9.09001 3.175L4.29626 5.30375C3.80064 5.53125 3.62189 5.75875 3.62189 5.86438C3.62189 5.97 3.80064 6.20563 4.28814 6.425L9.08189 8.55375C9.47189 8.72438 10.1788 8.72438 10.5688 8.55375L15.3706 6.425C15.8663 6.20563 16.045 5.97 16.045 5.86438C16.045 5.75875 15.8663 5.52312 15.3706 5.30375L10.5769 3.175C10.3819 3.09375 10.1056 3.045 9.82939 3.045Z"
        fill="white"
      />
      <path
        d="M10 14.1356C9.69125 14.1356 9.3825 14.0706 9.09 13.9406L3.57313 11.4869C2.73625 11.1212 2.07812 10.1056 2.07812 9.1875C2.07812 8.85437 2.35438 8.57812 2.6875 8.57812C3.02062 8.57812 3.29688 8.85437 3.29688 9.1875C3.29688 9.63437 3.6625 10.195 4.06875 10.3819L9.58562 12.8356C9.84562 12.9494 10.1462 12.9494 10.4144 12.8356L15.9312 10.3819C16.3375 10.2031 16.7031 9.63437 16.7031 9.1875C16.7031 8.85437 16.9794 8.57812 17.3125 8.57812C17.6456 8.57812 17.9219 8.85437 17.9219 9.1875C17.9219 10.1056 17.2638 11.1212 16.4269 11.495L10.91 13.9488C10.6175 14.0706 10.3088 14.1356 10 14.1356Z"
        fill="white"
      />
      <path
        d="M10 18.1981C9.69125 18.1981 9.3825 18.1331 9.09 18.0031L3.57313 15.5494C2.66313 15.1431 2.07812 14.2413 2.07812 13.2419C2.07812 12.9088 2.35438 12.6325 2.6875 12.6325C3.02062 12.6325 3.29688 12.9169 3.29688 13.25C3.29688 13.7619 3.5975 14.2331 4.06875 14.4444L9.58562 16.8981C9.84562 17.0119 10.1462 17.0119 10.4144 16.8981L15.9312 14.4444C16.4025 14.2331 16.7031 13.77 16.7031 13.25C16.7031 12.9169 16.9794 12.6406 17.3125 12.6406C17.6456 12.6406 17.9219 12.9169 17.9219 13.25C17.9219 14.2494 17.3369 15.1513 16.4269 15.5575L10.91 18.0113C10.6175 18.1331 10.3088 18.1981 10 18.1981Z"
        fill="white"
      />
    </svg>
  );
};

export { StackIcon };
