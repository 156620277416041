const getProjectsAmountPerTopic = (projects = []) => {
  const topics = projects.map((project) => project.topic.name);

  const mergedTopics = [].concat.apply([], topics);

  const results = Object.values(
    mergedTopics.reduce((acc, topic) => {
      acc[topic] = acc[topic]
        ? { name: topic, count: 1 + acc[topic].count }
        : { name: topic, count: 1 };
      return acc;
    }, {})
  );

  const total = results.reduce((acc, el) => acc + el.count, 0);

  return {
    results,
    total,
  };
};

export { getProjectsAmountPerTopic };
