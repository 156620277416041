import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";

export default function Circle({
  percentage,
  color,
  title,
  bg,
  bgCircle,
  rounded,
  fontSize,
  strokeBottom,
  stroke,
}) {
  return (
    <div
      style={{
        backgroundColor: `${bg ?? "inherent"}`,
        borderRadius: `${rounded ?? "none"}`,
        padding: `${rounded ? ".7rem" : "none"}`,
        marginRight: "8px",
        whiteSpace: "nowrap",
        justifyContent: "center",
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "16px",
        fill: "#1C2A34",
      }}
    >
      <CircularProgressBar
        colorCircle={bgCircle ?? "#e6e6e6"}
        colorSlice={color}
        percent={Number(percentage)}
        round
        size={90}
        strokeBottom={strokeBottom ?? 5}
        stroke={stroke ?? 5}
        textPosition="0.35em"
        fontSize={fontSize ?? "16px"}
        fontColor={"#545454"}
      />
      <p>{title}</p>
    </div>
  );
}
