import { Badge, Button, Input, Select } from "UI/atoms/index";
import { Modal } from "UI/molecules/index";
import { useCommonLookups } from "queries/lookups";
import { useEffect, useState } from "react";
import { CheckIcon as Check } from "shared/assets/svgs/Check";
import { CloseModalIcon as CloseModal } from "shared/assets/svgs/CloseModal";
import { CloudArrowDown } from "shared/assets/svgs/DesktopPageIcons";
import { PencilIcon as Pencil } from "shared/assets/svgs/Pencil";

import { Formik } from "formik";
import { userSchemaValidator } from "./utils/schemaValidator";

import { useCreateUser, useDeleteUser, useEditUser } from "queries/users";

import { Error } from "UI/atoms/Error";
import { Spinner } from "shared/assets/svgs";
import { filterUsers } from "./utils/filterUsers";

import { DashArea } from "UI/organisms/DashArea";
import { usePaginator } from "hooks/usePaginator";
import { getSelect } from "utils/getSelect";
import { provincias } from "utils/provinces";
import { TrashIcon } from "shared/assets/svgs/Trash";
import DeleteModal from "pages/AddEditProject/UI/atoms/DeleteModal";

function Usuarios() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({});
  const [selects, setSelects] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { data: users, isLoading: isConsultantsLoading } =
    useCommonLookups("users");
  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");

  const filteredUsers = filterUsers(users, selects);
  const isEdit = Object.keys(user).length > 0 ? true : false;

  const { items, pages, pagesList, setPageNumber } = usePaginator(
    filteredUsers,
    10
  );

  const onError = (err) => {
    setError(
      err?.response?.data?.email ? err?.response?.data?.email : "user created"
    );
  };

  const { mutate: add, isLoading: isAddLoading } = useCreateUser(onError);

  const { mutate: deleteUser } = useDeleteUser(user?.id);

  const {
    mutate: edit,
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
  } = useEditUser(user?.id);

  const initialValues = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    location: user.location_id ?? 0,
    email: user?.email || "",
    role: user?.role || "",
    is_active: user?.is_active || false,
    organization_name: user?.organization_name || "",
    phone: user?.phone || "",
  };

  useEffect(() => {
    if (deleteUser.mutate)
      deleteUser.mutate(user?.id);
  }, [user, deleteUser]);

  const handleOpenDetailUserModal = (user) => {
    setIsOpenModal(true);
    setUser(user);
  };

  const onSubmit = (data) => {
    setIsOpenModal(false);
    if (isEdit) {
      const changedValues = Object.entries(data).reduce((acc, [key, value]) => {
        const hasChanged = initialValues[key] !== value;
        if (hasChanged) {
          acc[key] = value;
        }
        return acc;
      }, {});
      edit(changedValues);
    } else {
      add(data);
    }
  };

  const getDate = (date) => {
    const today = new Date(date);
    const todayDate =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();

    return todayDate;
  };

  const handleSelectChange = (e, key) => {
    setSelects((prevState) => ({
      ...prevState,
      [key]: key === "is_active" ? Boolean(e.target.value) : e.target.value,
    }));
    setPageNumber(1);
  };

  useEffect(() => {
    if (error === "Usuario creado" || isEditSuccess) {
      setIsOpenModal(false);
    } else {
      return;
    }
  }, [error, isEditSuccess]);

  const resetFilter = () => {
    var options = document.getElementsByClassName("select");
    for (var i = 0, l = options.length; i < l; i++) {
      options[i].value = options[i].options[0].value;
    }
    setSelects({});
  };

  const handleDeleteUser = () => {
    setIsOpenDeleteModal(false);
    deleteUser(user?.id);
  };

  return (
    <>
      <div className="usuarios-dashboard">
        <DashArea section={"Usuarios"}>
          <ul className="dash-table-actions">
            <li>
              <Select
                name="pais"
                labelText={"Provincia"}
                className={"select-holder"}
                defaultValue=""
                onChange={(e) => handleSelectChange(e, "location")}
              >
                <option value="" disabled></option>
                {provincias?.map((province) => (
                  <option key={province.id} value={province.name}>
                    {province.name}
                  </option>
                ))}
              </Select>
            </li>
            <li>
              <Select
                name="tipo"
                labelText={"Rol"}
                className={"select-holder"}
                defaultValue=""
                onChange={(e) => handleSelectChange(e, "role")}
              >
                <option disabled value=""></option>
                {["Consorcio", "Aliado", "Comunidad", "UE"].map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Select>
            </li>
            <li>
              <Select
                name="is_active"
                id="is_active"
                labelText={"Estado"}
                className={"select-holder"}
                defaultValue=""
                onChange={(e) => handleSelectChange(e, "is_active")}
              >
                <option disabled value=""></option>
                {[
                  { value: "Activo", status: 1 },
                  { value: "Archivado", status: "" },
                ].map((item) => (
                  <option key={item.value} value={item.status}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </li>
            <li>
              <Badge
                color="#687b8c"
                background="rgba(104, 123, 140, 0.1)"
                className="select-holder badge"
                padding="9px 15px"
                margin="0 0 0 0.5rem"
                onClick={resetFilter}
              >
                Limpiar filtros
              </Badge>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "100px",
            }}
          >
            <Button
              height="40px"
              width="170px"
              className="btn-with-icon"
              type="button"
              onClick={() => (setUser({}), setIsOpenModal(true))}
            >
              <CloudArrowDown /> <span>NUEVO USUARIO</span>
            </Button>
          </div>

          <table className="dash-table barlow-medium">
            <thead>
              <tr>
                <th className="nowrap">
                  <span className="label">
                    ALTA{" "}
                    {/* <span className="orderby">
                      <OrderBy />
                    </span> */}
                  </span>
                </th>
                <th className="left">NOMBRE DE USUARIO</th>
                <th className="nowrap">
                  <span className="label">
                    ROL{" "}
                    {/* <span className="orderby">
                      <OrderBy />
                    </span> */}
                  </span>
                </th>
                <th className="nowrap">
                  <span className="label">PROVINCIA</span>
                </th>
                <th className="nowrap">
                  <span className="label">
                    ESTADO{" "}
                    {/* <span className="orderby">
                      <OrderBy />
                    </span> */}
                  </span>
                </th>
                <th className="left">EMAIL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items?.map((user) => (
                <tr key={user.id}>
                  <td className="nowrap center">{getDate(user.date_joined)}</td>
                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                  <td className="nowrap center">{user.role}</td>
                  <td className="center">{user.location}</td>
                  {user.is_active ? (
                    <td className="nowrap center">
                      <Badge
                        background="#E4F6EE"
                        color="#2EC07F"
                        className="badge estado"
                      >
                        Activo
                      </Badge>
                    </td>
                  ) : (
                    <td className="nowrap center">
                      <Badge
                        background="#FFEFEA"
                        color="#E00000"
                        className="badge estado"
                      >
                        Archivado
                      </Badge>
                    </td>
                  )}
                  <td>{user.email}</td>
                  <td>
                    <div style={{ display: "flex", gap: 10, width: '100%'}}>
                    <Button
                      height="30px"
                      width="30px"
                      className="circle-btn"
                      onClick={() => handleOpenDetailUserModal(user)}
                    >
                      <Pencil />
                    </Button>
                    <Button
                      height="30px"
                      width="30px"
                      className="circle-btn"
                      background="#F44336"
                      onClick={() => {
                        setUser(user);
                        setIsOpenDeleteModal(true);
                      }}
                    >
                      <TrashIcon />
                    </Button>
                    </div>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pager">
            {pagesList.map((lof) => (
              <Badge
                key={lof}
                background="white"
                color="#0379F2"
                fontWeight="bold"
                onClick={() => setPageNumber(lof)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                {lof}
              </Badge>
            ))}
          </div>
        </DashArea>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="520px"
        height={"90%"}
        padding="25px 39px"
        className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
      >
        <div>
          <div className="ficha-topbar">
            <h2>Usuario</h2>
            <Button
              background="white"
              width="32px"
              height="32px"
              onClick={() => setIsOpenModal(false)}
            >
              <CloseModal />
            </Button>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={userSchemaValidator(isEdit)}
            onSubmit={(values) => onSubmit(values)}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} className="ficha-head">
                <div className="field-row">
                  <label htmlFor="nombre" className="form-label">
                    Nombre de usuario
                  </label>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    height="46px"
                    width={"432px"}
                    className="form-control"
                    onChange={(name) => {
                      formik.setFieldValue("first_name", name.target.value);
                    }}
                    value={formik.values.first_name ?? user?.first_name ?? ""}
                  />
                  {formik.errors.first_name && formik.touched.first_name ? (
                    <Error>{formik.errors.first_name}</Error>
                  ) : null}
                </div>
                <div className="field-row">
                  <label htmlFor="nombre" className="form-label">
                    Apellido
                  </label>
                  <Input
                    type="text"
                    name="last_name"
                    id="last_name"
                    height="46px"
                    width={"432px"}
                    className="form-control"
                    onChange={(last_name) => {
                      formik.setFieldValue("last_name", last_name.target.value);
                    }}
                    value={formik.values.last_name ?? user?.last_name ?? ""}
                  />
                  {formik.errors.last_name && formik.touched.last_name ? (
                    <Error>{formik.errors.last_name}</Error>
                  ) : null}
                </div>
                <div className="field-row">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    height="46px"
                    width={"432px"}
                    className="form-control"
                    onChange={(email) => {
                      formik.setFieldValue("email", email.target.value);
                    }}
                    value={formik.values.email ?? user?.email ?? ""}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <Error>{formik.errors.email}</Error>
                  ) : null}
                </div>
                <div className="field-row">
                  <label htmlFor="pass" className="form-label">
                    Contraseña
                  </label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="new-password"
                    height="46px"
                    width={"432px"}
                    className="form-control"
                    onChange={(password) => {
                      formik.setFieldValue("password", password.target.value);
                    }}
                    value={formik.values.password ?? ""}
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <Error>{formik.errors.password}</Error>
                  ) : null}
                </div>
                <div className="field-row">
                  <label htmlFor="organization_name" className="form-label">
                    Organización
                  </label>
                  <Input
                    type="text"
                    name="organization_name"
                    id="organization_name"
                    height="46px"
                    width={"432px"}
                    className="form-control"
                    onChange={(organization_name) => {
                      formik.setFieldValue(
                        "organization_name",
                        organization_name.target.value
                      );
                    }}
                    value={
                      formik.values.organization_name ??
                      user?.organization_name ??
                      ""
                    }
                  />
                  {formik.errors.organization_name &&
                  formik.touched.organization_name ? (
                    <Error>{formik.errors.organization_name}</Error>
                  ) : null}
                </div>
                <div className="field-row">
                  <ul className="field-colums">
                    <li>
                      <Select
                        name="location"
                        id="location"
                        width="200px"
                        height={"46px"}
                        labelText={"Provincia"}
                        labelClassName={"form-label"}
                        labelHtmlFor={"pais"}
                        defaultValue={formik?.values?.location || ""}
                        onChange={(location) => {
                          formik.setFieldValue(
                            "location",
                            parseInt(location.target.value)
                          );
                        }}
                      >
                        <option disabled value="">
                          Seleccionar
                        </option>
                        {countries?.map((location) => (
                          <option key={location.id} value={location.id}>
                            {location.name}
                          </option>
                        ))}
                      </Select>
                      {formik.errors.location && formik.touched.location ? (
                        <Error>{formik.errors.location}</Error>
                      ) : null}
                    </li>
                    <li>
                      <label htmlFor="phone" className="form-label">
                        Teléfono
                      </label>
                      <Input
                        type="text"
                        name="phone"
                        id="phone"
                        width="200px"
                        height={"46px"}
                        className="form-control"
                        onChange={(phone) => {
                          formik.setFieldValue("phone", phone.target.value);
                        }}
                        value={formik.values.phone ?? user?.phone ?? ""}
                      />
                    </li>
                  </ul>
                </div>
                <div className="field-row">
                  <ul className="field-colums">
                    <li>
                      <Select
                        name="role"
                        id="role"
                        width="200px"
                        labelText={"Rol"}
                        labelClassName={"form-label"}
                        labelHtmlFor={"rol"}
                        onChange={(role) => {
                          formik.setFieldValue("role", role.target.value);
                        }}
                        value={formik.values.role ?? user?.role ?? ""}
                      >
                        <option value="" disabled>
                          Selecionar
                        </option>
                        {["Consorcio", "Aliado", "Comunidad", "UE"].map(
                          (role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          )
                        )}
                      </Select>
                      {formik.errors.role && formik.touched.role ? (
                        <Error>{formik.errors.role}</Error>
                      ) : null}
                    </li>
                    <li>
                      <Select
                        name="is_active"
                        id="is_active"
                        width="200px"
                        labelText={"Estado"}
                        labelClassName={"form-label"}
                        labelHtmlFor={"entidad"}
                        defaultValue={formik.values.is_active || false}
                        onChange={(isActive) => {
                          formik.setFieldValue(
                            "is_active",
                            Boolean(isActive.target.value)
                          );
                        }}
                      >
                        <option value={false} disabled>
                          Seleccionar
                        </option>
                        {[
                          { value: "Activo", status: 1 },
                          { value: "Archivado", status: "" },
                        ].map((item) => (
                          <option key={item.value} value={item.status}>
                            {item.value}
                          </option>
                        ))}
                      </Select>
                      {formik.errors.is_active && formik.touched.is_active ? (
                        <Error>{formik.errors.is_active}</Error>
                      ) : null}
                    </li>
                  </ul>
                </div>
                <div className="actions-row actions-row-left">
                  <Button
                    width="124px"
                    background="#F0F4F7"
                    color="#344854"
                    className="btn-with-icon"
                    onClick={() => (setIsOpenModal(false), setUser({}))}
                  >
                    Cancelar
                  </Button>
                  <Button
                    width="124px"
                    className="btn-with-icon white-icon"
                    type="submit"
                    disabled={!formik.dirty}
                  >
                    {isAddLoading || isEditLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <Check /> <span>Guardar</span>
                      </>
                    )}
                  </Button>
                </div>
                <Error>{error !== "user created" && error}</Error>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
      <DeleteModal isOpenModal={isOpenDeleteModal} setIsOpenModal={setIsOpenDeleteModal} callback={handleDeleteUser} />
    </>
  );
}

export { Usuarios };
