import { Button, Modal } from "UI/atoms";
const DeleteModal = ({
  isOpenModal,
  setIsOpenModal,
  callback
}) => {

  const handleDelete = () => {
    callback();
    setIsOpenModal(false);
  }

  return (
    <Modal
      isOpen={isOpenModal}
      toggleModal={setIsOpenModal}
      width="610px"
      padding="25px 32px"
      className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
    >
      <div className="justify-center center" style={{padding: "35px 35px 0px"}}>
          <div className="barlow-bold" style={{ textAlign: "center" }}>
            <h2>Esta seguro que quieres eliminarlo?</h2>
            <br />
            <div className="actions-row">
              <Button
                width="133px"
                background="#F44336"
                color="#FFFFFF"
                className="btn-with-icon"
                onClick={handleDelete}
              >
                ELIMINAR
              </Button>
              <Button
                width="133px"
                className="btn-with-icon white-icon"
                background="#F0F4F7"
                color="#344854"
                type={"button"}
                onClick={() => setIsOpenModal(false)}
              >
                <span>Cancelar</span>
              </Button>
            </div>

          </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
