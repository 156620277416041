import { useCommonLookups } from "queries/lookups";

const Provinces = () => {
  const { data: locations, isLoading: isLocationsLoading } =
    useCommonLookups("locations");

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <ul className="ajustes">
          {locations?.map((location) => (
            <li key={location.id} style={{ padding: "1rem" }}>
              <p>{location.name}</p>
              <div className="actions"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Provinces };
