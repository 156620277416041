import {
  Desktop,
  Gear,
  PresentationChart,
  Stack,
  Users,
} from "shared/assets/svgs/SidebarIcons";

const ADMIN_ROLES = [
  { text: "Escritorio", route: "desktop", icon: <Desktop /> },
  { text: "Proyectos", route: "projects", icon: <Stack /> },
  { text: "Reportes", route: "reports", icon: <PresentationChart /> },
  { text: "Usuarios", route: "users", icon: <Users /> },
  { text: "Ajustes", route: "settings", icon: <Gear /> },
];

const COORDINATOR_ROLES = [
  { text: "Escritorio", route: "desktop", icon: <Desktop /> },
  { text: "Proyectos", route: "projects", icon: <Stack /> },
  { text: "Usuarios", route: "users", icon: <Users /> },
];

const CONSULTANT_ROLES = [
  { text: "Escritorio", route: "desktop", icon: <Desktop /> },
  { text: "Proyectos", route: "projects", icon: <Stack /> },
];
const UE_ROLES = [
  { text: "Escritorio", route: "desktop", icon: <Desktop /> },
  { text: "Proyectos", route: "projects", icon: <Stack /> },
];

export { ADMIN_ROLES, CONSULTANT_ROLES, COORDINATOR_ROLES, UE_ROLES };
