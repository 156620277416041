import jwt_decode from "jwt-decode";

const getRole = () => {
  const isLogin = localStorage.getItem("__auth_provider_token__")
    ? true
    : false;

  const role = isLogin
    ? jwt_decode(localStorage.getItem("__auth_provider_token__") || "")?.role ||
      "Comunidad"
    : "Comunidad";

  return role;
};

export { getRole };
