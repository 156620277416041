import styled from "styled-components";
import { Badge } from "../../atoms/Badge";

const ProgressLabel = ({
  children,
  percentage,
  labelText,
  bgColor,
  color,
  labelNumber,
}) => {
  return (
    <RangeLabel>
      <Label>
        {children}
        {labelNumber !== "" ? (
          <Badge
            padding={"0px 10px"}
            fontSizes={"11px"}
            background={bgColor}
            color={color}
            className="badge"
            margin={"0 0 0 12px"}
            style={{ height: "23px" }}
          >
            {labelNumber}
          </Badge>
        ) : (
          ""
        )}
      </Label>
      <Strong style={{ display: "inline-block", margin: "1rem 0" }}>
        {percentage}
      </Strong>
    </RangeLabel>
  );
};

const RangeLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  // margin-bottom: 12px;
`;
const Label = styled.p`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #545454;
  display: flex;
  align-items: center;
`;

const Strong = styled.strong`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
`;

export { ProgressLabel };
