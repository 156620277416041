import { Button, Error, Input } from "UI/atoms";
import { Formik } from "formik";
import { indicatorsFormSchemaValidator } from "pages/AddEditProject/utils/schemaValidator";
import { Check } from "shared/assets/svgs";
import { CloseModalIcon } from "shared/assets/svgs/CloseModal";
import { generateId } from "utils/getDate";

const IndicatorsModal = ({
  setIsOpenModal,
  setIndicators,
  selectedIndicator,
  indicators,
  setSelectedIndicator,
}) => {
  const handleInitialValues = () => {
    if (selectedIndicator) {
      return {
        name: selectedIndicator.name ?? "",
        description: selectedIndicator.description ?? "",
        date: selectedIndicator.date ?? "",
        goal: selectedIndicator.goal ?? "",
        scope: selectedIndicator.scope ?? "",
      };
    } else {
      return {
        name: "",
        description: "",
        date: "",
        goal: "",
        scope: "",
      };
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setSelectedIndicator();
  };

  const getDate = () => {
    const today = new Date();
    const todayDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return todayDate;
  };

  const onSubmit = (data) => {
    data = {
      key: generateId(),
      name: data.name === "" ? "-----" : data.name,
      date: data.date === "" ? getDate() : data.date,
      description: data.description === "" ? "----" : data.description,
      goal: data.goal === "" ? "-----" : data.goal,
      scope: data.scope === "" ? "----" : data.scope,
    };
    closeModal();
    if (selectedIndicator) {
      const filtered = indicators.filter(
        (indicator) =>
          (indicator.key ? indicator.key : indicator.id) !==
          (selectedIndicator.key ? selectedIndicator.key : selectedIndicator.id)
      );
      setIndicators([...filtered, data]);
    } else {
      setIndicators([...indicators, data]);
    }
  };

  return (
    <div>
      <div className="ficha-topbar">
        <h2>Nuevo Indicador</h2>

        <Button
          type="button"
          background="white"
          width="32px"
          height="32px"
          onClick={() => closeModal()}
        >
          <CloseModalIcon />
        </Button>
      </div>
      <Formik
        initialValues={handleInitialValues()}
        validationSchema={
          selectedIndicator ? null : indicatorsFormSchemaValidator
        }
        // onSubmit={(values) => onSubmit(values)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="ficha-head">
            <div style={{ marginRight: "23px" }}>
              <label htmlFor="name" className="form-label">
                Título
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                height="46px"
                width={"524px"}
                className="form-control"
                onChange={(name) => {
                  formik.setFieldValue("name", name.target.value);
                }}
                value={formik.values.name ?? selectedIndicator?.name ?? ""}
              />
              {formik.errors.name && formik.touched.name ? (
                <Error>{formik.errors.name}</Error>
              ) : null}
            </div>
            <div style={{ margin: "23px 23px 0px 0px" }}>
              <label htmlFor="description" className="form-label">
                Descripción
              </label>

              <textarea
                id="description"
                name="description"
                className="form-control textarea scrollbar-scroll scrollbar-track scrollbar-thumb"
                onChange={(description) => {
                  formik.setFieldValue("description", description.target.value);
                }}
                value={
                  formik.values.description ??
                  selectedIndicator?.description ??
                  ""
                }
              ></textarea>
            </div>
            <div
              style={{
                margin: "23px 23px 23px 0px",
              }}
            >
              <div>
                <label htmlFor="date" className="form-label">
                  Fecha
                </label>

                <Input
                  id="date"
                  name="date"
                  type="date"
                  height="46px"
                  width={"253px"}
                  className="form-control"
                  onChange={(date) => {
                    formik.setFieldValue("date", date.target.value);
                  }}
                  value={
                    formik.values.date
                      ? formik.values.date
                      : selectedIndicator?.date || ""
                  }
                />
              </div>
            </div>
            <div style={{ marginRight: "23px" }}>
              <label htmlFor="goal" className="form-label">
                Meta
              </label>
              <Input
                id="goal"
                name="goal"
                type="text"
                height="46px"
                width={"524px"}
                className="form-control"
                onChange={(goal) => {
                  formik.setFieldValue("goal", goal.target.value);
                }}
                value={formik.values.goal ?? selectedIndicator?.goal ?? ""}
              />
            </div>
            <div style={{ margin: "23px 23px 0px 0px" }}>
              <label htmlFor="scope" className="form-label">
                Alcance
              </label>
              <Input
                id="scope"
                name="scope"
                type="text"
                height="46px"
                width={"524px"}
                className="form-control"
                onChange={(scope) => {
                  formik.setFieldValue("scope", scope.target.value);
                }}
                value={formik.values.scope ?? selectedIndicator?.scope ?? ""}
              />
            </div>

            <div className="actions-row">
              <Button
                type="button"
                width="133px"
                background="#F0F4F7"
                color="#344854"
                className="btn-with-icon"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                width="133px"
                className="btn-with-icon white-icon"
                type={"button"}
                onClick={() => onSubmit(formik.values)}
              >
                <Check /> <span>Confirmar</span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { IndicatorsModal };
