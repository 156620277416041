import React from 'react'

const TabsLayout = ({children}) => {
  return (
    <>
      {children}
    </>
  )
}

export {TabsLayout}