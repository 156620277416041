const STATUS_TYPES = {
  Approved: {
    name: "Approved",
    background: "#E4F6EE",
    color: "#2EC07F",
  },
  Pending: {
    name: "Pending",
    background: "#FDF5DF",
    color: "#F3A400",
  },
  "In progress": {
    name: "In progress",
    background: "#EBF2FF",
    color: "#0379F2",
  },
  Rejected: {
    name: "Rejected",
    background: "#FFEFEA",
    color: "#FF562F",
  },
};
const TYPES = {
  Approved: {
    name: "APROBADOS",
    background: "#E4F6EE",
    color: "#2EC07F",
  },
  Pending: {
    name: "PRESENTADOS",
    background: "#FDF5DF",
    color: "#F3A400",
  },
  "In progress": {
    name: "EN EJECUCIÓN",
    background: "#EBF2FF",
    color: "#0379F2",
  },
  Rejected: {
    name: "RECHAZADOS",
    background: "#FFEFEA",
    color: "#FF562F",
  },
  High: {
    name: "High",
    background: "#2EC07F",
    color: "#FFFFFF",
  },
  high: {
    name: "High",
    background: "#2EC07F",
    color: "#FFFFFF",
  },
  Medium: {
    name: "Medium",
    background: "#F3A400",
    color: "#FFFFFF",
  },
  Low: {
    name: "Low",
    background: "#FF562F",
    color: "#FFFFFF",
  },
};

const PROBABILITIES_TYPES = {
  High: {
    name: "High",
    background: "#2EC07F",
    color: "#FFFFFF",
  },
  high: {
    name: "High",
    background: "#2EC07F",
    color: "#FFFFFF",
  },
  Medium: {
    name: "Medium",
    background: "#F3A400",
    color: "#FFFFFF",
  },
  Low: {
    name: "Low",
    background: "#FF562F",
    color: "#FFFFFF",
  },
};

const IMPACTS_TYPES = {
  High: "High",
  high: "high",
  Medium: "Medium",
  Low: "Low",
};

export { TYPES, PROBABILITIES_TYPES, IMPACTS_TYPES, STATUS_TYPES };
