import { AdminApp } from "admin-app";
import { ConsultantApp } from "consultant-app";
import { CoordinatorApp } from "coordinator-app";
import { UEApp } from "ue-app";

const AppFlowByRole = ({ projects, role }) => {
  return (
    <>
      {role === "Consorcio" && <AdminApp projects={projects} />}
      {role === "Aliado" && <CoordinatorApp projects={projects} />}
      {role === "Comunidad" && <ConsultantApp projects={projects} />}
      {role === "UE" && <UEApp projects={projects} />}
    </>
  );
};

export { AppFlowByRole };
