const PublicMain = ({ children }) => {
  return (
    <>
      <div className="login">
        <div className="cols">
          <div className="login-intro">
            <div className="logo"></div>

            <div className="lg-block">
              <h2>Plataforma de Gestión de Proyectos</h2>
              <br />{" "}
              <div className="info">
                Una herramienta para el desarrollo e implementación de
                iniciativas por parte de las comunidades participantes de VOCES.
              </div>
            </div>
          </div>
          {children}
          <div className="lg-none login-intro">
            <h3>Plataforma de Gestión de Proyectos</h3>
            <br />{" "}
            <span>
              Una herramienta para el desarrollo e implementación de iniciativas
              por parte de las comunidades participantes de VOCES.
            </span>
          </div>
        </div>
        <div className="lg-block login-footer">
          <h4>Una iniciativa en conjunto de</h4>
          <div className="grid">
            <div className="dir-leg"></div>
            <div className="san-martin"></div>
            <div className="cambio-democ"></div>
            <div className="otras-voces"></div>
            <div className="gdn"></div>
            <div className="andhes"></div>
            <div className="ucasal"></div>
          </div>
          <h5>Este programa es financiado por la Unión Europea.</h5>
          <div className="union-europea"></div>
        </div>
      </div>
    </>
  );
};

export default PublicMain;
