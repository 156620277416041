import { Badge, Button, Modal } from "UI/atoms";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Pencil, Plus } from "shared/assets/svgs";
import { MilestonesModal } from "./UI/MilestonesModal";
import { ReadOnly } from "./UI/ReadOnly";

const Milestones = ({
  lookups,
  milestones,
  setMilestones,
  isEditMode,
  isCreateMode,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState();

  const openDetail = (milestone) => {
    setSelectedMilestone(milestone);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setSelectedMilestone();
  };

  const handleColors = (projectStatusId) => {
    if (projectStatusId) {
      const statusFiltered = lookups.milestoneStatuses.find(
        (substatus) => substatus.id === projectStatusId
      );

      return statusFiltered?.color.code;
    }
  };

  const handleName = (projectStatusId) => {
    if (projectStatusId) {
      const statusFiltered = lookups.milestoneStatuses.find(
        (status) => status.id === projectStatusId
      );

      return statusFiltered.name;
    }
  };

  return (
    <>
      {isEditMode || isCreateMode ? (
        <>
          {" "}
          <div className="tablayer tab-parametros">
            <Button
              type={"button"}
              height="24px"
              color="#21619F"
              background="transparent"
              className="btn-with-icon addfile"
              onClick={() => setIsOpenModal(true)}
            >
              <span className="blue-circle">
                <Plus />
              </span>{" "}
              <span>AGREGAR HITO</span>
            </Button>
            <table className="dash-table dash-table-inverse">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha fin</th>
                  <th>Estado del hito</th>
                </tr>
              </thead>
              <tbody>
                {!!milestones ? (
                  milestones.map((milestone) => (
                    <tr
                      key={milestone.key ? milestone.key : milestone.id}
                      style={{ textAlign: "center" }}
                    >
                      <td>
                        <strong>{milestone.name}</strong>
                      </td>
                      <td>
                        <small className="ellipsis">
                          {milestone.description}
                        </small>
                      </td>
                      <td>
                        <small>{milestone.start_date}</small>
                      </td>
                      <td>
                        <small>{milestone.end_date}</small>
                      </td>
                      <td className="nowrap">
                        <Badge
                          background={
                            milestone.status.color
                              ? milestone.status.color.code
                              : handleColors(milestone.status)
                          }
                          className="badge estado"
                        >
                          {milestone.status.name ??
                            handleName(milestone.status)}
                        </Badge>
                      </td>
                      <td>
                        <Button
                          type={"button"}
                          height="30px"
                          width="30px"
                          className="circle-btn"
                          onClick={() => openDetail(milestone)}
                        >
                          <Pencil />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="barlow-medium center">Sin hitos</div>
                )}
              </tbody>
            </table>
            <div className="pager">
              <Badge
                key={1}
                background="white"
                color="#0379F2"
                fontWeight="bold"
                onClick={() => "setPageNumber(lof)"}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                1
              </Badge>
            </div>
          </div>
          <Modal
            isOpen={isOpenModal}
            toggleModal={closeModal}
            width="610px"
            height={"90%"}
            padding="25px 32px"
            className="politicas-ficha scrollbar-thumb scrollbar-track scrollbar-scroll"
          >
            <MilestonesModal
              closeModal={closeModal}
              setMilestones={setMilestones}
              selectedMilestone={selectedMilestone}
              milestones={milestones}
              setSelectedMilestone={setSelectedMilestone}
              lookups={lookups}
            />
          </Modal>
        </>
      ) : (
        <ReadOnly
          milestones={milestones}
          handleColors={handleColors}
          handleName={handleName}
        />
      )}
    </>
  );
};

export { Milestones };
