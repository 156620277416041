import { useForgotPassword } from "queries";
import { useState } from "react";

import { BackButton } from "UI/atoms/BackButton";
import { Button, Input, RegularText } from "UI/atoms/index";
import PublicMain from "UI/organisms/PublicMain";

const validateEmail = (email) => {
  const regex = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const { mutate: forgotPassword, isSuccess } = useForgotPassword();

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleSubmit = (mail) => {
    forgotPassword(mail, {
      onError: (err) => setError(err.response),
    });
  };

  return (
    <PublicMain>
      <div className="col">
        <div className="pass-recover login-card">
          <h3>Recupera tu contraseña</h3>

          <div className="login-form">
            <div className="login-row">
              <label htmlFor="email">Correo electrónico</label>
              <Input
                placeholder="e-mail"
                type="text"
                name="email"
                className="username"
                onChange={(e) => handleEmailChange(e.target.value)}
              />
            </div>
            {isEmailValid ? null : (
              <span style={{ color: "red", fontSize: "12px" }}>
                Ingrese un mail valido.
              </span>
            )}
            {isSuccess ? (
              <RegularText
                size={"12px"}
                color={"#2EC07F"}
                className="rubik-bold"
              >
                ¡El recupero de contraseña esta en proceso!
                <br />
                Revise su casilla de mails para continuar
              </RegularText>
            ) : (
              ""
            )}
            <Button
              height="54px"
              width="100%"
              background="#21619F"
              margin={"20px 0px"}
              disabled={isSuccess}
              onClick={() => handleSubmit(email)}
            >
              Enviar
            </Button>
            <p className="new-user">
              <span style={{ color: "red" }}>{error}</span>
            </p>
          </div>
          <BackButton />
        </div>
      </div>
    </PublicMain>
  );
}

export { ForgotPassword };
