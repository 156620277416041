import { ListItem } from "UI/atoms/index";
import { Link, useLocation } from "react-router-dom";

import { Lock } from "shared/assets/svgs";
import { handleSidebarAccordingToUserRole } from "./utils/handleSidebarAccordingToUserRole";

function Menubar({ setIsLogin, role }) {
  const { pathname } = useLocation();

  const listItems = handleSidebarAccordingToUserRole(role);

  return (
    <>
      <div className="head">
        <div className="block">
          <h1>
            <span className="logo">voces</span>
          </h1>
        </div>
      </div>
      <ul>
        {listItems?.map((li) => (
          <li key={li.route} style={{ textDecoration: "none" }}>
            <Link to={li.route}>
              <ListItem
                className={`/${li.route}` === pathname ? "selected" : null}
              >
                <span>{li.icon}</span> {li.text}
              </ListItem>
            </Link>
          </li>
        ))}
        <li
          style={{ textDecoration: "none" }}
          onClick={() => {
            setIsLogin(false);
            localStorage.removeItem("__auth_provider_token__");
            localStorage.removeItem("__auth_provider_refresh__");
          }}
        >
          <ListItem>
            <span>
              <Lock />
            </span>{" "}
            Cerrar sesión
          </ListItem>
        </li>
      </ul>
    </>
  );
}

export { Menubar };
