import {useState} from 'react'

const usePaginator = (arr = [], size = 10) => {
  const [pageNumber, setPageNumber] = useState(1)

  const arrCopy = [...arr]
  const pages = Math.ceil(arrCopy.length / size)

  return {
    items: arrCopy.slice((pageNumber - 1) * size, pageNumber * size),
    pages,
    pagesList: [...Array(pages)].map((_, i) => i + 1),
    setPageNumber
  }
}                   
  
  export {usePaginator}