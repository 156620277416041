import { RegularText } from "UI/atoms";
import { Link } from "react-router-dom";

export const ResponseText = ({ successText, route }) => {
  return (
    <RegularText size="16px" color="#2EC07F">
      Tu proyecto se ha {successText} con éxito!{" "}
      <Link to={`/${route}`} style={{ fontSize: "16px", color: "#0379F2" }}>
        Click aquí
      </Link>{" "}
      para ver la lista de proyectos.
    </RegularText>
  );
};
