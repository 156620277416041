import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ListItem } from "../ListItem";
// import {Nodos, Desktop, Organizations, Invitations, Chart, Chat, Power} from '../../assets/svgs/SidebarIcons/index'

const listItems = [
  { text: "Desktop", icon: "Icon componentized svg" },
  { text: "Organizaciones", icon: "Icon componentized svg" },
  { text: "Invitaciones", icon: "Icon componentized svg" },
  { text: "Indice", icon: "Icon componentized svg" },
  { text: "Formularios", icon: "Icon componentized svg" },
];

const pathToListItems = listItems.map((li) => {
  return {
    ...li,
    path: `/${li.text}`.toLowerCase(),
  };
});

const Sidebar = ({ logout }) => {
  const { pathname } = useLocation();

  return (
    <Wrapper>
      <div style={{ marginBottom: "3rem" }}>Nodos icon</div>
      {pathToListItems.map((li) => (
        <Link to={li.path} style={{ textDecoration: "none" }} key={li.path}>
          <ListItem
            key={li.text}
            margin="3rem 0"
            fontSize="base"
            fontWeight="600"
            color={pathname === li.path ? "#6C3F98" : "#3D4852"}
          >
            <span style={{ margin: "0 0.5rem" }}>
              {React.cloneElement(li.icon, {
                color: pathname === li.path ? "#6C3F98" : "#3D4852",
              })}
            </span>
            {/* </span> */}
            {li.text}
          </ListItem>
        </Link>
      ))}
      <ListItem
        margin="2rem 0"
        // TODO
        onClick={() => logout("logout")}
      >
        Power Icon Cerrar sesion
      </ListItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem;
`;

export { Sidebar };
