const DesktopIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9375 8.98438H4.3125C2.34625 8.98438 1.26562 7.90375 1.26562 5.9375V4.3125C1.26562 2.34625 2.34625 1.26562 4.3125 1.26562H5.9375C7.90375 1.26562 8.98438 2.34625 8.98438 4.3125V5.9375C8.98438 7.90375 7.90375 8.98438 5.9375 8.98438ZM4.3125 2.48438C3.02875 2.48438 2.48438 3.02875 2.48438 4.3125V5.9375C2.48438 7.22125 3.02875 7.76562 4.3125 7.76562H5.9375C7.22125 7.76562 7.76562 7.22125 7.76562 5.9375V4.3125C7.76562 3.02875 7.22125 2.48438 5.9375 2.48438H4.3125Z"
        fill="#545454"
      />
      <path
        d="M15.6875 8.98438H14.0625C12.0962 8.98438 11.0156 7.90375 11.0156 5.9375V4.3125C11.0156 2.34625 12.0962 1.26562 14.0625 1.26562H15.6875C17.6537 1.26562 18.7344 2.34625 18.7344 4.3125V5.9375C18.7344 7.90375 17.6537 8.98438 15.6875 8.98438ZM14.0625 2.48438C12.7787 2.48438 12.2344 3.02875 12.2344 4.3125V5.9375C12.2344 7.22125 12.7787 7.76562 14.0625 7.76562H15.6875C16.9713 7.76562 17.5156 7.22125 17.5156 5.9375V4.3125C17.5156 3.02875 16.9713 2.48438 15.6875 2.48438H14.0625Z"
        fill="#545454"
      />
      <path
        d="M15.6875 18.7344H14.0625C12.0962 18.7344 11.0156 17.6537 11.0156 15.6875V14.0625C11.0156 12.0962 12.0962 11.0156 14.0625 11.0156H15.6875C17.6537 11.0156 18.7344 12.0962 18.7344 14.0625V15.6875C18.7344 17.6537 17.6537 18.7344 15.6875 18.7344ZM14.0625 12.2344C12.7787 12.2344 12.2344 12.7787 12.2344 14.0625V15.6875C12.2344 16.9713 12.7787 17.5156 14.0625 17.5156H15.6875C16.9713 17.5156 17.5156 16.9713 17.5156 15.6875V14.0625C17.5156 12.7787 16.9713 12.2344 15.6875 12.2344H14.0625Z"
        fill="#545454"
      />
      <path
        d="M5.9375 18.7344H4.3125C2.34625 18.7344 1.26562 17.6537 1.26562 15.6875V14.0625C1.26562 12.0962 2.34625 11.0156 4.3125 11.0156H5.9375C7.90375 11.0156 8.98438 12.0962 8.98438 14.0625V15.6875C8.98438 17.6537 7.90375 18.7344 5.9375 18.7344ZM4.3125 12.2344C3.02875 12.2344 2.48438 12.7787 2.48438 14.0625V15.6875C2.48438 16.9713 3.02875 17.5156 4.3125 17.5156H5.9375C7.22125 17.5156 7.76562 16.9713 7.76562 15.6875V14.0625C7.76562 12.7787 7.22125 12.2344 5.9375 12.2344H4.3125Z"
        fill="#545454"
      />
    </svg>
  );
};

export { DesktopIcon };
