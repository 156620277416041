const CheckCloud = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3607 8C5.3607 7.0111 5.65875 6.0444 6.21716 5.22215C6.77558 4.39991 7.56927 3.75904 8.49788 3.3806C9.42649 3.00217 10.4483 2.90315 11.4341 3.09608C12.4199 3.289 13.3254 3.76521 14.0362 4.46447C14.7469 5.16373 15.2309 6.05465 15.427 7.02455C15.6231 7.99446 15.5224 8.99979 15.1378 9.91342C14.7531 10.827 14.1018 11.6079 13.2661 12.1573C12.4303 12.7068 11.4478 13 10.4427 13H4.8525C4.34801 12.9995 3.8494 12.8935 3.38975 12.6889C2.93011 12.4843 2.51994 12.1859 2.18649 11.8134C1.85303 11.441 1.60391 11.003 1.45566 10.5285C1.3074 10.0541 1.26341 9.55407 1.3266 9.06163C1.38979 8.56919 1.55871 8.0956 1.82215 7.6723C2.0856 7.249 2.43754 6.88567 2.85462 6.60643C3.2717 6.32718 3.74437 6.13842 4.24127 6.05265C4.73817 5.96688 5.24793 5.98608 5.73672 6.10897"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4756 7.25L9.42645 10.25L7.90186 8.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CheckCloud };
