const getProjectsAmountPerStatus = (projects = []) => {
  const results = Object.values(
    projects.reduce((acc, project) => {
      acc[project.status.name] = acc[project.status.name]
        ? {status: project.status.name, count: 1 + acc[project.status.name].count, color: project.status.color}
        : {status: project.status.name, count: 1, color: project.status.color};
        return acc;
      }, {})
    );

  const total = results.reduce((acc, el) => acc + el.count, 0)

  return {
    results,
    total
  }
}
    
export {getProjectsAmountPerStatus}