import React from 'react'
import styled from 'styled-components'

const Label = ({children, htmlFor, color, style}) => {
  return (
    <StyledLabel 
      htmlFor={htmlFor}
      style={style}
      color={color}
    >
      {children}
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  margin: 1rem 0;
  font-size: ${props => props.theme.fontSizes.base};
  color: ${props => props.theme.colors.blue}
`

export {Label}