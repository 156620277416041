import styled from "styled-components";

export const Box = ({
  children,
  background = "transparent",
  fontSizes = "xs",
  fontWeight = "700",
  color = "#fff",
  margin = "0",
  padding = "9px 15px",
  border = "none",
  borderRadius = "20px",
  style,
  className,
  onClick,
  height,
  width,
}) => {
  return (
    <StyledBox
      background={background}
      color={color}
      padding={padding}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      fontSize={fontSizes}
      fontWeight={fontWeight}
      style={style}
      className={className}
      onClick={onClick}
      height={height}
      width={width}
    >
      {children}
    </StyledBox>
  );
};

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => (props.width ? props.width : "fit-content")};
  height: ${(props) => (props.height ? props.height : "fit-content")};

  padding: ${(props) => (props.padding ? props.padding : " 0 ")};
  margin: ${(props) => props.margin};

  color: ${(props) => (props.color ? props.color : "#545454")};
  background: ${(props) => props.background};

  font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;

  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
`;
