import { useEffect, useState } from "react";
import { Eye } from "shared/assets/svgs/LoginIcons";

import { Button, Input } from "UI/atoms/index";

import { useLogin } from "queries/login";
import { Link } from "react-router-dom";
import PublicMain from "UI/organisms/PublicMain";

function Login({ setIsLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState("");

  const { mutate: login, isLoading, data, error: errorLogin = "" } = useLogin();

  const handleLogin = () => {
    login({
      email: username,
      password,
    });
  };

  useEffect(() => {
    if (data) {
      setIsLogin(true);
    }
  }, [data]);

  useEffect(() => {
    if (errorLogin) {
      setError(errorLogin.response?.data?.detail || "invalid login");
    }
  }, [errorLogin]);

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleLogin();
    }
  };

  return (
    <PublicMain>
      <div className="col">
        <div className="login-card">
          <h3 style={{ color: "#545454" }}>Iniciar sesión</h3>
          <div className="lg-none ">
            <Button
              height="54px"
              width="100%"
              onClick={handleLogin}
              disabled={isLoading}
              background="#f0f4f7"
              color="#6F83A0"
              className={"google-btn"}
            >
              <span className="google-logo"></span>
              Iniciar sesión con Google
            </Button>
          </div>

          <div className="login-form">
            <div className="login-row">
              <label htmlFor="email">Usuario</label>
              <Input
                placeholder="e-mail"
                type="text"
                name="email"
                className="username"
                onChange={(e) => {
                  setError("");
                  setUsername(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="login-row">
              <label>Contraseña</label>
              <Input
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                type={hidePassword ? "password" : "text"}
                name="password"
                className="password"
                onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />
              <span
                className="tooglepass"
                onClick={() => setHidePassword(!hidePassword)}
              >
                <Eye />
              </span>
            </div>
            <p className="forget">
              <Link to="/forgot-password">¿Olvidaste la contraseña?</Link>
            </p>
            <Button
              height="54px"
              width="100%"
              onClick={handleLogin}
              disabled={isLoading}
              background="#21619F"
            >
              INGRESAR
            </Button>
            <p className="new-user">
              <span style={{ color: "red" }}>{error}</span>
            </p>
          </div>
        </div>
      </div>
    </PublicMain>
  );
}

export { Login };
