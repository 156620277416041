import Mapa from "shared/images/mapa-argentina.svg";

export const Map = ({ mapData }) => {
  return (
    <div className="map" style={{ margin: "2em 1.5em" }}>
      <div className="map-holder">
        <img src={Mapa} width="389" alt="Mapa de argentina" />
        {mapData.map((data) => (
          <section key={data.className}>
            <div className={`image-${data.className} map-bg`}> </div>
            <div className={"circle " + data.className}>
              <span>{data.count}</span>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};
