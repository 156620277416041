import React from 'react'

const Eye = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.375 2.81259L14.625 15.1876" stroke="#344854" strokeOpacity="1" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.8919 11.0812C10.34 11.5829 9.61134 11.8448 8.8663 11.8092C8.12126 11.7737 7.42085 11.4437 6.91911 10.8918C6.41738 10.3399 6.15542 9.61131 6.19086 8.86627C6.22631 8.12123 6.55625 7.42078 7.10811 6.91899" stroke="#344854" strokeOpacity="1" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.20274 4.82307C2.33615 6.27471 1.125 9.00009 1.125 9.00009C1.125 9.00009 3.375 14.062 9 14.062C10.3179 14.0725 11.6194 13.769 12.7967 13.1765" stroke="#344854" strokeOpacity="1" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6679 11.8899C16.2008 10.5169 16.875 9.00009 16.875 9.00009C16.875 9.00009 14.625 3.93704 9.00002 3.93704C8.51285 3.93624 8.02644 3.97585 7.54581 4.05546" stroke="#344854" strokeOpacity="1" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.52927 6.23735C10.127 6.35214 10.6715 6.65765 11.0809 7.10805C11.4904 7.55846 11.7428 8.12948 11.8002 8.73546" stroke="#344854" strokeOpacity="1" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {Eye}