const TOPIC_COLOR_TYPES = {
  background: {
    default: "#F0F4F7",
  },
  color: {
    default: "#21619F",
  },
};

export { TOPIC_COLOR_TYPES };
