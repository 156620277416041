import { DashArea } from "UI/organisms/DashArea";
import React, { useState } from "react";
import {
  Provinces,
  Roles,
  Sectors,
  Statuses,
  Substatuses,
  TabsLayout,
  Topics,
} from "./UI/templates";

const layoutsTabs = {
  provinces: <Provinces />,
  topics: <Topics />,
  sectors: <Sectors />,
  statuses: <Statuses />,
  substatuses: <Substatuses />,
  roles: <Roles />,
};

function Ajustes() {
  const [selectedTab, setSelectedTab] = useState("provinces");

  const handleSelectedTab = (tab) => {
    return selectedTab === tab && "selected";
  };

  const handleLayoutsTabsWithExtendedProps = (props) => {
    return React.cloneElement(layoutsTabs[selectedTab], { ...props });
  };

  return (
    <div className="proyecto-nuevo">
      <DashArea section={"Ajustes"}>
        <div className="tabs-card">
          <ul className="tab-menu">
            <li onClick={() => setSelectedTab("provinces")}>
              <span className={`tab-item ${handleSelectedTab("provinces")}`}>
                Provincia
              </span>
            </li>
            <li onClick={() => setSelectedTab("topics")}>
              <span className={`tab-item ${handleSelectedTab("topics")}`}>
                Tipo
              </span>
            </li>
            <li onClick={() => setSelectedTab("sectors")}>
              <span className={`tab-item ${handleSelectedTab("sectors")}`}>
                Sector
              </span>
            </li>
            <li onClick={() => setSelectedTab("statuses")}>
              <span className={`tab-item ${handleSelectedTab("statuses")}`}>
                Etapa
              </span>
            </li>
            <li onClick={() => setSelectedTab("substatuses")}>
              <span className={`tab-item ${handleSelectedTab("substatuses")}`}>
                Estado
              </span>
            </li>
            <li onClick={() => setSelectedTab("roles")}>
              <span className={`tab-item ${handleSelectedTab("roles")}`}>
                Rol usuario
              </span>
            </li>
          </ul>

          <TabsLayout>{handleLayoutsTabsWithExtendedProps()}</TabsLayout>
        </div>
      </DashArea>
    </div>
  );
}

export { Ajustes };
