import Circle from "../ProgressWheel/Circle";

const BudgetCard = ({ title, current, total, color, bgCircle }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "320px",
        backgroundColor: "white",
        height: "80px",
        borderRadius: "10px",
        padding: "1rem",
        justifyContent: "space-between",
      }}
    >
      <div style={{}}>
        <h2
          style={{ color: `${color}`, marginBottom: "10px", fontSize: "14px" }}
        >
          {title}
        </h2>
        <span style={{ fontSize: "18px" }}>${current}</span>
      </div>
      <Circle
        fontSize={"14px"}
        strokeBottom={10}
        stroke={10}
        percentage={((current / total) * 100).toFixed(0)}
        bgCircle={bgCircle}
        color={color}
        rounded={"45px"}
        bg={"white"}
      />
    </div>
  );
};

export default BudgetCard;
