const getDate = (date) => {
  var months = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ],
    now = new Date(date),
    formatted =
      now.getDate() + " " + months[now.getMonth()] + " " + now.getFullYear();
  return formatted;
};

const getTodayDate = () => {
  const today = new Date();
  const todayDate =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  return todayDate;
};

const getTime = (date) => {
  const formattedDate = new Date(date);
  const newDate = formattedDate.toLocaleTimeString([], {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  });
  return newDate;
};

export const generateId = () => {
  const id = Math.floor((1 + Math.random()) * 0x10000);
  return id;
};

export { getDate, getTime, getTodayDate };
