import { Button, Input, Search } from "UI/atoms/index";
import { Modal } from "UI/molecules/index";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Plus } from "shared/assets/svgs/DesktopPageIcons";
import { PaperPlaneIcon } from "shared/assets/svgs/Others/PaperPlane";
import { SearchIcon } from "shared/assets/svgs/Search";
import { getRole } from "utils/getRole";
import { DashBar, DashHead, Text, Title } from "./styles";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    borderRadius: "10px",
    display: "block",
    width: "450px",
    height: "44px",
    borderRadius: "10px",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "none",
  }),
};

const Header = ({ user, search, buttonText, projects }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const navigate = useNavigate();

  const mapProjectsToNewValues = projects.map((project) => {
    return {
      ...project,
      value: project.name,
      label: project.name,
    };
  });

  const handleOnSearch = (evt) => {
    setSearchFilter(evt);
  };

  const handleOnDropdownElementClick = (el) => {
    const project = projects.find((project) => {
      return project.name === el.name
    });
    const hasProject = Boolean(project);
    if (hasProject) {
      navigate(`edit-project/${project.id}`);
    } else {
      return;
    }
  };

  const customNoOptionsMessage = () => {
    return <span>No se encontraron proyectos</span>;
  };

  const role = getRole();

  const isMenuOpen = Boolean(searchFilter && searchFilter.length >= 2);

  return (
    <DashHead>
      <Title fontSizes="18px" fontWeight={500} color="#1c2a34">
        <Text
          fontSizes="16px"
          fontWeight={500}
          color="#21619F"
          fontFamily="Barlow"
        >
          {user.first_name} {user.last_name}
        </Text>

        <br />
      </Title>
      <DashBar>
        <li style={{ position: "relative" }}>
          <Select
            options={mapProjectsToNewValues}
            placeholder="Buscar por proyecto..."
            value={searchFilter}
            onInputChange={handleOnSearch}
            onChange={(el) => handleOnDropdownElementClick(el)}
            isSearchable={true}
            menuIsOpen={isMenuOpen}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={customNoOptionsMessage}
            styles={{ ...customStyles }}
            className="dash-search"
          />
        </li>{" "}
        {role !== "Comunidad" && role !== "UE" ? (
          <li>
            <Button
              onClick={() => {
                navigate("/new-project");
                window.location.reload();
              }}
              width="168px"
            >
              <Plus /> <span style={{ marginLeft: "9px" }}>{buttonText}</span>
            </Button>
          </li>
        ) : (
          ""
        )}
      </DashBar>
      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="30rem"
        height="20rem"
        title={"Invite Organization"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "2.3rem 6rem",
          }}
        >
          <Input
            margin="0 0 1rem 0"
            padding="0.75rem"
            border="0.5px solid #BCBCBC"
            borderRadius="10px"
            type="text"
            placeholder="Organization Name"
          />
          <Input
            padding="0.75rem"
            border="0.5px solid #BCBCBC"
            borderRadius="10px"
            type="email"
            placeholder="Email"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
          }}
        >
          <Button
            // style={{margin: '0 auto'}}
            fontSize="sm"
            padding="0.75rem 1.25rem"
            style={{ margin: "0 1rem" }}
            onClick={() => setIsOpenModal(false)}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <PaperPlaneIcon />
            </span>
            Send Invitation
          </Button>
          <Button
            background="#F9F8FA"
            color="#3D4852"
            fontSize="sm"
            padding="1rem 1.5rem"
            style={{ margin: "0 1rem" }}
            onClick={() => setIsOpenModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </DashHead>
  );
};

export { Header };
