import { Modal } from "UI/atoms";
import { AddEdit } from "../../molecules/AddEdit";

const AddEditModal = ({
  isOpenModal,
  setOpenAddOrEditModal,
  method,
  lookup = null,
  keyName,
  name,
  colors = undefined,
}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggleModal={setOpenAddOrEditModal}
      width="500px"
      height={"90%"}
      padding="25px 32px"
      className="topicos-ficha  scrollbar-thumb scrollbar-track scrollbar-scroll"
    >
      {method === "PUT" && (
        <AddEdit
          actionText="Editar"
          method="PUT"
          lookup={lookup}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          keyName={keyName}
          name={name}
          colors={colors}
        />
      )}
      {method === "POST" && (
        <AddEdit
          actionText="Agregar"
          method="POST"
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          keyName={keyName}
          name={name}
          colors={colors}
        />
      )}
    </Modal>
  );
};

export { AddEditModal };
